<template>
	<v-main v-if="$vuetify.breakpoint.mdAndUp">
		<v-overlay z-index="5"></v-overlay>
		<div style="background-size: cover; background-attachment: fixed; min-height: 100vh; display: flex; flex-direction: column; padding-bottom: 80px" :style="{ backgroundImage: 'url(' + $bucket + '/image/background/Login.BG.jpg' + ')' }">
			<v-layout style="z-index: 6" column align-center justify-center>
				<v-form ref="form" v-model="valid" style="transform-origin: center center 0px; width: 500px" v-if="!dialogConfirmation">
					<div style="padding: 7px 7px 50px 7px; background-color: white; border-radius: 4px">
						<div style="text-align: right">
							<v-btn icon color="secondary" to="/">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</div>

						<h2 class="duo" style="text-align: center">
							Need help with your
							<b>duo</b> password?
						</h2>

						<div style="padding: 0 15%">
							<p class="duo" style="line-height: normal; text-align: center">
								Please enter the work email registered to your
								<b>duo</b> account and we’ll send you a password reset link.
							</p>
							<v-text-field
								size="35%"
								v-model="email"
								placeholder="Email address"
								validate-on-blur
								:rules="[(v) => !!v || 'Please enter your registered email address', (v) => (v && v.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,63})+$/) && true) || 'Please enter a valid email address']"
							></v-text-field>
						</div>

						<div style="text-align: center; margin-top: 30px">
							<v-btn large class="duo-primary" @click="forgotPassword">Send Link</v-btn>
						</div>
					</div>
				</v-form>

				<v-card v-if="dialogConfirmation" max-width="500">
					<v-card-title>
						<v-spacer></v-spacer>
						<v-btn to="/" icon color="secondary">
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text class="pa-3">
						<v-layout column align-center justify-center>
							<p class="duo" style="line-height: normal; text-align: center">
								We have sent you a password reset link. If the email doesn’t appear in your inbox, please check your junk folder. If you require further support, please email <a href="mailto:duosupportnz@airnz.co.nz" style="text-decoration: none; font-size: 17px;">duosupportnz@airnz.co.nz</a>
							</p>
						</v-layout>
					</v-card-text>
					<v-card-actions class="justify-center pb-6">
						<v-btn style="max-width: 120px" to="/" class="duo-primary ml-2 mr-2">Close</v-btn>
					</v-card-actions>
				</v-card>
			</v-layout>
		</div>
	</v-main>
	<forgot-password-mobile v-else></forgot-password-mobile>
</template>

<script>
import axios from "axios";
import forgotPasswordMobile from "./mobile/forgotPasswordMobile.vue"

export default {
	components: {
		forgotPasswordMobile
	},
	data() {
		return {
			email: null,
			valid: true,
			dialogConfirmation: false,
		};
	},
	methods: {
		forgotPassword() {
			if (this.$refs.form.validate()) {
				axios
					.post("/system/authentication/password/forgot", { email: this.email })
					.then(() => {
						this.dialogConfirmation = true;
					})
					.catch(() => {});
			}
		},
	},
};
</script>
