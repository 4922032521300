<template>
  <v-main
    :style="{ backgroundImage: 'url(https://duonz.s3-ap-southeast-2.amazonaws.com/image/background/BDM.Map.BG.jpg)', backgroundSize: 'cover', margin: '10px' }"
    fluid>
    <div v-if="isPreview == false">
      <v-container fluid>
        <div class="navbar d-flex flex-wrap pt-5">
          <img style="width: 216px" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
          <v-spacer />
          <img style="width: 182px" :src="$bucket + '/image/logo-airnz.svg'" />
        </div>
        <router-view :key="$route.fullPath"></router-view>
      </v-container>
      <v-container fluid style="max-width: 1271px">
        <v-sheet class="pa-8">
          <v-row>
            <!-- <v-col>
            <h1>{{ entity.title }}</h1>
          </v-col> -->
            <v-col>
              <h2>{{ entity.title }}</h2>
            </v-col>
            <v-col cols="auto">
              <v-btn @click="$router.push('/administration/creator/form')" color="duo-secondary">Cancel</v-btn>
              <v-btn @click="isPreview = true" color="duo-secondary" class="mx-2">Preview</v-btn>
              <v-btn @click="save" color="duo-primary">
                Save</v-btn>
            </v-col>
            <v-col cols="12">
              <p>{{ entity.description }}</p>
            </v-col>
          </v-row>
        </v-sheet>

        <v-form ref="form">
          <div class="mt-6" v-for="(item, idx) in questionList" :key="idx">
            <p><b>{{ item.type.replaceAll("TC_", "T&C's ").replaceAll("_", " ") }}</b></p>
            
              <v-row>
                <v-col cols="10">
                  <v-sheet class="pa-8">
                  <div v-if="item.type == 'TC_Tickbox'">
                    <div>
                      <editor v-model="questionList[idx].question"
                        :api-key="$tinyMceKey" :init="initEditor" />
                    </div>
                    <sub style="color:red;" v-if="errorList[idx]">{{errorList[idx]}}</sub>
                  </div>

                  <div v-else-if="item.type.includes('Form')" class="mt-8">
                    <v-row>
                      <v-col cols="6"></v-col>
                      <v-col class="d-inline-flex justify-end align-center pt-0" cols="6">
                        <v-row>
                          <v-autocomplete dense solo v-model="formType" :items="formTypeList" label="Form Type" append-icon="mdi-chevron-down" class="pr-2 pb-1" />
                          <v-btn medium color="primary" @click="addFormType(idx, formType)" style="text-transform: none;" height="38">Add</v-btn>
                        </v-row>
                      </v-col>
                    </v-row>
                    
                    <template v-if="questionList[idx].map.form">
                      <v-row v-for="(form, index) in questionList[idx].map.form" :key="index" no-gutters>
                        <v-col cols="10">
                          <template v-if="form.type == 'Date_of_Birth'">
                            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y max-width="290px">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field v-if="questionList[idx].answer[index]" v-model="questionList[idx].answer[index].submitAnswer" class="mb-3" :label="form.type.replaceAll('_', ' ')" readonly v-on="on" disabled>
                                  <template v-slot:append>
                                    <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                                  </template>
                                </v-text-field>
                              </template>
                              <v-date-picker v-model="questionList[idx].answer[index].submitAnswer" @change="menu = false" no-title scrollable></v-date-picker>
                            </v-menu>
                          </template>
                          <template v-else-if="form.type == 'HTML'">
                            <v-text-field class="mb-3" dense v-model="questionList[idx].map.form[index].value"
                              :label="form.type.replaceAll('_', ' ')" />
                          </template>
                          <template v-else>
                            <v-text-field class="mb-3" dense v-model="questionList[idx].answer[index].submitAnswer" disabled
                              :label="form.type.replaceAll('_', ' ')" />
                          </template>
                          
                        </v-col>
                        <v-col cols="2" :class="form.type == 'Date_of_Birth' ? 'pt-4':''">
                          <v-icon v-if="index !== 0" @click="shiftFormType(idx, index, 'up')">mdi-arrow-up</v-icon>
                          <v-icon v-if="index !== questionList[idx].map.form.length - 1" @click="shiftFormType(idx, index, 'down')">mdi-arrow-down</v-icon>
                          <v-icon @click="deleteFormType(idx, index)">mdi-delete</v-icon>
                        </v-col>
                      </v-row>
                    </template>
                  </div>

                  <div v-else-if="item.type != 'CTA_Button'">
                    <v-row no-gutters>
                    <v-col cols="10">
                      <v-text-field v-model="questionList[idx].question" />
                    </v-col>
                    <v-col cols="2">
                      <v-checkbox id="hideTitle" v-model="questionList[idx].mandatory" >
                        <template v-slot:label>
                          <span style="font-size: 14px; color: #2d2926 !important">Mandatory</span>
                        </template>
                      </v-checkbox>
                    </v-col>
                    </v-row>
                  </div>
                  <!-- <div v-if="item.type == 'Multiple_line'" class="mt-8">
                    <v-text-field type="number" outlined dense v-model="questionList[idx].map.lines" label="Number of Lines" />
                  </div> -->
                  <div v-if="item.type.includes('Choice')" class="mt-8">
                    <!-- Accomodate old data with default answers 4 -->
                    <v-row v-for="idxAnswer in (questionList[idx].map.numAnswers ? questionList[idx].map.numAnswers : 4)" :key="idxAnswer" no-gutters>
                      <v-col cols="10">
                        <v-text-field class="mb-3" dense v-model="questionList[idx].map['option' + idxAnswer]" :rules="[(v) => !!v || 'Blank values not allowed']"
                          :label="'Option ' + idxAnswer" />
                      </v-col>
                      <v-col cols="2">
                        
                        <v-icon v-if="idxAnswer != 1" @click="shiftOptionUp(idx, idxAnswer)">mdi-arrow-up</v-icon>
                        <v-icon v-if="(!questionList[idx].map.numAnswers && idxAnswer != 4) || questionList[idx].map.numAnswers != idxAnswer" @click="shiftOptionDown(idx, idxAnswer)">mdi-arrow-down</v-icon>
                        <v-icon v-if="(!questionList[idx].map.numAnswers && idxAnswer == 4 && idxAnswer > 2) || (questionList[idx].map.numAnswers == idxAnswer && idxAnswer > 2)" @click="removeChoice(idx)">mdi-trash-can</v-icon>
                      </v-col>
                    </v-row>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-btn color="duo-primary" @click="addChoice(idx)">Add Choice</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else-if="item.type == 'Dropdown_Options'" class="mt-8">
                    <v-row v-for="(item, idxAnswer) in questionList[idx].map.answer" :key="idxAnswer">
                      <v-col cols="10">
                        <v-text-field dense v-model="questionList[idx].map.answer[idxAnswer]"
                          :label="'Option ' + (idxAnswer + 1)" />
                      </v-col>
                      <v-col cols="2">
                        <v-icon @click="removeDropdown(idx, idxAnswer)">mdi-trash-can</v-icon>
                      </v-col>
                    </v-row>
                    <v-btn color="duo-primary" @click="addDropDown(idx)">Add Dropdown</v-btn>
                  </div>
                  <div v-if="item.type == 'CTA_Button'">
                    <v-chip-group v-model="ctaButtonSelected" mandatory column class="pt-2">
                      <v-chip v-for="(item, idx) in ctaButtonTab" :key="idx" color="primary" outlined pill
                        style="cursor: pointer; width: 75px; height: 24px;" class="d-flex justify-center">{{ item
                        }}</v-chip>
                    </v-chip-group>
                    <v-text-field :rules="[(v) => !!v || 'This field is required']" v-if="ctaButtonSelected == 0" v-model="questionList[idx].question"
                      label="Button Text" />
                    <v-text-field v-if="ctaButtonSelected == 1" v-model="questionList[idx].map.link"
                      label="Button Link" :rules="[(v) => !!v || 'Enter Link', (v) => $root.isURL(v) || 'Invalid link, link should start with http:// or https://']" />
                    <!-- <v-text-field  label="Button Position" /> -->
                    <v-radio-group v-if="ctaButtonSelected == 2" v-model="questionList[idx].map.position" row>
                      <v-radio label="Left" value="left"></v-radio>
                      <v-radio label="Center" value="center"></v-radio>
                      <v-radio label="Right" value="right"></v-radio>
                    </v-radio-group>
                  </div>
                </v-sheet>
                </v-col>
                
                <v-col cols="2" class="pt-10">
                  <v-icon v-if="idx > 0" @click="shiftUp(item, idx)">mdi-arrow-up</v-icon>
                  <v-icon v-if="idx < questionList.length - 1" @click="shiftDown(item, idx)">mdi-arrow-down</v-icon>
                  <v-icon @click="confirmRemove(item, idx)">mdi-trash-can</v-icon>
                </v-col>
              </v-row>
            
          </div>
        </v-form>

        <v-row class="mt-12">
          <v-col cols="4">
            <v-select v-model="selectedType" :items="typeList" solo outlined dense label="Add content block"
              class="duo-description rounded-0" append-icon="mdi-chevron-down">
              <template v-slot:item="{ item }">
                <span class="duo-description">{{ item.replaceAll('TC_','T&C ').replaceAll('_',' ') }}</span>
              </template>
            </v-select>
          </v-col>
          <v-col cols="auto">
            <v-btn @click="addQuestion" class="white--text" color="duoSecondary"> Add </v-btn>
          </v-col>
        </v-row>

      </v-container>
    </div>
    <div v-else>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="d-flex justify-end">
            <v-btn @click="isPreview = false" color="duo-secondary" class="mr-2">Back</v-btn>
            <v-btn @click="save(); isPreview = false" color="duo-primary">Save</v-btn>
          </v-col>
        </v-row>
      </v-container>
      <duo-dynamic-form :is-preview="true" :questionList="questionList" />
    </div>
    <div>

    </div>

    <confirmation-dialog :title="confirmationDialog.title" :show="confirmationDialog.show"
      :content="confirmationDialog.content" @onCloseDialog="closeRemoveDialog" @onClickConfirm="deleteQuestion" />
    
    <confirmation-dialog title="Confirm leave page" :show="dialogConfirmLeavePage"
      content="Any unsaved progress will be lost <br/> Do you want to leave this page?"
      @onCloseDialog="dialogConfirmLeavePage = false" @onClickConfirm="confirmLeavePage" />
  </v-main>
</template>
  
<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>
  
<script>
/* eslint-disable no-console */
import editor from "@tinymce/tinymce-vue";
import axios from "axios";
import duoDynamicForm from "./creator/duoDynamicForm.vue"
import confirmationDialog from "./dialog/confirmationDialog.vue";

export default {
  components: {
    editor,
    duoDynamicForm,
    confirmationDialog,
  },
  data() {
    return {
      isPreview: false,
      typeList: ['Single_Line', 'Multiple_Line', 'Dropdown_Options', 'Single_Choice', 'Multiple_Choice', 'TC_Tickbox', 'CTA_Button', 'Form'],
      ctaButtonTab: ['Text', 'Link', 'Position'],
      ctaButtonSelected: 0,
      selectedType: null,
      pageImageList: [],
      backgroundImageList: [],
      dialogLibrary: {
        show: false,
        target: '',
        list: [],
        idx: undefined,
      },
      entity: {},
      questionList: [],
      questionListOld: "",
      formType: "",
      formTypeList: ['Full_Name', 'Passport_Number', 'Date_of_Birth', 'Airports_Number', 'HTML'],
      menu: false,
      errorList: {},
      attachment: null,
      attachment1: null,
      initEditor: {
        height: 150,
        width: '100%',
        selector: '',
        menubar: false,
        plugins: ["code", "paste", "table", "lists", "link", "textcolor"],
        paste_as_text: true,
        fontsize_formats: "8px 9px 10px 11px 12px 13px 14px 15px 16px 17px 18px 20px 21px 22px 23px 24px 25px 26px 27px 28px 29px 30px 31px 32px 33px 34px 35px 36px",
        toolbar:
          "code undo redo | fontsizeselect | bold italic underline forecolor backcolor | table | \
					alignleft aligncenter alignright alignjustify | \
					bullist numlist outdent indent | link | removeformat | pastetext | help"
      },
      confirmationDialog: {
        show: false,
        id: null,
        title: 'Confirm Delete',
        content: 'Are you sure you want to remove selected question? <br/> user\'s answer will also be removed.'
      },
      leave: false,
      nextPath: "/administration",
      dialogConfirmLeavePage: false
    }
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getForm();
    this.getQuestion();
  },
  beforeRouteLeave(to, from, next) {
    if (this.leave == true) {
      next();
    } else {
      next(false);
      this.beforeLeave(to.fullPath);
    }
  },
  methods: {
    beforeLeave(path) {
      this.nextPath = path;
      if (JSON.stringify(this.questionList) === this.questionListOld && this.questionListOld.length > 1) {
        this.leave = true;
        this.$router.push(this.nextPath);
      } else {
        this.dialogConfirmLeavePage = true;
      }
    },
    confirmLeavePage() {
      this.leave = true;
      this.$router.push(this.nextPath);
    },
    refresh() {
      this.$forceUpdate();
    },
    getForm() {
      axios.get("/system/creator/form?id=" + this.$route.params.id).then(
        response => {
          this.entity = response.data;
        }
      );
    },
    getQuestion() {
      axios.get("/system/creator/form/content/list?dynamicFormId=" + this.$route.params.id).then(
        response => {
          this.questionList = response.data;
          this.questionListOld = JSON.stringify(response.data);

          // Initialize answer array with objects
          this.questionList.forEach(question => {
            if (question.type == 'Form') {
              question.answer = [];
              question.map.form.forEach(form => {
                  question.answer.push({ type: form.type, submitAnswer: '' });
              });
            }
          });

        }
      );
    },
    addQuestion() {
      if (this.selectedType != null) {
        let obj = { type: this.selectedType, map: {} };
        if (this.selectedType == 'Dropdown_Options') {
          obj.map.answer = ['', '', '', '']
        } else if  (this.selectedType == 'TC_Tickbox') {
          obj.question="<p></p>";
        } else if (this.selectedType.includes('Choice')) {
          obj.numAnswers = 2;
        }
          
        this.questionList.push(obj);
      }
    },
    shiftUp(item, currIdx) {
      this.questionList.splice(currIdx, 1);
      this.questionList.splice(currIdx - 1, 0, item);
    },
    shiftDown(item, currIdx) {
      this.questionList.splice(currIdx, 1);
      this.questionList.splice(currIdx + 1, 0, item);
    },
    shiftOptionUp(idx, idxOption) {
      let temp = this.questionList[idx].map["option" + (idxOption - 1)];

      this.questionList[idx].map["option" + (idxOption - 1)] = this.questionList[idx].map["option" + idxOption] ? this.questionList[idx].map["option" + idxOption] : '';
      this.questionList[idx].map["option" + idxOption] = temp ? temp : '';
    },
    shiftOptionDown(idx, idxOption) {
      let temp = this.questionList[idx].map["option" + (idxOption + 1)];

      this.questionList[idx].map["option" + (idxOption + 1)] = this.questionList[idx].map["option" + idxOption] ? this.questionList[idx].map["option" + idxOption] : '';
      this.questionList[idx].map["option" + idxOption] = temp ? temp : '';
    },
    save() {
      this.errorList = {};
      for(let i = 0; i < this.questionList.length; i++) {
        if (!this.questionList[i].question || this.questionList[i].question.length == 0) {
          this.errorList[i] = "This field is required";
        }
      }

      if (this.$refs.form.validate() && Object.keys(this.errorList).length == 0) {
        let url = "/system/creator/form/content/update"

        axios.post(url, { dynamicFormId: this.$route.params.id, questionList: this.questionList }).then(() => {
          this.questionList = [];
          this.getQuestion();
        })
      }
    },
    preview() {
      this.$router.push("/administration/creator/page/" + this.entity.id + "/preview")
    },
    confirmRemove(item, idx) {
      if (item.id) {
        this.confirmationDialog.id = item.id;
        this.confirmationDialog.show = true;
      } else {
        this.removeContent(idx);
      }
    },
    removeContent(idx) {
      this.questionList.splice(idx, 1)
    },
    deleteQuestion() {
      axios
        .post("/system/creator/form/content/delete?id=" + this.confirmationDialog.id, {})
        .then(() => {
          this.closeRemoveDialog();
          this.getQuestion();
        })
        .catch(() => { });
    },
    closeRemoveDialog() {
      this.confirmationDialog.id = null;
      this.confirmationDialog.show = false;
    },
    addDropDown(questionIdx) {
      this.questionList[questionIdx].map.answer.push('')
    },
    removeDropdown(questionIdx, answerIdx) {
      this.questionList[questionIdx].map.answer.splice(answerIdx, 1);
    },
    removeChoice(idx){
      this.questionList[idx].map.numAnswers = !this.questionList[idx].map.numAnswers ? 3 : (this.questionList[idx].map.numAnswers - 1);
      delete this.questionList[idx].map['option' + (this.questionList[idx].map.numAnswers + 1)];
      this.$forceUpdate();
    },
    addChoice(idx) {
      this.questionList[idx].map.numAnswers = !this.questionList[idx].map.numAnswers ? 5 : (this.questionList[idx].map.numAnswers + 1);
      this.questionList[idx].map['option' + this.questionList[idx].map.numAnswers] = '';
      this.$forceUpdate();
    },
    shiftFormType(idx, currentIndex, direction) {
      const formTypes = this.questionList[idx].map.form;
      if (direction === 'up' && currentIndex > 0) {
        // Swap the current form type with the one above it
        const temp = formTypes[currentIndex];
        formTypes[currentIndex] = formTypes[currentIndex - 1];
        formTypes[currentIndex - 1] = temp;
      } else if (direction === 'down' && currentIndex < formTypes.length - 1) {
        // Swap the current form type with the one below it
        const temp = formTypes[currentIndex];
        formTypes[currentIndex] = formTypes[currentIndex + 1];
        formTypes[currentIndex + 1] = temp;
      }

      this.$forceUpdate();
    },
    addFormType(idx, type) {
      if (!this.questionList[idx].map.form) {
        this.$set(this.questionList[idx].map, 'form', []); // Initialize form as an empty array
      }
      if (!this.questionList[idx].answer) {
        this.questionList[idx].answer = [];
      }
      this.questionList[idx].question = "Form";
      this.questionList[idx].map.form.push({ type: type });
      this.questionList[idx].answer.push({ type: type, submitAnswer: '' });
      this.$forceUpdate();
    },
    deleteFormType(idx, index) {
      // Check if the index is valid
      if (index >= 0 && index < this.questionList[idx].map.form.length) {
        // Remove the form type at the specified index
        this.questionList[idx].map.form.splice(index, 1);
        // Also remove the corresponding answer
        this.questionList[idx].answer.splice(index, 1);
      }
    }
  }
};
</script>