<template>
    <v-main>
        <v-card color="#1f1f1f" class="py-3 px-5" flat fluid rounded="0">
            <v-row align="center" justify="space-between">
                <v-col cols="12" sm="6" class="text-left">
                    <img style="width: 200px; margin-top: 5px;" :src="$bucket + '/image/logo-duo.svg'" />
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <img style="width: 200px; " :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
                <v-col cols="12" sm="6">
                    <h2 class="white--text" style="font-family: AirNZ Sans Semibold !important;">Training modules</h2>
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <v-btn color="#01b0b9" class="white--text" height="42" width="140" @click="createTraining">Create new</v-btn>
                </v-col>
            </v-row>
            <v-row justify="space-between">
                <v-col style="background-color: #1f1f1f;">
                    <v-data-table dark :headers="fieldList" :items="trainingModuleList" sort-by="id" :search="keyword" :footer-props="{ itemsPerPageOptions: [50,100,500,-1] }">
                        <template v-slot:top>
                            <v-row no-gutters class="pb-4">
                            <v-col cols="12">
                                <div class="d-flex" style="border-bottom: 2px solid white;">
                                <v-text-field v-model="keyword" label="Search" hide-details height="50" class="custom-text-field">
                                    <template v-slot:prepend>
                                    <v-icon class="pt-2">mdi-magnify</v-icon>
                                    </template>
                                </v-text-field>
                                </div>
                            </v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.name="{ item }"><u>{{ item.name }}</u></template>
                        <template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 120) }}</template>
                        <template v-slot:item.active="{ item }">
                            <div class="pl-2">
                            <v-icon v-if="item.active === true" color="#00a0a8" title="Active" size="18" class="icon-tm-active"></v-icon>
                            <v-icon v-else color="grey" title="Inactive" size="18" class="icon-tm-draft"></v-icon>
                            </div>
                        </template>
                        <template v-slot:item.action="{ item }">
                            <div class="d-flex flex-row">

                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                    <v-icon size="18" class="mr-2 icon-tm-quick-edit" v-on="{ ...tooltip }" @click="editTrainingModule(item)
                                        "></v-icon>
                                    </template>
                                    <span>Edit</span>
                                </v-tooltip>

                                <!-- <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                    <v-icon size="18" class="ml-3 icon-tm-target" v-on="{ ...tooltip }" @click="showUpdateTrainingModule(item)
                                        "></v-icon>
                                    </template>
                                    <span>Target</span>
                                </v-tooltip> -->

                                <v-tooltip top>
                                    <template v-slot:activator="{ on: tooltip }">
                                    <v-icon size="18" class="ml-2 icon-tm-delete" v-on="{ ...tooltip }" @click="showDeleteTrainingModule(item)
                                        "></v-icon>
                                    </template>
                                    <span>Delete</span>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:no-data>Empty list</template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-card>

        <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Delete training module</span>
                </v-card-title>
                <v-card-text>Are you sure you want to delete '{{ trainingModule.name }}' ({{ trainingModule.id }})?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogDelete = false">Cancel</v-btn>
                    <v-btn @click="deleteTrainingModule" color="accent">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- <v-dialog v-model="dialogUpdateState" max-width="450" persistent>
          <v-card class="mx-auto" max-width="450" color="#e7e7e7">
            <v-toolbar flat color="#2d2926" dark>
              <v-row justify="space-between">
                <v-col cols="12" align-self="center" class="ml-2" style="font-family: 'AirNZ Sans Semibold'; font-size: 22px;">Target Results</v-col>
              </v-row>
            </v-toolbar>

            <v-card-text>
              <div class="pt-6 text-center" style="font-weight: bold; font-size: 14px; color: black;">Select target states.</div>

              <v-container fluid class="pt-0 mt-0 ml-2">
                <v-row no-gutters justify="center" align="center">
                  <v-col
                    v-for="(target, index) in targetList"
                    :key="index"
                    cols="3"
                    justify="center" align="center"
                    style="height: 35px;"
                  >
                    <v-checkbox
                      v-model="trainingModule.states"
                      :value="target"
                      :label="target"
                      class="minimal-checkbox"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              
            </v-card-text>
            <v-card-actions class="pt-5">
              <v-row justify="center" class="mb-5">
                <v-btn outlined class="mr-2 cancel-btn black--text" height="40" width="140" @click="dialogUpdateState = false">Cancel</v-btn>
                <v-btn color="#01b0b9" class="white--text" height="40" width="140" @click="updateTrainingModule">Save</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
    </v-main>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
export default {
    data: function() {
        return {
            trainingModuleList: [],
            keyword: "",
            fieldList: [
				{
					text: "Id",
					value: "id",
					width: "10%"
				},
				{ text: "Title", value: "name", width: "20%" },
				{ text: "Description", value: "description", width: "60%" },
				{ text: "Status", value: "active", width: "10%" },
				{ text: "", value: "action", sortable: false }
			],
            dialogDelete: false,
            dialogUpdateState: false,
            trainingModule: {
                id : 0,
                name: "",
                states: []
            },
            // targetList: ["NSW", "ACT", "QLD", "VIC", "SA", "TAS", "WA", "NT"],
            cslides: [],
            cquizes: [],
        }
    },
    mounted: function() {
		this.listTrainingModule();
	},
    methods: {

        convertSlide(slides) {
            this.cslides.length = 0

            for(let i=0; i < slides.length; i++) {

                let cslide = {}
                cslide.layout = slides[i].valueMap.slideLayout
                cslide.backgroundColor = slides[i].valueMap.backgroundColor
                cslide.heading = slides[i].valueMap.headerTitle
                cslide.bodyCopy = slides[i].valueMap.bodyCopy
                cslide.buttonText = slides[i].valueMap.buttonText
                cslide.isDisplayAfterQuiz = slides[i].valueMap.isDisplayAfterQuiz
                cslide.type = slides[i].type
                cslide.name = slides[i].name
                
                cslide.imageName = slides[i].valueMap.imageName

                this.cslides.push(cslide)

            }
        },
        convertQuiz(quizes) {
            this.cquizes.length = 0

            for(let i=0; i < quizes.length; i++) {

                let cquiz = {}

                cquiz.question = quizes[i].valueMap.question
                cquiz.imageNameQuestion = quizes[i].valueMap.imageNameQuestion
                cquiz.answer1 = quizes[i].valueMap.answer1
                cquiz.answer2 = quizes[i].valueMap.answer2
                cquiz.answer3 = quizes[i].valueMap.answer3
                cquiz.answer4 = quizes[i].valueMap.answer4
                cquiz.imageNameAnswer1 = quizes[i].valueMap.imageNameAnswer1
                cquiz.imageNameAnswer2 = quizes[i].valueMap.imageNameAnswer2
                cquiz.imageNameAnswer3 = quizes[i].valueMap.imageNameAnswer3
                cquiz.imageNameAnswer4 = quizes[i].valueMap.imageNameAnswer4

                cquiz.quizLayout = quizes[i].type == "new-quiz-1" ? "1" : "2"

                cquiz.backgroundColor = quizes[i].valueMap.backgroundColor
                cquiz.type = quizes[i].type
                cquiz.name = quizes[i].name

                cquiz.correctAnswer = quizes[i].valueMap.correctAnswer

                this.cquizes.push(cquiz)
            }
        },  

        async editTrainingModule(item) {
            
            this.$store.commit("resetTrainingModule")
            let module = {...this.$store.state.trainingModule}
            module.id = item.id
            module.details.isActive = item.active
            module.details.name = item.name
            module.details.description = item.description

            await axios.post("/api/page/module/list", { moduleId: item.id })
            .then((response) => {

                let slides = response.data.filter(item => item.type.includes('training'))
                let quizes = response.data.filter(item => !item.type.includes('training'))

                this.convertSlide(slides)
                this.convertQuiz(quizes)

                module.slides = [...this.cslides]
                module.quizes = [...this.cquizes]

            })
            .catch(() => {});

            this.$store.commit("setTrainingModule", module)

            this.$router.push({path: "/administration/training/edit/module"});            
        },
        createTraining() {
            this.$router.push({path: "/administration/training/create/module"});
        },
        listTrainingModule() {
			axios
				.get("/api/trainingModule/list")
				.then(response => {
					this.trainingModuleList = response.data;
				})
				.catch(() => {});
		},
        showDeleteTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogDelete = true;
		},
        deleteTrainingModule() {
			axios
				.post("/api/trainingModule/delete", { id: this.trainingModule.id })
				.then(() => {
					this.dialogDelete = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},
        showUpdateTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogUpdateState = true;
		},
        updateTrainingModule() {
			axios
				.post("/system/trainingModule/state/edit", { trainingModule: this.trainingModule })
				.then(() => {
					this.dialogUpdateState = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},
    }
}
</script>
