<template>
    <div>
        <v-card color="#1f1f1f" class="pt-12 pl-12">
            <div v-if="showSlideLayout">
                <br/>
                <v-row justify="space-between" style="width: 600px;">
                    <!-- Slide 1 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 1 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 1; formSlides.type = 'new-training-1'; ">
                    <v-row class="justify-center" style="background-color: #221551; height: 52px;">
                        <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                    </v-row>
                    <v-row style="background-color: #2d2926; height: 80px;">
                        <v-col cols="12" class="justify-center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 50px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 30px;">
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    </v-col>

                    <!-- Slide 2 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 2 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 2; formSlides.type = 'new-training-2'; ">
                    <v-row style="background-color: #2d2926; height: 82px;">
                        <v-col cols="12" class="justify-center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 50px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 30px;">
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    <v-row class="justify-center" style="background-color: #221551; height: 50px;">
                        <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                    </v-row>
                    </v-col>

                    <!-- Slide 3 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 3 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 3; formSlides.type = 'new-training-3'; ">
                    <v-row class="justify-center" style="background-color: #221551; height: 60px;">
                        <v-icon size="50" color="#440099">mdi-image-area</v-icon>
                        <div style="margin-top: 29px; position: absolute;" class="d-flex justify-center">
                        <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; position: absolute; width: 100px;">
                        </div>
                        </div>
                    </v-row>
                    <v-row style="background-color: #2d2926; height: 72px;">
                        <v-col cols="12" class="justify-center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 30px;">
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    </v-col>

                </v-row>

                <v-row justify="start" style="width: 600px;" class="pt-8">
                    <!-- Slide 4 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 4 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 4; formSlides.type = 'new-training-4'; ">
                    <v-row style="height: 132px;">
                        <v-col cols="6" align-self="center" style="background-color: #221551; height: 128px;">
                        <div class="col justify-center align-center pl-1 mx-0">
                            <v-icon size="60" color="#440099">mdi-image-area</v-icon>
                        </div>
                        </v-col>
                        <v-col cols="6" align-self="center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    </v-col>

                    <v-col cols="1" style="max-width: 30px;"></v-col>

                    <!-- Slide 5 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 5 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 5; formSlides.type = 'new-training-5'; ">
                    <v-row style="height: 132px;">
                        <v-col cols="6" align-self="center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 120px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                            </div>
                        </div>
                        </v-col>
                        <v-col cols="6" align-self="center" style="background-color: #221551; height: 128px;">
                        <div class="col justify-center align-center pl-1 mx-0">
                            <v-icon size="60" color="#440099">mdi-image-area</v-icon>
                        </div>
                        </v-col>
                    </v-row>
                    </v-col>

                </v-row>

                <v-row justify="start" style="width: 600px;" class="pt-8">
                    <!-- Slide 6 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 6 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 6; formSlides.type = 'new-training-6'; ">
                    <v-row style="height: 132px;">
                        <v-col cols="12" align-self="center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-start">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    </v-col>

                    <v-col cols="1" style="max-width: 30px;"></v-col>

                    <!-- Slide 7 -->
                    <v-col cols="4" style="max-width: 180px;"
                    :style="formSlides.slideLayout == 7 ? 'border: 2px solid #107d82;' : 'border: 1px solid #5d5b5b;'"
                    @click="formSlides.slideLayout = 7; formSlides.type = 'new-training-7'; ">
                    <v-row style="height: 132px;">
                        <v-col cols="12" align-self="center">
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid white; background-color: white; height: 2px; width: 40px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #5e5d5d; background-color: #5e5d5d; height: 2px; width: 140px;">
                            </div>
                        </div>
                        <div style="margin-bottom: 8px;" class="d-flex justify-center">
                            <div
                            style="max-height: 2px; border: 2px solid #00a0a8; background-color: #00a0a8; height: 2px; width: 20px;">
                            </div>
                        </div>
                        </v-col>
                    </v-row>
                    </v-col>
                </v-row>

                <v-container fluid>
                    <v-row justify="start" class="pt-6" style="width: 300px;">
                        <div style="color: grey; font-family: AirNZ Sans Regular !important; font-size: 14px;">Background Color</div>
                    </v-row>
                    <v-row justify="start" class="pt-2" style="width: 300px;">
                        <!-- Black circle with border -->
                        <div class="circle-black mr-3"
                        :style="formSlides.backgroundColor == 'black' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="formSlides.backgroundColor = 'black';"></div>
                        <!-- White circle with border -->
                        <div class="circle-white mr-3"
                        :style="formSlides.backgroundColor == 'white' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="formSlides.backgroundColor = 'white';"></div>
                        <!-- Purple circle with border -->
                        <div class="circle-purple"
                        :style="formSlides.backgroundColor == 'purple' ? 'border: 2px solid #107d82 !important;' : 'border: 1px solid #5d5b5b !important;'"
                        @click="formSlides.backgroundColor = 'purple';"></div>
                    </v-row>
                </v-container>

                <v-container fluid>
                    <v-row justify="start" class="pt-6" style="width: 300px;">
                        <div style="color: grey; font-family: AirNZ Sans Regular !important; font-size: 14px;">Hero Image</div>
                    </v-row>
                    <v-row justify="start" class="pt-2" style="width: 700px;">
                        <v-btn v-if="$root.isBlank(formSlides.imageName) && openAddImageFromLibrary == false" outlined class="mr-4 white--text" height="45" width="200" @click="showImageLib('image1')">
                        Add Image from library
                        </v-btn>
                        <v-btn v-if="$root.isBlank(formSlides.imageName) && openUploadNewImage == false" outlined class="mr-4 white--text" height="45" width="200" @click="openFileDialog()">
                        Upload new image
                        </v-btn>
                        <input type="file" ref="attachment2" accept="image/png, image/jpeg, image/jpg" style="display: none;"
                            required />

                        <template v-if="!$root.isBlank(formSlides.imageName) && !$root.isBlank(attachment2)">
                        <v-col>
                            <v-text-field v-model="formSlides.imageName" label="Upload Background Image"
                            prepend-icon="mdi-file" readonly dark />
                        </v-col>
                        <v-col cols="auto"> 
                            <v-icon color="duoQuaternary" class="mb-0 white--text"
                            @click="attachment2 = null; formSlides.imageName = ''; refresh();">mdi-trash-can</v-icon>
                        </v-col>
                        </template>
                    </v-row>
                </v-container>

                <v-container fluid class="mx-0 px-0">
                    <v-row>
                        <v-col cols="12" sm="12">
                        <v-text-field label="Heading1" style="font-size: 14px;" v-model="formSlides.heading" class="white--text"
                            dark></v-text-field>
                        <div class="mt-4">
                            <p style="color: #BCBCBC;" class="mb-0">Body Copy</p>
                            <v-textarea v-model="formSlides.bodyCopy" class="white--text" rows="6" outlined dark></v-textarea>
                        </div>
                        <v-text-field label="Button Text" v-model="formSlides.buttonText" class="white--text mt-0 pt-0"
                            dark></v-text-field>
                        <v-checkbox v-model="formSlides.isDisplayAfterQuiz" :value="false" label="Slide displays after quiz"
                            dark></v-checkbox>
                        </v-col>
                    </v-row>
                </v-container>

            </div>
            <!-- Slides List -->
            <div else>
                <v-row align="center" justify="space-between">
                    <v-col cols="9" >
                        <h2 class="white--text pl-5" style="font-family: AirNZ Sans Semibold !important;">Slide Layout</h2>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col v-if="!showSlideLayout">
                        <v-btn color="#440099" class="white--text" height="42" width="120" @click="showSlideLayout = true">Add new</v-btn>
                    </v-col>
                </v-row>

                <v-container v-if="moduleId" fluid>
                    <!-- <draggable v-if="isLoaded" v-bind="dragOptions" v-model="slideList" class="row justify-start"> -->
                    <draggable v-bind="dragOptions" v-model="slideList" class="row justify-start">
                        <v-col cols="4" class="my-0 py-0" v-for="(page, index) in slideList" :key="index + 0">
                        <v-card dark flat rounded="0" height="100%"
                            @click="$router.push('/administration/duoTrainingModule/' + moduleId + '/page/' + page.id + '/configuration')">
                            <v-card-text class="ma-0 pa-0" style="cursor: move;">
                            <div v-for="pageType in pageTypeStaticData" :key="pageType.type">
                                <v-img v-if="page.type === pageType.type" :src="pageType.srcImage" contain eager
                                style="border: 1px solid white;"></v-img>
                            </div>
                            </v-card-text>
                            <v-card-actions>
                            <v-row class="d-flex" justify="space-between">
                                <v-col cols="10">
                                <div v-for="pageType in pageTypeStaticData" :key="pageType.type">
                                    {{ validateTitle(page, pageType, index) }} 
                                </div>
                                </v-col>
                                <v-col cols="2">
                                <v-btn fab right x-small elevation="1" style="right: -8px;" @click.stop="showDeletePage(page)">
                                    <v-icon class="icon-tm-delete pb-2" size="14"></v-icon>
                                </v-btn>
                                </v-col>
                            </v-row>
                            </v-card-actions>
                        </v-card>
                        </v-col>
                    </draggable>
                    <!-- <v-row v-else>
                        <v-col height="260" class="col-12 col-sm-6 col-md-4 col-lg-3" v-for="n in 8" :key="n">
                        <v-skeleton-loader class="mx-auto" type="list-item-two-line, image"></v-skeleton-loader>
                        </v-col>
                    </v-row> -->
                </v-container>
            </div>
        </v-card>

        <v-dialog v-model="dialogPageDelete" max-width="600px">
            <v-card>
                <v-card-title>
                    <span class="headline">Delete {{ title }}</span>
                </v-card-title>
                <v-card-text>Are you sure you want to delete slide '{{ page.valueMap.headerTitle }}' ?</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="dialogPageDelete = false">Cancel</v-btn>
                    <v-btn @click="deletePage()" color="accent">Delete</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import draggable from "vuedraggable";
export default {
    components: {
        draggable
    },
    props: ["showSlideLayout", "formSlides", "pageList", "moduleId"],
    computed: {
		dragOptions() {
			return {
				animation: 200,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},
		isLoaded() {
			return !this.$root.isBlank(this.trainingModule);
		}
	},
    data: function() {
        return {
            slideList: [],
            dialogPageDelete : false,
            title: 'page',
            pageTypeStaticData: [
                { type: "start-1", title: "Start 1 Page", srcImage: "/template/anz-training-start-1.jpg" },
                { type: "start-2", title: "Start 2 Page", srcImage: "/template/anz-training-start-2.jpg" },
                { type: "start-3", title: "Start 3 Page", srcImage: "/template/anz-training-start-3.jpg" },
                { type: "info-image-1", title: "Information Image 1 Page", srcImage: "/template/anz-training-info-image-1.jpg" },
                { type: "info-image-2", title: "Information Image 2 Page", srcImage: "/template/anz-training-info-image-2.jpg" },
                { type: "info-image-3", title: "Information Image 3 Page", srcImage: "/template/anz-training-info-image-3.jpg" },
                { type: "info-image-4", title: "Information Image 4 Page", srcImage: "/template/anz-training-info-image-4.jpg" },
                { type: "info-image-5", title: "Information Image Full Page", srcImage: "/template/anz-training-info-image-5.jpg" },
                { type: "info-video-1", title: "Information Video 1 Page", srcImage: "/template/anz-training-info-video-1.jpg" },
                { type: "info-video-2", title: "Information Video 2 Page", srcImage: "/template/anz-training-info-video-2.jpg" },
                { type: "info-text-1", title: "Information Text 1 Page", srcImage: "/template/anz-training-info-text-1.jpg" },
                { type: "info-text-2", title: "Information Text 2 Page", srcImage: "/template/anz-training-info-text-2.jpg" },
                { type: "multiple-choice-1", title: "Multiple Choice 1 Page", srcImage: "/template/anz-training-question-multiple-choice-1.jpg" },
                { type: "multiple-choice-2", title: "Multiple Choice 2 Page", srcImage: "/template/anz-training-question-multiple-choice-2.jpg" },
                { type: "short-answer", title: "Short Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg" },
                { type: "free-answer", title: "Free Answer Page", srcImage: "/template/anz-training-question-short-answer.jpg" },

                { type: "new-training-", title: "Slide Title", srcImage: "/template/new-training.jpg" },

                { type: "new-training-1", title: "Slide Title", srcImage: "/template/new-training-1.jpg" },
                { type: "new-training-2", title: "Slide Title", srcImage: "/template/new-training-2.jpg" },
                { type: "new-training-3", title: "Slide Title", srcImage: "/template/new-training-3.jpg" },
                { type: "new-training-4", title: "Slide Title", srcImage: "/template/new-training-4.jpg" },
                { type: "new-training-5", title: "Slide Title", srcImage: "/template/new-training-5.jpg" },
                { type: "new-training-6", title: "Slide Title", srcImage: "/template/new-training-6.jpg" },
                { type: "new-training-7", title: "Slide Title", srcImage: "/template/new-training-7.jpg" },
            ],
            page: {
				valueMap: {
					headerTitle: "",
					headerBackgroundColor: {
						// grey
						hexa: '#424242'
					},
					headerIconColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					headerIconBackgroundColor: {
						// purple
						hexa: '#221551'
					},
					bodySummary: "",
					bodyTip: "",
					bodyNote: "",
					bodyIconTextColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					bodyIconBackgroundColor: {
						// purple
						hexa: '#440099'
					},
					bodyQuestionMultipleChoice: {},
					bodyQuestionShortAnswer: {},
					footerHasBackButton: false,
					footerButtonConfig: "",

                    bodyCopy: "",
                    buttonText: "",
                    slideLayout: 0,
                    backgroundColor: "black",
                    isDisplayAfterQuiz: false,
                    type: '',
                    name: ''
				}
			},
        }
    },
    mounted: function() {
        this.slideList = [...this.pageList]
	},
    methods: {
        validateTitle(data, pageType, index) {
            var title = pageType.title;
            var header = data.valueMap ? data.valueMap.headerTitle : null;
            if (data.type != pageType.type) {
                return "";
            }
            if (header) {
                var div = document.createElement("div");
                div.innerHTML = header;
                title = div.textContent || div.innerText || "";
            }
            return (index + 1) + ". " + title;
        },
        listPage(id) {
            this.moduleId = id
			axios
				.get("/api/page/module/list?moduleId=" + id)
				.then(response => {

					this.slideList = response.data

				})
				.catch(() => {});
		},
        deletePage() {
			axios
				.post("/api/page/delete?id=" + this.page.id, {})
				.then(() => {
					this.dialogPageDelete = false;
					this.listPage(this.page.moduleId);
				})
				.catch(() => {});
		},
        showDeletePage(item) {
			this.page = this.$root.clone(item);
			this.dialogPageDelete = true;
		},
    }
}
</script>