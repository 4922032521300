<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="headers" :items="dynamicFormList" sort-by="id" :search="keyword"
			:footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<h2>Form Report</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details
								style="margin-right:10px;" />
							<v-btn small fab dark color="secondary" title="Add" @click="showAddDialog">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 100) }}</template>
			<template v-slot:item.active="{ item }">
				<v-icon v-if="item.active === true" color="green" title="Active">mdi-check</v-icon>
				<v-icon v-else color="red" title="Inactive">mdi-block-helper</v-icon>
			</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex flex-row">
					<v-icon @click="$router.push(`/administration/form/${item.id}/details`)">mdi-account-group-outline</v-icon>
				</div>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>

		<v-dialog v-model="dialogUpdate" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ entity.id ? "Update Form Info" : "Add New Form" }}</span>
				</v-card-title>
				<v-card-text>
					<v-text-field @input="entity.slug = entity.title ? entity.title.replaceAll(' ', '') : null"
						v-model="entity.title" :rules="[(v) => !!v || 'Enter Title']" label="Title" rows="3" />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="closeDialog">Cancel</v-btn>
					<v-btn @click="save" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-main>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";

export default {
	mounted() {
		this.getList();
	},
	data() {
		return {
			headers: [
				{ text: "Title", value: "title" },
                { text: "Total Submitted", value: "personCount" },
				{ text: "", value: "action", sortable: false },
			],
			entity: {},
			dynamicFormList: [],
		}
	},
	methods: {
		getList() {
			axios.get("/system/creator/form/report/list").then(response => {
				this.dynamicFormList = response.data;
			})
		},
	}
}
</script>