<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex align-center pl-5 pt-4" :style="'width: 100%; height: 58px; font-size: 28px;' + ' color: ' + color">
    <div class="d-flex flex-column">
      <span style="font-size: 28px; line-height: 1.5;" v-html="label ? label.replace('<p>', '').replace('</p>', '') : ''"></span>
      <!-- <span class="duo-underline" :style="{marginTop: isEdit == true ? '0' : '-18px', fontSize: '28px'}"></span> -->
      <span class="duo-underline" :style="{ color: color }"></span>
    </div>
  </div>
  <duo-tile-title-mobile v-else :label="label" :color="color"></duo-tile-title-mobile>
</template>

<script>
import duoTileTitleMobile from "../mobile/duoTileTitleMobile.vue"
export default {
  components: {
		duoTileTitleMobile
	},
  props: {
    label: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#2d2926",
    }
  },
}
</script>