<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="headers" :items="dynamicFormList" sort-by="id" :search="keyword"
			:footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="6">
						<div class="d-flex justify-start align-center">
							<a>
								<v-icon @click="$router.go(-1)" color="black" class="pl-4">mdi-arrow-left</v-icon>
							</a>
							<div class="d-flex px-2 align-center">
								<h2>{{ dynamicForm.title }}</h2>
							</div>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details
								style="margin-right:10px;" />
							<v-btn small fab dark color="secondary" title="Add" @click="showAddDialog">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.name="{ item }">{{
				item.firstName + " " + item.lastName
			}}</template>
			<template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 100) }}</template>
			<template v-slot:item.active="{ item }">
				<v-icon v-if="item.active === true" color="green" title="Active">mdi-check</v-icon>
				<v-icon v-else color="red" title="Inactive">mdi-block-helper</v-icon>
			</template>
			<template v-slot:item.answer="{ item }">{{
				item.answerCount + ' of ' + dynamicForm.questionCount
			}}</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex flex-row">
					<v-icon
						@click="$router.push(`/administration/form/${$route.params.id}/details/${item.id}/submission`)">mdi-account-group-outline</v-icon>
				</div>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>
	</v-main>
</template>

<script>
/* eslint-disable no-console */

import axios from "axios";

export default {
	mounted() {
		this.getDetails();
		this.getList();
	},
	data() {
		return {
			keyword: "",
			headers: [
				{ text: "Name", value: "name" },
				{ text: "Email", value: "email" },
				{ text: "Mobile Number", value: "mobileNumber" },
				{ text: "Account Name", value: "agencyName" },
				{ text: "State", value: "state" },
				{ text: "Answered Questions", value: "answer" },
				{ text: "Submitted", value: "submittedDate" },
				{ text: "", value: "action", sortable: false },
			],
			dynamicForm: {},
			dynamicFormList: [],
		}
	},
	methods: {
		getDetails() {
			axios.get("/system/creator/form?id=" + this.$route.params.id).then(response => {
				this.dynamicForm = response.data;
			})
		},
		getList() {
			axios.get("/system/creator/form/report/submission?id=" + this.$route.params.id).then(response => {
				this.dynamicFormList = response.data;
			})
		},
	}
}
</script>