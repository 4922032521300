<template>
    <v-main>
        <v-card color="#1f1f1f" class="py-3 px-5" flat fluid rounded="0">
            <v-row align="center" justify="space-between">
                <v-col cols="12" sm="6" class="text-left">
                    <img style="width: 200px; margin-top: 5px;" :src="$bucket + '/image/logo-duo.svg'" />
                </v-col>
                <v-col cols="12" sm="6" class="text-right">
                    <img style="width: 200px; " :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
                </v-col>
            </v-row>
            <v-row align="center" justify="space-between">
                <v-col cols="12" sm="6">
                    <h2 class="white--text" style="font-family: AirNZ Sans Semibold !important;">{{ title }}</h2>
                </v-col>
                <v-col v-if="!dialogAddTraining" cols="12" sm="6" class="text-right">
                    <v-btn color="#01b0b9" class="white--text" height="42" width="140" @click="onCreateTrainingModule">Create new</v-btn>
                </v-col>
                <v-col v-if="dialogAddTraining" cols="12" sm="6" class="text-right">
                    <v-btn outlined class="mr-4 cancel-btn white--text" height="45" width="100" @click="dialogAddTraining=false; showSlideLayout=false">
                    Cancel
                    </v-btn>
                    <v-btn color="#01b0b9" class="white--text" height="45" width="100" @click="onSaveTraining">Save1</v-btn>
                </v-col>
                
            </v-row>
            <v-row v-if="!dialogAddTraining" align="center" justify="space-between">
                <v-col style="background-color: #1f1f1f; ">
                    <v-data-table dark :headers="fieldList" :items="trainingModuleList" sort-by="id" :search="keyword" :footer-props="{ itemsPerPageOptions: [50,100,500,-1] }">
                    <template v-slot:top>
                        <v-row no-gutters class="pb-4">
                        <v-col cols="12">
                            <div class="d-flex" style="border-bottom: 2px solid white;">
                            <v-text-field v-model="keyword" label="Search" hide-details height="50" class="custom-text-field">
                                <template v-slot:prepend>
                                <v-icon class="pt-2">mdi-magnify</v-icon>
                                </template>
                            </v-text-field>
                            </div>
                        </v-col>
                        </v-row>
                    </template>
                    <template v-slot:item.name="{ item }"><u>{{ item.name }}</u></template>
                    <template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 120) }}</template>
                    <template v-slot:item.active="{ item }">
                        <div class="pl-2">
                        <v-icon v-if="item.active === true" color="#00a0a8" title="Active" size="18" class="icon-tm-active"></v-icon>
                        <v-icon v-else color="grey" title="Inactive" size="18" class="icon-tm-draft"></v-icon>
                        </div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <div class="d-flex flex-row">
                        <!-- <v-icon @click="$router.push('/administration/duoTrainingModule/' + item.id + '/configuration')" size="18" class="icon-tm-quick-edit"></v-icon> -->
                        <v-icon size="18" class="icon-tm-quick-edit" @click="showEditTrainingModule(item)"></v-icon>
                        <v-icon size="18" class="ml-3 icon-tm-target" @click="showUpdateTrainingModule(item);"></v-icon>
                        <v-icon size="18" class="ml-4 icon-tm-delete" @click="showDeleteTrainingModule(item)"></v-icon>
                        </div>
                    </template>
                    <template v-slot:no-data>Empty list</template>
                    </v-data-table>

                    <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                        <v-card-title>
                        <span class="headline">Delete {{ title }}</span>
                        </v-card-title>
                        <v-card-text>Are you sure you want to delete '{{ trainingModule.name }}' ({{ trainingModule.id }})?</v-card-text>
                        <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn @click="dialogDelete = false">Cancel</v-btn>
                        <v-btn @click="deleteTrainingModule" color="accent">Delete</v-btn>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>

                    <v-dialog v-model="dialogUpdate" max-width="450" persistent>
                    <v-card class="mx-auto" max-width="450" color="#e7e7e7">
                        <v-toolbar flat color="#2d2926" dark>
                        <v-row justify="space-between">
                            <v-col cols="12" align-self="center" class="ml-2" style="font-family: 'AirNZ Sans Semibold'; font-size: 22px;">Target Results</v-col>
                        </v-row>
                        </v-toolbar>

                        <v-card-text>
                        <div class="pt-6 text-center" style="font-weight: bold; font-size: 14px; color: black;">Select target states.</div>

                        <v-container fluid class="pt-0 mt-0 ml-2">
                            <v-row no-gutters justify="center" align="center">
                            <v-col
                                v-for="(target, index) in targetList"
                                :key="index"
                                cols="3"
                                justify="center" align="center"
                                style="height: 35px;"
                            >
                                <v-checkbox
                                v-model="trainingModule.states"
                                :value="target"
                                :label="target"
                                class="minimal-checkbox"
                                ></v-checkbox>
                            </v-col>
                            </v-row>
                        </v-container>
                        
                        </v-card-text>
                        <v-card-actions class="pt-5">
                        <v-row justify="center" class="mb-5">
                            <v-btn outlined class="mr-2 cancel-btn black--text" height="40" width="140" @click="dialogUpdate = false">Cancel</v-btn>
                            <v-btn color="#01b0b9" class="white--text" height="40" width="140" @click="updateTrainingModule">Save2</v-btn>
                        </v-row>
                        </v-card-actions>
                    </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-card>

        <v-tabs v-if="dialogAddTraining"  v-model="trainingTab" background-color="#1f1f1f" color="white">
            <v-tab v-for="item in iTab" :key="item.value" :value="item.value" :text="item.text">
                {{ item.text }}
            </v-tab>
        </v-tabs>
        
        <v-tabs-items v-if="dialogAddTraining" v-model="trainingTab">
            <!-- Details  -->
            <v-tab-item>
                <v-card color="#1f1f1f" class="pt-4 pl-12">
                    <v-row align="center" style="width: 800px;">
                        <v-col>
                            <v-row align="center" justify="start" style="width: 200px;">
                                <v-col>
                                <div class="white--text">{{ formFields.active ? 'Active' : 'Inactive' }}</div>
                                </v-col>
                                <v-col align-self="start">
                                <v-switch
                                    v-model="formFields.active"
                                    color="teal"
                                    inset
                                    class="white--text"
                                    dark
                                ></v-switch>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    <v-row align="center" justify="start">
                        <v-col>
                            <v-text-field
                                label="Title"
                                v-model="formFields.name"
                                class="white--text"
                                dark
                            ></v-text-field>
                            <v-text-field
                                label="Description"
                                v-model="formFields.description"
                                class="white--text"
                                dark
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card>
            </v-tab-item>
            <!-- Slides -->
            <v-tab-item>
                <admin-training-slide-tab 
                    :showSlideLayout="showSlideLayout" 
                    :formSlides="formSlides"
                    :pageList="pageList"
                    :moduleId="moduleId" />
            </v-tab-item>
            <!-- Quizes -->
            <v-tab-item>
                <admin-training-quiz-tab 
                    :showSlideLayout="showSlideLayout" 
                    :formSlides="formSlides"
                    :pageList="pageList"
                    :moduleId="moduleId" />
            </v-tab-item>
        </v-tabs-items>

        <!-- UPDATE TARGET TRAINING DIALOG -->
        <v-dialog v-model="dialogUpdate" max-width="450" persistent>
          <v-card class="mx-auto" max-width="450" color="#e7e7e7">
            <v-toolbar flat color="#2d2926" dark>
              <v-row justify="space-between">
                <v-col cols="12" align-self="center" class="ml-2" style="font-family: 'AirNZ Sans Semibold'; font-size: 22px;">Target Results</v-col>
              </v-row>
            </v-toolbar>

            <v-card-text>
              <div class="pt-6 text-center" style="font-weight: bold; font-size: 14px; color: black;">Select target states.</div>

              <v-container fluid class="pt-0 mt-0 ml-2">
                <v-row no-gutters justify="center" align="center">
                  <v-col
                    v-for="(target, index) in targetList"
                    :key="index"
                    cols="3"
                    justify="center" align="center"
                    style="height: 35px;"
                  >
                    <v-checkbox
                      v-model="trainingModule.states"
                      :value="target"
                      :label="target"
                      class="minimal-checkbox"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
              
            </v-card-text>
            <v-card-actions class="pt-5">
              <v-row justify="center" class="mb-5">
                <v-btn outlined class="mr-2 cancel-btn black--text" height="40" width="140" @click="dialogUpdate = false">Cancel</v-btn>
                <v-btn color="#01b0b9" class="white--text" height="40" width="140" @click="updateTrainingModule">Save3</v-btn>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- DELETE TRAINING DIALOG -->
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Delete {{ title }}</span>
            </v-card-title>
            <v-card-text>Are you sure you want to delete '{{ trainingModule.name }}' ({{ trainingModule.id }})?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="dialogDelete = false">Cancel</v-btn>
              <v-btn @click="deleteTrainingModule" color="accent">Delete</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- MISING TITLE DIALOG -->
        <v-dialog v-model="showMissingTitleDialog" max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Missing Title</span>
            </v-card-title>
            <v-card-text>Please fill the slide title first on Details tab before saving</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn @click="showMissingTitleDialog = false">OK</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

    </v-main>
</template>

<script>
/* eslint-disable no-console */
import axios from "axios";
import AdminTrainingSlideTab from './adminTrainingSlideTab.vue';
import AdminTrainingQuizTab from './adminTrainingQuizTab.vue'
export default {
    components: {
        AdminTrainingSlideTab,
        AdminTrainingQuizTab,
    },
    data: function() {
        return {
            // SLIDES TAB
            showSlideLayout: false,

            openUploadNewImage: false,
            openAddImageFromLibrary: false,
            title: 'page',
            showMissingTitleDialog: false,

            dialogPageDelete : false,

            page: {
				valueMap: {
					headerTitle: "",
					headerBackgroundColor: {
						// grey
						hexa: '#424242'
					},
					headerIconColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					headerIconBackgroundColor: {
						// purple
						hexa: '#221551'
					},
					bodySummary: "",
					bodyTip: "",
					bodyNote: "",
					bodyIconTextColor: {
						// white
						hexa: '#FFFFFFFF'
					},
					bodyIconBackgroundColor: {
						// purple
						hexa: '#440099'
					},
					bodyQuestionMultipleChoice: {},
					bodyQuestionShortAnswer: {},
					footerHasBackButton: false,
					footerButtonConfig: "",

                    bodyCopy: "",
                    buttonText: "",
                    slideLayout: 0,
                    backgroundColor: "black",
                    isDisplayAfterQuiz: false,
                    type: '',
                    name: ''
				}
			},
			pageList: [],
            // trainingModule: {},
            moduleId: 0,
            formSlides : {
                slideLayout: 1,
                backgroundColor: 'black',
                heading: '',
                bodyCopy: '',
                buttonText: '',
                isDisplayAfterQuiz: false,
                type: '',
                name: ''
            },
            formQuiz : {
                slideLayout: 1,
                backgroundColor: 'black',
                heading: '',
                bodyCopy: '',
                buttonText: '',
                isDisplayAfterQuiz: false,
                type: '',
                name: ''
            },
            trainingTab: 0,
            itemTab: ["Details", "Slides", "Quizes"],
            iTab: [
                {text: 'Details', value: 'details'},
                {text: 'Slides', value: 'slides'},
                {text: 'Quizes', value: 'quizes'}
            ],
            tab: 'option-1',
            text: "Lorem ipsum dolor sit amet",

            formFields: {
                id: null,
                active: true,
                name: '',
                description: ''
            },

			targetList: ["NSW", "ACT", "QLD", "VIC", "SA", "TAS", "WA", "NT"],
			fieldList: [
				{
					text: "Id",
					value: "id",
					width: "10%"
				},
				{ text: "Title", value: "name", width: "20%" },
				{ text: "Description", value: "description", width: "60%" },
				{ text: "Status", value: "active", width: "10%" },
				{ text: "", value: "action", sortable: false }
			],
			keyword: "",
			trainingModuleList: [],
			trainingModule: {
        states: []
      },
			dialogAddTraining: false,
			dialogEdit: false,
			dialogDelete: false,
			dialogUpdate: false
		};
    },
    mounted: function() {
		this.listTrainingModule();
        this.showSlideLayout = false;
	},
    methods: {
        showEditTrainingModule(item) {

            //get Slides
            this.listPage(item.id)

            //get Details
            this.formFields.id = item.id
            this.formFields.active = item.active
            this.formFields.name = item.name
            this.formFields.description = item.description

            console.log("Choosen Form Fields : ", this.formFields)

            this.dialogAddTraining = true
        },
        resetAllFields() {

            this.formFields.id = null
            this.formFields.active = true
            this.formFields.name = ''
            this.formFields.description = ''
            this.formFields.name = ''
            this.formFields.type = ''

            this.formSlides.slideLayout = 1
            this.formSlides.backgroundColor = 'black'
            this.formSlides.heading = ''
            this.formSlides.bodyCopy = ''
            this.formSlides.buttonText = ''
            this.formSlides.isDisplayAfterQuiz = false
            this.formSlides.name = ''
            this.formSlides.type = ''

            this.page.valueMap.headerTitle = ''
            this.page.valueMap.bodyCopy = ''
            this.page.valueMap.buttonText = ''
            this.page.valueMap.slideLayout = 0
            this.page.valueMap.backgroundColor = 'black'
            this.page.valueMap.isDisplayAfterQuiz = false

        },
        findTrainingModule() {
			axios
				.get("/api/trainingModule?id=" + this.moduleId)
				.then(response => {
					this.trainingModule = response.data;
				})
				.catch(() => {});
		},
        listPage(id) {
            this.moduleId = id
            console.log(id)
			axios
				.get("/api/page/module/list?moduleId=" + id)
				.then(response => {

                    this.pageList = response.data
                    console.log("Response Page List: ", response.data)

                    // Filter out pages with specified types
                    //this.pageList = response.data.filter(page => ["multiple-choice-1", "multiple-choice-2", "short-answer", "free-answer"].includes(page.type));
				})
				.catch(() => {});
		},
        listTrainingModule() {
			axios
				.get("/api/trainingModule/list")
				.then(response => {
					this.trainingModuleList = response.data;
				})
				.catch(() => {});
		},
        onCreateTrainingModule() {
            this.dialogAddTraining = true
        },
        onSaveTraining() {

            if (this.formFields.id == null) {   // add

                if (this.formFields.name.length > 0) {
                    axios
                    .post("/api/trainingModule/add", { trainingModule: this.formFields })
                    .then((response) => {

                        this.page.valueMap.headerTitle = this.formSlides.heading
                        this.page.valueMap.bodyCopy = this.formSlides.bodyCopy
                        this.page.valueMap.buttonText = this.formSlides.buttonText
                        this.page.valueMap.slideLayout = this.formSlides.slideLayout
                        this.page.valueMap.backgroundColor = this.formSlides.backgroundColor
                        this.page.valueMap.isDisplayAfterQuiz = this.formSlides.isDisplayAfterQuiz

                        this.page.moduleId = response.data.id
                        this.page.name = this.formSlides.type
                        this.page.type = this.page.name
                        this.page.sortOrder = 1001

                        axios.post("/api/page/add", {page : this.page})
                        .then(() => {
                            this.listTrainingModule();
                            this.dialogAddTraining = false;
                        })
                        .catch(() => { })
                        .finally(() => this.resetAllFields())
                    })
                    .catch(() => { });
                } else {
                    this.showMissingTitleDialog = true;
                }
            
            } else {    // edit

                if (this.formFields.name.length > 0) {
                
                    axios
                    .post("/api/trainingModule/edit", { trainingModule: this.formFields })
                    .then((response) => {

                        console.log("Edit : ", JSON.parse(response.config.data).trainingModule.id)

                        console.log("formSlides : ", this.formSlides)

                        // add slide
                        if (this.formSlides.heading.length > 0 && this.formSlides.slideLayout > 0) {
                            
                            this.page.moduleId = JSON.parse(response.config.data).trainingModule.id

                            this.page.valueMap.headerTitle = this.formSlides.heading
                            this.page.valueMap.bodyCopy = this.formSlides.bodyCopy
                            this.page.valueMap.buttonText = this.formSlides.buttonText
                            this.page.valueMap.slideLayout = this.formSlides.slideLayout
                            this.page.valueMap.backgroundColor = this.formSlides.backgroundColor
                            this.page.valueMap.isDisplayAfterQuiz = this.formSlides.isDisplayAfterQuiz

                            this.page.name = this.formSlides.type
                            this.page.type = this.page.name
                            this.page.sortOrder = 1001

                            axios.post("/api/page/add", {page : this.page})
                            .then(() => {
                                this.listTrainingModule();
                                this.dialogAddTraining = false;
                            })
                            .catch(() => { })
                            .finally(() => this.resetAllFields());
                        }
                    })
                    .catch(() => { });
                
                } else {
                    this.showMissingTitleDialog = true;
                }

            }

        },

        showUpdateTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogUpdate = true;
		},
        updateTrainingModule() {
			axios
				.post("/system/trainingModule/state/edit", { trainingModule: this.trainingModule })
				.then(() => {
					this.dialogUpdate = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},

        showDeleteTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogDelete = true;
		},
        deleteTrainingModule() {
			axios
				.post("/api/trainingModule/delete", { id: this.trainingModule.id })
				.then(() => {
					this.dialogDelete = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},

    }
}
</script>
