import { render, staticRenderFns } from "./trainingModulePageNew3.vue?vue&type=template&id=48c41d12&scoped=true&"
import script from "./trainingModulePageNew3.vue?vue&type=script&lang=js&"
export * from "./trainingModulePageNew3.vue?vue&type=script&lang=js&"
import style0 from "./trainingModulePageNew3.vue?vue&type=style&index=0&id=48c41d12&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c41d12",
  null
  
)

export default component.exports