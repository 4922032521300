<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            min-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-tiles/Singapore.Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            We’re thrilled to be partnering with House of Travel and Singapore Tourism Board to bring you the opportunity to win one of nine spots on the famil of a lifetime!
          </h2>
          <p>
            <b>The famil prize includes the below for travel 6th November - 13th November 2024.</b>
          </p>
          <div style="background-color:white; margin-bottom:1.5vw; padding:1.5vw;">
            <ul>
              <li>
                <b>Return flights on Air New Zealand</b> from Auckland to Singapore in <b>Premium Economy</b>
              </li>
              <li style="margin-top: 10px;">
                <b>Air New Zealand Lounge access</b> to start the trip off with some luxury in Auckland
              </li>
              <li style="margin-top: 10px;">
                <b>Accomodation</b> in Singapore
              </li>
              <li style="margin-top: 10px;">
                <b>Explore Singapore, with unforgettable experiences like:</b>
              </li>
              <li style="list-style-type: none; margin-left: 1vw; ">
                  <div style="width: 500px; float:left; margin-top: 10px;" >
                    <ul style="list-style-type:disc">
                      <li style="margin-top: 10px;">Brass Lion Gin Making Class</li>
                      <li style="margin-top: 10px;">Gardens by the Bay (Flower Dome & Cloud Forest)</li>
                      <li style="margin-top: 10px;">Singapore Sidecars</li>
                      <li style="margin-top: 10px;">Mr Bucket Chocolaterie</li>
                    </ul>
                  </div>
                  <div style="width: 220px; float:left">
                    <ul style="list-style-type:disc">
                      <li style="margin-top: 10px;">Museum of Ice Cream</li>
                      <li style="margin-top: 10px;">Singapore Flyer</li>
                      <li style="margin-top: 10px;">Sentosa</li>
                    </ul>
                  </div>
                  <br style="clear:both;"/>
              </li>
            </ul>
          </div>
          <!--  -->
              <v-row>
                <v-col
                  v-for="tile in 3"
                  :key="tile.title"
                  cols="4"
                  md="4"
                  sm="12"
                >
                  <v-card tile elevation="0">
                    <v-img
                      :src="$bucket + '/image/dashboard-tiles/Singapore.Image.'+ (tile)+'.jpg'"
                    ></v-img>
                  </v-card>
                </v-col>
              </v-row>
          <!--  -->
          <p>
            <b>Sounds amazing, right? To enter:</b>
          </p>
          <p>
            <ul>
              <li>Log your eligible sales from Auckland to Singapore or beyond on 086 ticket stock during the incentive 2-22 September 2024.</li>
              <li>Complete our Discover Singapore module, brought to you in conjunction with House of Travel and Singapore Tourism Board.</li>
            </ul>
          </p>
          <p>
            <b>If you don’t log a sale you still have a chance to win! Make sure you complete the Discover Singapore module to qualify.</b>
          </p>
          <p>
            <ul>
              <li>Every AKL-SIN-beyond sale = 1 point</li>
              <li>Every AKL-SIN point to point sale = 2 points</li>
              <li>1x bonus point for AKL-SIN or beyond on 086 in Premium Economy or Business Premier</li>
            </ul>
          </p>
          <p>
            <b>How can I Sling my way to Singapore? <br />
              Complete the training <a href="https://duo.airnzagent.co.nz/#/training/module/164149/" target="_blank">module</a> and log your sales on 086 to AKL-SIN or beyond!</b>
          </p>
          <p>
            <ul>
              <li>1x Top Destination Seller – agent with the most points on AKL-SIN</li>
              <li>5x Top Sellers – agents with the most points on AKL-SIN or beyond</li>
              <li>3x Wildcards – drawn at random</li>
            </ul>
          </p>
          <p>
            <b>Winners will be announced on Friday 27<sup>th</sup> September via House of Travel.</b>
          </p>
          <p style="font-size: 13px">
            <a
              style="font-size: 13px; color: black; font-style: italic"
              :href="$window.location.origin + '/#/singaporeTC'"
              target="_blank"
              >Terms and conditions apply.</a
            >
          </p>
        </div>
      </v-row>
      <br />
      <v-row no-gutters >
        <duo-training-tile 
                        style="margin-left: -20px;"
                        @onClickEdit="showEditDialog(item)" :map="item.map"
                        @onClickHide="showDialogConfirmation('unpublish', item.id)"
                        v-for="item in tileList" :img="item.image" :title="item.title" :content="item.content"
                        :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :link="item.link"
                        :status="getModuleStatus(item.link)" @onClickButton="onStartTraining(item.link, item.title)" />
      </v-row>
    </v-container>

    <v-dialog v-model="dialogRetake" max-width="400">
      <v-card class="mx-auto" max-width="400">
        <v-toolbar flat color="#221551" dark>
          <v-toolbar-title>{{moduleStatus == 'Resume' ? 'Resume' : 'Finish' }}</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <h2 class="text-h6 my-4">{{ moduleStatus == 'Resume' ? 'You haven\'t finished this training yet' : 'You have finished this training' }}</h2>
          <h2 class="text-h6 my-4" v-if="moduleStatus != 'Resume'">Your Score: {{ finalScore }}</h2>
        </v-card-text>

        <v-card-actions class="justify-center pb-6">
          <v-btn @click="handleRetakeModule()" class="duo-primary">Retake</v-btn>
          <v-btn @click="handleReviewModule()" class="duo-secondary">{{moduleStatus == 'Resume' ? 'Resume' : 'Review'}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Training Module is disabled -->
    <v-dialog v-model="dialogModuleDisable" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>Info</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">The training module is disabled</h2>
          </v-card-text>

          <v-card-actions class="justify-center pb-6">
            <v-btn @click="closeDialogModule" class="duo-primary">Cancel</v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";
import duoTrainingTile from './creator/duoTrainingTile.vue';
import axios from "axios";

export default {
  components: {
    subHeader,
    duoTrainingTile
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getList();
    if (this.$store.state.principal.administrator == false) {
      this.listCompletedModule();
    }
  },
  data() {
    return {
      retakeModule: false,
      dialogRetake: false,
      moduleId: 0,
      moduleStatus: "",
      finalScore: 0,
      showScore: false,
      dialogModuleDisable: false,
      tileList: [],
      completedModuleList: [],
      choosenTitle: "",
    };
  },
  methods: {
    getList() {
      axios
          .get("/system/tile/training")
          .then((response) => {
              this.tileList = response.data.filter(function (el) {
                  return el.sequence;
              })
              this.tileList = response.data.filter(function (el) {
                  return el.link === "164149"; //singapore training module linkid on production
              })
          })
          .catch(() => { });
    },
    listCompletedModule() {
      axios
        .get("/system/score/completed")
        .then((response) => {
          this.completedModuleList = response.data;
        }).catch(() => { });
    },
    onStartTraining(id, title) {
      this.choosenTitle = title;
      this.moduleId = id;
      // axios.get('/system/trainingModule/list').then((response) => {
      axios.get('/system/trainingModule/list/all').then((response) => {
        const res = response.data.find((v) => Number(v.id) === Number(id))
        if (res.active) {
          this.checkPersonScore(this.choosenTitle);
        } else {
          this.dialogModuleDisable = true;
        }
      });
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.moduleStatus = this.getModuleStatus(response.data.moduleId);
            this.dialogRetake = true;
            this.moduleId && this.getModulePageQuiz(this.moduleId);
          } else {
            this.$router.push({path: `/training/module/${this.moduleId}/`, query: { title: this.choosenTitle.replace(/(<([^>]+)>)/gi, "") }});
          }
        })
        .catch(() => { });
    },
    getModuleStatus(moduleId) {
      let result = this.completedModuleList.find(score => Number(score.moduleId) === Number(moduleId));
      if (result != null) {
        return result.transitMap.scoreStatus;
      } else {
        return null;
      }
    },
    getModulePageQuiz(id) {
      if (id !== undefined) axios.get(`/system/page/module/list?moduleId=${id}`).then((response) => {
        this.showScore = response.data.some((v) => v.name.includes('choice') || v.name.includes('answer'))
      });
    },
    closeDialogModule() {
      this.dialogModuleDisable = false;
    }
  },
};
</script>
