<template>
	<v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
		<subHeader/>

		<div class="container-account-setting">
			<v-expansion-panels flat>
				<v-expansion-panel class="mb-5">
					<v-expansion-panel-header style="padding: 0 23px" expand-icon disabled>
						<v-container fluid>
							<v-row no-gutters style="font-size: 18px">
								<p><b>Account settings</b></p>
							</v-row>
							<v-row no-gutters style="font-size: 17px; line-height: 21px">
								<p>
									Here's where you can manage your <b>duo</b> account and review important information. <br/>If you have any problems or require further information, please contact
									<a class="duo" href="mailto:duosupportnz@airnz.co.nz">duosupportnz@airnz.co.nz</a>
								</p>
							</v-row>
						</v-container>
					</v-expansion-panel-header>
				</v-expansion-panel>

				<v-expansion-panel class="mb-5">
					<v-expansion-panel-header class="expand-header" style="padding: 0 23px">
						<v-container fluid>
							<v-row no-gutters style="font-size: 18px">
								<p><b>Update password</b></p>
							</v-row>
							<v-row no-gutters style="font-size: 17px; line-height: 21px">
								<p>
									It's a good idea to protect your personal details by updating your eight-digit password regularly.
									<br/>Any new password must contain at least one number and one special character.
								</p>
							</v-row>
						</v-container>
					</v-expansion-panel-header>
					<v-expansion-panel-content>
						<v-form ref="form" v-model="valid">
							<v-row class="d-flex justify-center">
								<v-col cols="5">
									<v-text-field
											id="currentPassword"
											class="custom-input"
											label="Current Password"
											v-model="entity.map.currentPassword"
											:append-icon="passwordType1 ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="() => (passwordType1 = !passwordType1)"
											:type="passwordType1 ? 'password' : 'text'"
											:rules="[(v) => !!v || 'Sorry, we dont recognise that password. Please try again.']"
									>
										<template v-slot:label>
											<span style="font-size: 17px; color: #2d2926">Current password</span>
										</template>
									</v-text-field>
									<v-text-field
											id="newPassword"
											class="custom-input"
											label="New Password"
											v-model="entity.map.newPassword"
											:append-icon="passwordType2 ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="() => (passwordType2 = !passwordType2)"
											:type="passwordType2 ? 'password' : 'text'"
											persistent-hint
											:rules="[(v) => !!v || 'Enter a strong password', (v) => (v && v.match('^(?=.*\\d)(?=.*[^A-Za-z0-9]).{8,}$') && true) || 'Your password must be STRONG']"
									>
										<template v-slot:label>
											<span style="font-size: 17px; color: #2d2926">New password</span>
										</template>
										<template v-slot:message>
											<span>Your password must be <b>STRONG</b></span>
											<ul>
												<li>Passwords must have at least one non-alphanumeric character</li>
												<li>Passwords must have at least one digit ('0'-'9')</li>
												<li>Minimum 8 characters</li>
											</ul>
										</template>
									</v-text-field>
									<!-- <div
										v-bind:class="{
											'password-strength-initial': true,
											'password-strength-veryweak': passwordStrength.isVeryWeak,
											'password-strength-weak': passwordStrength.isWeak,
											'password-strength-strong': passwordStrength.isStrong,
										}"
										style="display: flex; justify-content: center; align-items: center"
									>-->
									<v-row v-if="!$root.isBlank(entity.map.newPassword)" no-gutters justify="center">
										<p style="font-size: 12px !important; font-family: 'AirNZ Sans Regular' !important">Password strength</p>
										&nbsp;
										<p style="font-size: 12px !important; font-family: 'AirNZ Sans Semibold' !important">{{ passwordStrength.message }} password</p>
									</v-row>
									<!-- </div> -->
									<v-text-field
											id="confirmPassword"
											class="custom-input"
											label="Confirm New Password"
											v-model="entity.map.confirmPassword"
											:append-icon="passwordType3 ? 'mdi-eye' : 'mdi-eye-off'"
											@click:append="() => (passwordType3 = !passwordType3)"
											:type="passwordType3 ? 'password' : 'text'"
											style="margin-top: 20px"
											:rules="[(v) => !!v || 'Please confirm your password', (v) => (v && v === entity.map.newPassword) || 'Password does not match']"
									>
										<template v-slot:label>
											<span style="font-size: 17px; color: #2d2926">Confirm new password</span>
										</template>
									</v-text-field>

									<!-- <v-text-field
										id="password"
										v-model="password"
										persistent-hint
										:rules="[(v) => !!v || 'Enter a strong password', (v) => (v && v.match('^(?=.*\\d)(?=.*[^A-Za-z0-9]).{8,}$') && true) || 'Your password must be STRONG']"
										:append-icon="passwordType ? 'mdi-eye' : 'mdi-eye-off'"
										@click:append="() => (passwordType = !passwordType)"
										:type="passwordType ? 'password' : 'text'"
										:class="{ 'line-blue': password != '' && password.length > 0 }"
									>
										<template v-slot:label>
											<span class="duo">Password*</span>
										</template>
										<template v-slot:message>
											<span>Your password must be <b>STRONG</b></span>
											<ul>
												<li>Passwords must have at least one non-alphanumeric character</li>
												<li>Passwords must have at least one digit ('0'-'9')</li>
												<li>Minimum 8 characters</li>
											</ul>
										</template>
									</v-text-field> -->

									<v-row class="justify-end mb-4">
										<v-btn :disabled="!valid" color="#00B0B9" width="193px" height="50px" outlined elevation="0" @click="submitChangePassword()" style="text-transform: none; font-size: 17px"> Save</v-btn>
									</v-row>
								</v-col>
							</v-row>
						</v-form>
					</v-expansion-panel-content>
				</v-expansion-panel>

				<v-expansion-panel class="mb-5">
					<v-expansion-panel-header class="expand-header" style="padding: 0 23px" expand-icon disabled>
						<v-container fluid>
							<v-row no-gutters style="font-size: 18px">
								<p><b>Privacy statement</b></p>
							</v-row>
							<v-row no-gutters style="font-size: 17px; line-height: 21px">
								<p>For information about Air New Zealand's privacy practices, including how you can view, correct or delete your personal information please see our <a style="color: #00b0b9; font-size: 17px" href="https://www.airnewzealand.co.nz/privacy-policy#personal-information" target="_blank">Privacy Statement</a>.</p>
							</v-row>
						</v-container>
					</v-expansion-panel-header>
				</v-expansion-panel>

				<v-expansion-panel class="mb-5">
					<v-expansion-panel-header class="expand-header" style="padding: 0 23px">
						<v-container fluid>
							<v-row no-gutters style="font-size: 18px">
								<p><b>Manage account</b></p>
							</v-row>
							<v-row no-gutters style="font-size: 17px; line-height: 21px">
								<p>You can either temporarily close your account, or delete it for good.</p>
							</v-row>
						</v-container>
					</v-expansion-panel-header>
					<v-expansion-panel-content style="padding: 0 11px">
						<v-row no-gutters class="mb-4">
							<v-divider></v-divider>
						</v-row>
						<v-row no-gutters align="center">
							<v-col>
								<v-row no-gutters style="font-size: 18px">
									<p>
										<b>Take a break</b>
									</p>
								</v-row>
								<v-row no-gutters class="pr-8" style="font-size: 17px; line-height: 21px">
									<p>
										If you want to pause <b>duo</b> activity for a while, simply take a break, and all your bookings and training history will remain against your profile until you come back. You can re-activate your account in future by
										contacting
										<a class="duo" href="mailto:duosupportnz@airnz.co.nz">duosupportnz@airnz.co.nz</a>
									</p>
								</v-row>
							</v-col>
							<v-btn @click="dialogBreak = !dialogBreak" elevation="0" class="btn-mng-account">Take a break</v-btn>
						</v-row>

						<v-row no-gutters class="my-3">
							<v-divider></v-divider>
						</v-row>

						<!-- delete account -->
						<v-row no-gutters align="center">
							<v-col>
								<v-row no-gutters style="font-size: 18px">
									<p>
										<b style="font-family: AirNZ Sans Semibold">Delete account</b>
									</p>
								</v-row>
								<v-row no-gutters class="pr-8" style="font-size: 17px; line-height: 21px">
									<p>
										If you’d like to say goodbye to <b>duo</b> for good - your account, along with personal information will be permanently deleted from our system and cannot be reactivated. If you’d like to rejoin <b>duo</b> at any point,
										you’ll need to register for a new account.
									</p>
								</v-row>
							</v-col>
							<v-btn @click="dialogDelete = !dialogDelete" elevation="0" class="btn-mng-account">Delete account</v-btn>
						</v-row>
						<!-- delete account end -->
					</v-expansion-panel-content>
				</v-expansion-panel>
			</v-expansion-panels>

			<div class="text-center">
				<v-dialog v-model="dialogBreak" width="505px">
					<v-card class="white" height="100%" :style="{ borderRadius: '8px'}">
						<v-card-title class="pa-0" height="66px" :style="{ backgroundColor: '#440099' }">
							<div :style="{ margin: '18px 17px 18px 24.5px' }">
								<v-img width="30px" height="30px" :src="$bucket + '/image/warning.svg' " contain style="color: white;"></v-img>
							</div>
							<div>
								<span class="notif-title-text">Bye for Now</span>
							</div>
						</v-card-title>
						<v-card-text class="pa-0 px-3 pt-2 d-flex justify-center" height="190.71px">
							<div class="notification-content-warning-style">
								<div v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
									<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
									<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
									<div :key="item" v-for="(item, index) in $store.state.notification.content.noticeList">
										<div v-if="index == 0 && item.includes('Session')" class="notification-content-text-style">{{ item }}</div>
										<div v-else class="regular-text-style" style="color: #2D2926 !important; padding-top: 20px;">{{ item }}</div>
									</div>
								</div>
								<div v-else>
									<div class="notif-content-text">
										Once you confirm, you will be logged out and your account will be deactivated.<br />To re-activate your account contact
										<a href="mailto:duosupportnz@airnz.co.nz" class="duo">duosupportnz@airnz.co.nz</a>.
									</div>
								</div>
							</div>
						</v-card-text>
						<v-card-actions class="px-0 pt-0 justify-center" height="93.29px" :style="{ paddingBottom: '29.85px'}">
							<v-btn @click="submitCloseAccountBreak()" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom dark>
								<span class="regular-text-style">Got it</span>
							</v-btn>
							<div style="padding-right: 17px;"></div>
							<v-btn @click="dialogBreak = false" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom outlined>
								<span class="regular-text-style" style="color: black !important;">Cancel</span>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogDelete" width="505px">
					<v-card class="white" height="100%" :style="{ borderRadius: '8px'}">
						<v-card-title class="pa-0" height="66px" :style="{ backgroundColor: '#440099' }">
							<div :style="{ margin: '18px 17px 18px 24.5px' }">
								<v-img width="30px" height="30px" :src="$bucket + '/image/warning.svg' " contain style="color: white;"></v-img>
							</div>
							<div>
								<span class="notif-title-text">Warning</span>
							</div>
						</v-card-title>
						<v-card-text class="pa-0 px-3 pt-2 d-flex justify-center" height="190.71px">
							<div class="notification-content-warning-style">
								<div v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
									<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
									<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
									<div :key="item" v-for="(item, index) in $store.state.notification.content.noticeList">
										<div v-if="index == 0 && item.includes('Session')" class="notification-content-text-style">{{ item }}</div>
										<div v-else class="regular-text-style" style="color: #2D2926 !important; padding-top: 20px;">{{ item }}</div>
									</div>
								</div>
								<div v-else>
									<div class="notif-content-text">
										Once you confirm, you will be logged out and your account will be permanently deleted.
									</div>
								</div>
							</div>
						</v-card-text>
						<v-card-actions class="px-0 pt-0 justify-center" height="93.29px" :style="{ paddingBottom: '29.85px'}">
							<v-btn @click="submitCloseAccountDelete()" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom dark>
								<span class="regular-text-style">Got it</span>
							</v-btn>
							<div style="padding-right: 17px;"></div>
							<v-btn @click="dialogDelete= false" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom outlined>
								<span class="regular-text-style" style="color: black !important;">Cancel</span>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

				<v-dialog v-model="dialogFinish" width="505px">
					<v-card class="white" height="100%" :style="{ borderRadius: '8px'}">
						<v-card-title class="pa-0" height="66px" :style="{ backgroundColor: '#440099' }">
							<div :style="{ margin: '18px 17px 18px 24.5px' }">
								<v-img width="30px" height="30px" :src="$bucket + '/image/warning.svg' " contain style="color: white;"></v-img>
							</div>
							<div>
								<span v-if="entity.map.closeAccountType == 'break'" class="notif-title-text">Welcome Back</span>
								<span v-else-if="entity.map.closeAccountType == 'delete'" class="notif-title-text">Welcome Back</span>
								<span v-else class="notif-title-text">Success</span>
							</div>
						</v-card-title>
						<v-card-text class="pa-0 px-3 pt-2 d-flex justify-center" height="190.71px">
							<div class="notification-content-warning-style">
								<div v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
									<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
									<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
									<div :key="item" v-for="(item, index) in $store.state.notification.content.noticeList">
										<div v-if="index == 0 && item.includes('Session')" class="notification-content-text-style">{{ item }}</div>
										<div v-else class="regular-text-style" style="color: #2D2926 !important; padding-top: 20px;">{{ item }}</div>
									</div>
								</div>
								<div v-else>
									<div v-if="entity.map.closeAccountType == 'break'" class="notif-content-text">
											This account has been temporarily closed. To re-active your account please contact
											<a href="mailto:duosupportnz@airnz.co.nz" style="text-decoration: none">duosupportnz@airnz.co.nz</a>.
									</div>
									<div v-else-if="entity.map.closeAccountType == 'delete'" class="notif-content-text">
											This account has been permanently closed.
									</div>
									<div v-else class="notif-content-text">
											The password has been updated.
									</div>
								</div>
							</div>
						</v-card-text>
						<v-card-actions class="px-0 pt-0 justify-center" height="93.29px" :style="{ paddingBottom: '29.85px'}">
							<v-btn @click="entity.map.closeAccountType === true ? signOut() : (dialogFinish = false)" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" width="192.7px" bottom dark>
								<span class="regular-text-style">Close</span>
							</v-btn>
						</v-card-actions>
					</v-card>
				</v-dialog>

			</div>
		</div>
	</v-container>

	<account-setting-mobile v-else></account-setting-mobile>
</template>

<style scoped>
.notif-title-text {
	letter-spacing: 0;
	color: #fff;
	text-align: left;
	font-style: normal;
	font-variant: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 32px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
	white-space: pre-wrap;
}
.notif-content-text {
	padding-top: 30px;
	padding-bottom: 30px;
	letter-spacing: 0;
	text-align: center;
	font-style: normal;
	font-variant: normal;
	font-weight: 400;
	font-size:18px;
	color: #2d2926;
	line-height: 21px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
}
.expand-header .mdi-chevron-down::before {
	content: "\F0140";
	font-size: 25px !important;
}

.custom-input.v-input input {
	font-size: 17px !important;
}

.theme--light.v-divider {
	border-color: #707070;
}
</style>
<style scoped>
.container-account-setting {
	padding: 0 85px;
}

.btn-mng-account {
	width: 192.2px !important;
	height: 50px !important;
	color: white;
	background-color: #2d2926 !important;
	border-radius: 4px !important;
	opacity: 1 !important;
	text-transform: none;
	font-size: 17px;
	letter-spacing: 0;
}

.btn-save {
	width: 162px !important;
	height: 46px !important;
	border: 1px solid #00b0b9 !important;
	color: #00b0b9 !important;
	border-radius: 4px !important;
	opacity: 1;
}

.theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
	color: #221551 !important;
}

.v-expansion-panels > .v-expansion-panel {
	background: rgba(255, 255, 255, 0.9) !important;
	border-radius: 9px !important;
	padding: 10px 5px !important;
}

.v-application--is-ltr .v-expansion-panel-header {
	text-align: left;
	padding: 34 38px !important;
}

.btn-width-custom {
	min-width: 200px !important;
}

.v-expansion-panel-header__icon {
	padding-right: 3%;
}

.airnz-PageBlock {
	max-width: 1060px;
	margin-right: auto;
	margin-left: auto;
}

.airnz-PageBlock--lg {
	max-width: 1312px;
}

@media print, screen and (min-width: 768px) and (min-height: 450px) {
	.airnz-PageBlock {
		padding-left: 40px;
		padding-right: 40px;
	}
}

@media screen and (min-width: 992px) and (min-height: 450px) {
	.airnz-PageBlock {
		padding-left: 80px;
		padding-right: 80px;
	}
}

.airnz-PageBlock--fullWidth {
	padding-right: 0;
	padding-right: var(--airnz-space-0);
	padding-left: 0;
	padding-left: var(--airnz-space-0);
	max-width: 100%;
}

.theme--light.v-expansion-panels .custom-color-size.v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
	color: #2d2926 !important;
	font-size: 35px;
}
</style>

<script>
import axios from "axios";
import subHeader from "./subHeader.vue";
import accountSettingMobile from "./mobile/accountSettingMobile.vue"

export default {
	components: {
		subHeader,
		accountSettingMobile
	},
	data() {
		return {
			entity: {
				map: {
					currentPassword: "",
					newPassword: "",
					confirmPassword: ""
				}
			},
			showUpdatePassword: false,
			showPublicPolicy: false,
			showCloseDuoAccount: false,
			passwordType1: true,
			passwordType2: true,
			passwordType3: true,
			dialogBreak: false,
			dialogDelete: false,
			dialogFinish: false,
			valid: true
		};
	},
	mounted: function () {
		this.profile();
	},
	computed: {
		passwordStrength() {
			let data = {
				message: "Weak",
				isVeryWeak: false,
				isWeak: false,
				isFair: false,
				isStrong: false
			};
			if (typeof this.entity.map.newPassword !== "undefined" && !this.$root.isBlank(this.entity.map.newPassword)) {
				const regex = /^(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/g;
				let isSpecial = this.entity.map.newPassword.match(regex);
				// data.isVeryWeak = this.entity.map.newPassword.length >= 1 && this.entity.map.newPassword.length < 8 ? true : data.isVeryWeak;
				data.isWeak = this.entity.map.newPassword.length >= 1 && this.entity.map.newPassword.length < 8 ? true : data.isWeak;

				// data.message = data.isVeryWeak ? "Very Weak" : data.message;
				// data.isWeak = isSpecial || this.entity.map.newPassword.length >= 8 ? true : data.isWeak;
				data.message = data.isWeak ? "Weak" : data.message;
				// data.isStrong = isSpecial && this.entity.map.newPassword.length > 12 ? true : data.isStrong;
				// data.message = data.isStrong ? "Strong" : data.message;
				data.isStrong = isSpecial && this.entity.map.newPassword.length >= 8 ? true : data.isStrong;
				data.message = data.isStrong ? "Strong" : data.message;
			}
			return data;
		}
	},
	methods: {
		profile() {
			axios
					.get("/system/person/me")
					.then((response) => {
						this.entity = response.data;
					})
					.catch(() => {
					});
		},
		submitChangePassword() {
			this.$refs.form.validate();
			if (this.$refs.form.validate()) {
				axios
						.post("/system/person/password/change", { entity: this.entity })
						.then(() => {
							this.dialogFinish = true;
							this.$refs.form.reset();
						})
						.catch(() => {
						});
			}
		},
		submitCloseAccountBreak() {
			this.entity.map.closeAccountType = "break";
			axios
					.post("/system/person/close/account", { entity: this.entity })
					.then(() => {
						(this.dialogBreak = false), (this.dialogFinish = true);
					})
					.catch(() => {
					});
		},
		submitCloseAccountDelete() {
			this.entity.map.closeAccountType = "delete";
			axios
					.post("/system/person/close/account", { entity: this.entity })
					.then(() => {
						(this.dialogDelete = false), (this.dialogFinish = true);
					})
					.catch(() => {
					});
		},
		signOut() {
			axios
					.post("/system/authentication/logout", {})
					.then(() => {
						axios.defaults.headers.common["Authorization"] = "";
						document.cookie = "isLoggedIn=false";
						this.$router.push({ path: "/login" });
					})
					.catch(() => {
					});
		}
	}
};
</script>
