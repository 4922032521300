<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row style="padding: 0px 60px 0px 0px">
        <h2>Seats to Suit Changes</h2>
        <v-col cols="12" class="pa-0">
          <p>We are refreshing out short-haul Seats to Suit offerings to allow greater flexibility and choice for your
            customers. </p>
          <p>Effective from 11 June 2024, seat<strong>+bag</strong> will no longer be offered on out short-haul Seats to
            Suit network. The refreshed Seats to Suit network will now include <strong>seat</strong>,
            the<strong>works</strong>, works<strong>flexi</strong>, <strong>premium</strong>economy,
            premium<strong>flexi</strong>, business<strong>premier™</strong> and business<strong>flexi</strong> fares.
          </p>
        </v-col>
      </v-row>

      <v-row class="py-3">
        <v-col cols="4" class="px-0">
          <v-row no-gutters :style="{ minHeight: '369px', backgroundColor: '#221551', color: 'white' }">
            <v-col cols="12">
              <v-card class="pt-2" :color="'duoQuaternary'" tile min-height="376" elevation="0">
                <div class="d-flex align-center pl-6" style="width: 100%; height: 58px; font-size: 28px">
                  <div :class="'d-flex flex-column white--text'">
                    <span><b>duo</b>training</span>
                    <span class="duo-underline"></span>
                  </div>
                  <v-spacer />
                </div>
                <v-card-text class="ma-1">
                  <v-row>
                    <v-col cols="12" class="pl-3">
                      <v-row>
                        <v-col cols="auto" class="pl-3">
                          <v-avatar size="76" :color="'white'">
                            <v-icon class="duo-icon-training" :color="'duoQuaternary'" size="32"></v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col style="align-content: center;">
                          <div>
                            <span style="font-size: 28px; line-height: 1.2;"
                              :style="{ color: 'white !important' }" v-html="this.tileS2S.title"></span>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pl-4 pr-12">
                          <span :class="'duo-description white--text'" v-html="this.tileS2S.content"></span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="duo-description pl-3 pr-6">
                      <span :style="{ color: 'white' }"></span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn class="'duo-text-button elevation-0 '" width="193px" height="50px"
                        :style="{ fontSize: '17px', color: '#2d2926' }" :color="'white'"
                        @click="openSameTab('/#/training/module/27252/')" absolute bottom light>{{ this.tileS2S.buttonLabel }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="pl-12">
          <h2 class="pb-8">Key Points</h2>
          <ul class="mr-12 pr-6">
            <li>There will no longer be the option to purchase food onboard.</li>
            <li>Check out the <a
                v-on:click="openNewTab('https://p-airnz.com/cms/assets/NZ-Agents/Images/seat-2-suit-final__Resampled.jpg')">Agent
                Cheat
                Sheet here.</a>
            </li>
            <li><strong>seat</strong> only fares are <strong>now</strong> available to book in your GDS.</li>
            <li>You can book a seat only fare and add a bag as a EMD.</li>
          </ul>
        </v-col>
      </v-row>

      <v-row style="padding: 10px 0px 30px 0px;">
        <h2>Seats to Suit product overview</h2>
      </v-row>
      <v-row>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/seat.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;"><strong>seat</strong></span>
          <p>Choose <strong>seat</strong> if you've got a customer heading off on a quick trip or if they like to travel
            light. It could be a day trip for work. Or maybe children who don't have their own bag to check in.</p>
          <p>With <strong>seat</strong> customer get a carry-on bag allowance of up to 7kg and complimentary tea, coffe
            or water. Plus they'll enjoy full gate to gate entertainment.</p>
          <p><strong>Seat</strong> only fares can be booked in the GDS with a PTC/Buydown code (LBR). If baggage is
            required, this can be added with an EMD.</p>
          <a v-on:click="openNewTab('https://www.airnzagent.co.nz/seats-to-suit')">
            <p>Read more about <strong>seat</strong></p>
          </a>
        </v-col>
      </v-row>

      <v-divider class="my-6" />

      <v-row>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/theworks.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;">the<strong>works</strong></span>
          <p>Customer travelling with the<strong>works</strong> will continue to receive the same greate inclusion as we
            currenlty offer. One checked bag, a meal and drinks, full gate to gate entertainment and free standard seat
            selection.</p>
          <a
            v-on:click="openNewTab('https://www.airnewzealand.co.nz/the-works?_ga=2.154220344.1031886362.1714099604-2139070628.1713939461&_gl=1*alwsay*_ga*MjEzOTA3MDYyOC4xNzEzOTM5NDYx*_ga_7W0V730RP5*MTcxNDEwNDgzOC4zLjEuMTcxNDEwNDgzOS4wLjAuMA..')">
            <p>Read more about the<strong>works</strong></p>
          </a>
        </v-col>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/works-flexi.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;">works<strong>flexi</strong></span>
          <ul style="padding-top: 20px; padding-bottom: 20px;">
            <li>We've introduced affordable flexibility for your customers, which is available on all works fares
              regardless of the booking class. This is available from P to Y class.</li>
            <li>For only $60 NZD your customers can add flexibility by purchasing a works<strong>flexi</strong> fare
            </li>
            <li>works<strong>flexi</strong> customers will receive the flexibility to make free changes or even cancel
              their ticket to receive a full refund. Any additional fare and tax difference is still applicable when
              making changes. </li>
          </ul>
        </v-col>
      </v-row>

      <v-divider class="my-6" />

      <v-row>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/premium-economy.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;">premium<strong>economy</strong></span>
          <p>If your customers want all the benefits of premium travel at a great price choose
            <strong>premium</strong>economy. They'll
            get two priority checked bags (up to 23kg each) and two carry-on bags (up to 7kg each), and can relax in one
            of our luxury leather seats, with more personal space. Select a meal from our
            <strong>premium</strong>economy menu while
            enjoying inflight movies, TV, music and games from gate-to-gate.
          </p>
          <a
            v-on:click="openNewTab('https://www.airnewzealand.co.nz/premiumeconomy?_ga=2.98833182.1031886362.1714099604-2139070628.1713939461&_gl=1*131760*_ga*MjEzOTA3MDYyOC4xNzEzOTM5NDYx*_ga_7W0V730RP5*MTcxNDExNjk3MS42LjAuMTcxNDExNjk3MS4wLjAuMA..')">
            <p>Read more about <strong>premium</strong>economy</p>
          </a>
        </v-col>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/premium-flexi.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;">premium<strong>flexi</strong></span>
          <ul style="padding-top: 20px; padding-bottom: 20px;">
            <li>We've introduced affordable flexibility for your customers, which is available on all premium fares
              regardless of the booking class. This is available for A, O, E and U class.</li>
            <li>For an additional 80.00 NZD your customers can add flexibility by purchasing a
              premium<strong>flexi</strong> fare.</li>
            <li>premium<strong>flexi</strong> customers will receive the flexibility to make free changes or even cancel
              their ticket to
              receive a full refund. Any additional fare and tax difference is still applicable when making changes.
            </li>
          </ul>
        </v-col>
      </v-row>

      <v-divider class="my-6" />

      <v-row>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/business-premier.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;">business<strong>premier™</strong></span>
          <p>If your customer really wants to get the most out of flying with us, fly with
            business<strong>premier™</strong>. With three priority checked bags (up to 23kg each), and two carry-on bags
            (up to 7kg each), they can take everything they need. Relax in one of our comfortable leather armchairs that
            converts into a lie-flat bed when it's time to take a nap.</p>
          <p>
            They'll select meal from our business<strong>premier™</strong> menu, designed by our Consultant Chef Peter
            Gordon and accompanied by premium New Zealand wines. They'll also enjoy inflight movies, TV, music and games
            as well as premium check in, priority boarding, preferred seating and lounge access.

          </p>
          <a
            v-on:click="openNewTab('https://www.airnewzealand.co.nz/tasman-and-pacific-compare-products-business?_ga=2.128520204.1031886362.1714099604-2139070628.1713939461&_gl=1*st174i*_ga*MjEzOTA3MDYyOC4xNzEzOTM5NDYx*_ga_7W0V730RP5*MTcxNDExNjk3MS42LjAuMTcxNDExNjk3MS4wLjAuMA..')">
            <p>Read more about business<strong>premier™</strong></p>
          </a>
        </v-col>
        <v-col cols="3" class="px-0" style="align-content: center;">
          <v-img max-width="210px" :src="$bucket + '/image/dashboard-pages/business-flexi.png'" />
        </v-col>
        <v-col cols="9" class="px-0">
          <span style="font-size: 20px;">business<strong>flexi</strong></span>
          <ul style="padding-top: 20px; padding-bottom: 20px;">
            <li>We've introduced affordable flexibility for your customers, which is available on all works fares
              regardless of the booking class. This is available for J, Z, D, and C class.</li>
            <li>For only 120.00 NZD, your customers can add flexibility by purchasing a business<strong>flexi</strong>
              fare.
            </li>
            <li>business<strong>flexi</strong> customers will receive the flexibility to make free changes or even
              cancel
              their ticket to receive a full refund. Any additional fare and tax difference is still applicable when
              making changes. </li>
          </ul>
        </v-col>
      </v-row>

      <v-divider class="my-6" />

      <v-row>
        <h2>Our refreshed Seats to Suit</h2>
      </v-row>
      <v-row class="mt-0 d-flex justify-center text-center">
        <v-col cols="12" class="px-0 my-6">
          <div style="width: 1250px; height: 706px;">
            <video @click="onClickVideo" @play="onClickVideo" ref="video" id="video" width="100%" class="iframe-size"
              controls controlsList="nodownload">
              <source :src="$bucket + '/video/Seats to Suit PTC Sydney Corporate Micro.mp4'" type="video/mp4">
            </video>
          </div>
        </v-col>
      </v-row>

      <v-row>
        <h2>Agent Cheat Sheet</h2>
      </v-row>
      <v-row class="mt-0 d-flex justify-center text-center">
        <v-col cols="12" class="px-0 my-6">
          <div>
            <v-img :src="$bucket + '/image/dashboard-pages/diagram.png'" />
          </div>
        </v-col>
      </v-row>

      <v-row>
        <p>For more information visit <a
            @click="openNewTab('https://www.airnzagent.co.nz/seats-to-suit-changes-2')">airnzagent.co.nz/seats-to-suit-changes-2</a>
        </p>
      </v-row>

    </v-container>
  </v-container>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-size: 17px;
  padding-bottom: 1rem;
}
</style>

<script>
import axios from "axios";
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  data() {
    return {
      videoWatched: false,
      tileS2S: {}
    };
  },
  mounted: function () {
    this.getTrainingS2S();
    this.video = document.getElementById("video");
    window.scrollTo(0, 0);
  },
  methods: {
    onClickVideo() {
      if (this.videoWatched == false) {
        this.videoWatched = true
        this.$root.saveActivityLog({ type: 'Watch_video', value: "Seats to Suit PTC Sydney Corporate Micro" });
      }
    },
    openSameTab(url) {
      window.open(url, '_self')
    },
    openNewTab(url) {
      window.open(url, '_blank')
    },
    getTrainingS2S() {
      axios
        .get("/system/tile/training")
        .then((response) => {
          this.tileS2S = response.data.filter(v => v.id == 34001)[0]
        });
    },
  }
};
</script>