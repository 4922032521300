<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row style="padding: 0px 100px 10px 20px">
        <h2 class="pb-8" style="text-align: center">
          Terms and Conditions - Air New Zealand and Korea Tourism Organization
          – Win a trip to South Korea (“Promotion”)
        </h2>
        <v-col cols="12" class="pa-0">
          <ol>
            <li>
              Information on how to enter the promotion forms part of the
              conditions of entry. Entry into the promotion is deemed as
              acceptance of its terms and conditions.
            </li>
            <li>
              Entry is open to leisure-based Travel Agents and Brokers who are
              New Zealand-based and IATA-accredited or part of the Travel
              Industry Designated Service only. The Promoters reserve the right
              to verify the validity of entries.
            </li>
            <li>
              Grand Prize Draw: To enter the prize draw, participants must:
              <ol type="a">
                <li>
                  Book, and ticket flights to South Korea on 086 ticket stock
                  between 6 – 23 August for travel anytime. Agents must register
                  their ticketed Air New Zealand booking references through Duo
                  and complete the Discover South Korea module to be in to win.
                  Booked travel may be availed for any time. Bookings that are
                  ticketed and then refunded are not eligible.
                </li>
              </ol>
            </li>
            <li>
              Secondary Prize Draw: To enter the prize draw, participants must:
              <ol type="a">
                <li>
                  Complete the Korea Tourism Organization (KTO) Webinar module
                  on Duo.
                </li>
              </ol>
            </li>
            <li>Each booking will earn the Eligible Participant 1 point.</li>
            <li>
              Promotion commences 12:01am Tuesday 6th August and closes 11.59pm
              Friday 23 August 2024 (the "Promotional Period").
            </li>
            <li>
              Grand Prize Draw <br />
              There are 4 prizes to be won. Each prize consists of:
              <ul style="list-style-type:disc">
                <li>
                  2 N class Economy return flights from Auckland to Seoul with
                  domestic connection options available for those travelling
                  across New Zealand. Subject to availability. Blackout periods
                  apply.
                </li>
                <li>
                  4 nights twin share accommodation to the value of $2,250
                </li>
                <li>Airport transfers</li>
                <li>
                  Guided city tour in Seoul including Gyeongbok Palace,
                  Gwangjang Market and N Seoul Tower.
                  <ol style="list-style-type: list-style-custom;">
                    <li>
                      3 winners will be drawn based on the highest number
                      bookings logged via duosales.
                    </li>
                    <li>1 winner will be drawn at random from the entries.</li>
                  </ol>
                </li>
              </ul>
            </li>
            <li>
              Secondary Prize Draw
              <ul style="list-style-type:disc">
                <li>
                  There are 3 prizes to be won. Each prize consists of a $100
                  prezzy card.
                </li>
              </ul>
            </li>
            <li>
              Travel is on Air New Zealand Economy operated services from
              winners nearest Air New Zealand serviced domestic airport to Seoul
              via Auckland. Travel is for the specified dates only (28 October
              2024 to 23 August 2025) only. Tickets are non-changeable.
            </li>
            <li>
              Travel is not eligible for any frequent flyer (including Airpoints
              Dollars or Status Points) accrual, upgrades, companion redemption
              or credit of tier status. Travel is non-transferable,
              exchangeable, or redeemable for cash. All prize travel will be
              subject to Air New Zealand’s General Terms and Conditions of
              carriage, to view visit www.airnz.co.nz. Prize travel cannot be
              used in conjunction with Air New Zealand’s Airpoints™ Programme or
              any other carriers’ frequent flyer schemes.
            </li>
            <li>
              Attempts will be made to notify the winner(s) by phone or email at
              the contact telephone number or email address provided at the time
              of promotion entry and announced at www.airnzagent.co.nz site and
              in Air New Zealand’s WTPS. If the Promoters, having used
              reasonable efforts, are unable to contact the winner, then the
              prize is forfeited, and a further draw(s) will be conducted. The
              Promoters’ decision is final, and no correspondence will be
              entered into. Each prize winner will be at the discretion of the
              promoters.
            </li>
            <li>
              Winners will be drawn between the 2nd of September and 5th
              September 2024.
            </li>
            <li>
              The winners are responsible for paying all additional costs
              associated with the prize that are not specifically included (as
              stated in clause 7), including (where applicable) visa, transport,
              meal costs, spending money, insurance, and all other incidentals.
            </li>
            <li>
              The Promoters reserve the right to substitute the prize in whole
              (or any of its components), with a substitute prize of equal or
              greater value.
            </li>
            <li>
              Prize is non-transferable, non-redeemable for flight credit
              exchangeable or redeemable for cash.
            </li>
            <li>
              The Promoters assume no responsibility for any error, omission,
              interruption, deletion, defect, delay in operation or
              transmission, communications line failure, theft or destruction or
              unauthorised access to or alteration of entries. The Promoters
              assumes no responsibility for any injury or damage to participants
              or any other person's computer related to or resulting from
              participation in or downloading any materials in this promotion.
              Entries into this promotion will be stored in the Air New Zealand
              booking database.
            </li>
            <li>
              Neither the Promoters nor any other person or party associated
              with this promotion shall be liable for any loss or damage
              whatsoever suffered (including but not limited to indirect or
              consequential loss) or personal injury suffered or sustained in
              connection with either participation in this promotion or with any
              prizes offered.
            </li>
            <li>
              The Promoters collect entrants' personal information in order to
              conduct the promotion. If the information requested is not
              provided, the entrant may not participate in the promotion. Please
              refer to Air New Zealand's Privacy Statement at
              <a href="https://www.airnewzealand.co.nz/privacy" target="_blank" style="color: black">https://www.airnewzealand.co.nz/privacy</a> regarding the collection,
              use, disclosure and storage of personal information.
            </li>
            <li>
              The Promoters of this competition are Air New Zealand Limited,
              Private Bag 92007, Auckland, New Zealand and Korea Tourism
              Organization.
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
@counter-style list-style-custom {
  system: extends lower-alpha;
  suffix: '). ';
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>
