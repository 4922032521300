<template>
  <v-container fluid>
    <subHeader />
    <!-- {{ enableButton() }} -->
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            max-height="345"
            style="background-size: cover; background-position: center center"
            :src="'/image/dashboard-tiles/Honolulu.Hero.jpg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            We’re thrilled to offer two lucky agents the chance to win return Premium Economy flights for themselves and a companion to beautiful Honolulu!
          </h2>
          <p>
            <b>Imagine whisking away a partner, friend, parent, or child to Hawaii while enjoying our exceptional Premium Economy service.</b>
          </p>
          <p>
            To enter, simply log any sales made to Hawaii in Premium Economy or 
            Business&trade; between August 26th and September 11th, 2024. 
            The two agents with the highest number of bookings in these premium
            cabins will each win two return Premium Economy flights for themselves 
            and a companion!
          </p>
          <p>
            <b>Winners will be revealed at the Aloha Down Under events in Auckland and Wellington.</b>
          </p>
          <p style="font-size: 13px">
            <a
              style="font-size: 13px; color: black; font-style: italic"
              :href="$window.location.origin + '/#/honoluluTC'"
              target="_blank"
              >Terms and conditions apply.</a
            >
          </p>
        </div>
      </v-row>
      <v-row>
          <v-row>
            <v-col
              v-for="tile in 3"
              :key="tile.title"
              cols="4"
              md="4"
              sm="12"
            >
              <v-card tile elevation="0">
                <v-img
                  :src="'/image/dashboard-tiles/Honolulu.Image.'+ (tile)+'.jpg'"
                ></v-img>
              </v-card>
            </v-col>
          </v-row>
        <!-- </div> -->
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Regular";
  font-size: 22px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // tiles: [
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/933adc1b-797e-46cf-8708-d056aa4be62a",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/76403d50-dcd9-4685-a363-4a3d2f0699cc",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/ab2a133b-e3e7-4a84-acf2-2b2f5d00a9a8",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/7a7129e8-1287-48a3-8884-1b3dea847608",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/586b051c-12c2-4aa8-99b2-819bdb1d1480",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/08b45743-5a8b-411d-9892-ca6803d65964",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/07ad4413-5921-4abc-a907-3b944b09baec",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/babfe4d4-b74e-491e-a612-2d966af9ea8c",
      //     button: false,
      //   },
      //   {
      //     link: "https://xd.wayin.com/display/container/dc/770ba8d0-c72f-483a-bb4b-82e80854bc2b",
      //     button: false,
      //   },
      // ],
      // tile10Button: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    // visit(url, day) {
    //   window.open(url, "_blank");
    //   this.$root.saveActivityLog({
    //     type: "Visit_dashboard_tile",
    //     value: "Fiji Day " + day,
    //   });
    // },
    // enableButton() {
    //   let tzOffset = new Date().getTimezoneOffset() / -60;
    //   let startDate = new Date(Date.UTC(2024, 6, 16, 23, 59, 59));
    //   let dateNow = new Date().getDate();

    //   // eslint-disable-next-line no-console
    //   console.log(new Date(startDate.setHours(startDate.getHours() - tzOffset)), new Date())
      
    //   startDate = new Date(startDate.setHours(startDate.getHours() - tzOffset)).getDate()
      
    //   // eslint-disable-next-line no-console
    //   console.log(startDate, dateNow)

    //   if (dateNow - startDate <= 10) {
    //     for (let i = 1; i <= dateNow - startDate; i++) {
    //       i == 10 ? this.tile10Button = true : this.tiles[i - 1].button = true;
    //     }
    //   } else {
    //     for (let i = 1; i <= 10; i++) {
    //       i == 10 ? this.tile10Button = true : this.tiles[i - 1].button = true;
    //     }
    //   }
    // },
  },
};
</script>
