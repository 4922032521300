<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1266" max-height="345" style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-tiles/WinTheWarrios.Landing.jpeg'" />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2 style="padding-bottom: 1rem;">
            Air New Zealand is giving you the opportunity to win 1 of 20 spots to watch the Warriors training session at
            Go Media Stadium in Auckland on Friday 12th July 2024.
          </h2>
          <div style="padding: 2rem; background-color:#EAEAEA;">
            <v-row>
              <div class="col-10">
                <p style="font-size: 24px; font-weight: bold; margin-top: 0;">The winning agents will experience...</p>
                <ul class="pl-10">
                  <li>Return airfares to Auckland and transfers to/from Go Media Stadium</li>
                  <li>Food and drinks will be provided</li>
                  <li>Watch the Warriors training session from 11am - 1pm</li>
                  <li>A meet and greet</li>
                  <li>A tour of the facilities</li>
                </ul>
              </div>
              <div style="display: flex; justify-content: center; align-items: center; width: 110px;">
                <v-img max-width="auto" :src="$bucket + '/image/dashboard-tiles/WinTheWarrios.LandingLogo.png'"
                  style="display: inline-block; vertical-align: middle; object-fit: contain !important;"></v-img>
              </div>
            </v-row>
          </div>
        </div>
      </v-row>

      <p><b>To be in to win all you need to do is register below - it’s that simple!</b></p>
      <p>Registrations are open from Monday 17th June 2024 to Sunday 23rd June 2024.</p>

      <v-row style="padding: 1rem 0 1rem 0;">
        <v-btn height="50" width="280"
          style="font-size: 16px; background-color: #440099 !important; color: white; text-transform: none !important;"
          @click="showPopup">
          Register Now
        </v-btn>
      </v-row>

      <v-row>
        <a style="font-size: 13px; color: black; font-style: italic;"
          :href="$window.location.origin + '/#/page/WinTheWarrios-TC'" target="_blank">Terms and Conditions apply.</a>
      </v-row>
    </v-container>

    <v-dialog v-model="show" width="900px" persistent>
      <v-card style="border-radius: 1rem;">
        <div style="background-color: #1C1044; color: white; padding: 1rem;">
          <div style="display:flex; justify-content: end">
            <v-icon large color="white" @click="closePopup">mdi-close</v-icon>
          </div>
          <div style="display: flex; flex-direction: column; align-items: center; text-align: center; padding: 2rem
            0;">
            <h2
              style="font-family: 'AirNZ Black Italic'; font-size: 46px; font-weight: normal; line-height: 3rem; padding: 0 0 2rem 0;">
              Thanks, you've
              registered <br /> for the incentive!</h2>
            <div style="padding-top: 1rem;">
              <v-btn style="font-size: 16px; height: 45px; width: 180px; color: black; text-transform: none !important;"
                @click="closePopup">Find out More</v-btn>
            </div>
          </div>
          <div style="display: flex; justify-content: space-between; padding: 1rem 2rem;">
            <div style="width: 70px;">
              <v-img max-width="auto" style="object-fit: contain !important;"
                :src="$bucket + '/image/dashboard-tiles/WinTheWarrios.Logo.png'" />
            </div>
            <div>
              <img width="280" style="object-fit: contain !important;"
                :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {}
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    showPopup() {
      this.show = true
      this.$root.saveActivityLog({ type: 'Visit_dashboard_tile', value: "Register WinTheWarriors" });
    },
    closePopup() {
      this.show = false
    }
  }
};
</script>