<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px"
            :src="$bucket + '/image/dashboard-pages/SeatsToSuit_HeroBanner.jpg'" />
        </v-col>
      </v-row>
      <v-row style="padding: 0px 60px 10px 0px">
        <h2>Be in to WIN with Seats to Suit</h2>
        <v-col cols="12" class="pa-0">
          <p style="margin-right: -27px;">
            Imagine strolling the black sand beaches of Auckland, hiking through the forests of Rotorua, or sampling
            world-renowned wines in the vineyards of Marlborough. </P>
          <P> Well, here's your chance to be in to WIN flights for you and a mate to New Zealand. </P>
          <P>To be in to WIN, <b>watch the video</b> below of two of our BDMs Nick and Michelle and then complete the <a
              @click="$router.push('/training')">Seats to Suit Training Module</a>.
          </p>

          <p><b>So, what's up for grabs... </b></p>
          <ul style="margin-left: -4px;">
            <li>5x prizes of return the<b>works</b> flights for two to New Zealand </li>
          </ul>
          <p>
            By doing this not only will you learn about the Air New Zealand Seats to Suit offering but you will then be
            able to book your customers with confidence to <br /> New Zealand and the Pacific Islands.
          </p>

          <p>Our Seats to Suit offering allows your customers to travel the way they like! From <b>seat</b> fares, which
            includes a carry-on bag, through to works<b>deluxe</b> where customers can experience premium check-in,
            movies, a meal and extra personal service and space, we've got something for everyone. And wherever you are
            flying, you can expect the same great, uniquely kiwi experience onboard.
          </p>
        </v-col>
      </v-row>

      <v-row class="mt-0 d-flex justify-center text-center">
        <v-col cols="12" class="px-0 my-6">
          <div style="width: 1250px; height: 706px;">
            <video @click="onClickVideo" @play="onClickVideo" ref="video" id="video" width="100%" class="iframe-size" controls controlsList="nodownload">
              <source :src="$bucket + '/video/Seats To Suit - Air New Zealand Product Offering.mp4'" type="video/mp4">
            </video>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pa-0">
          <h2>So, what are you waiting for? Test your knowledge and be in to win</h2>
          <v-btn @click="onStartTraining(30673)" class="my-4" min-width="200px" height="50px" color="duoSecondary"
            :elevation="0" dark>Get started</v-btn>
          <p><a @click="$root.openRouteInNewTab('/SeatsToSuit/TermsAndConditions')">Terms and conditions</a> apply</p>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="dialogRetake" max-width="400">
      <v-card class="mx-auto" max-width="400">
        <v-toolbar flat color="#221551" dark>
          <v-toolbar-title>Finish</v-toolbar-title>
        </v-toolbar>

        <v-card-text>
          <h2 class="text-h6 my-4">You have finished this training</h2>
          <h2 class="text-h6 my-4">Your Score: {{ finalScore }}</h2>
        </v-card-text>

        <v-card-actions class="justify-center pb-6">
          <v-btn @click="handleRetakeModule()" class="duo-primary">Retake</v-btn>
          <v-btn @click="handleReviewModule()" class="duo-secondary">Review</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
  <seats-to-suit-mobile v-else></seats-to-suit-mobile>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";
import axios from "axios";
import seatsToSuitMobile from './mobile/seatsToSuitMobile.vue';

export default {
  components: {
    subHeader,
    seatsToSuitMobile
  },
  data() {
    return {
      retakeModule: false,
      dialogRetake: false,
      videoWatched: false,
      moduleId: 0,
      moduleStatus: "",
      finalScore: 0,
      showScore: false,
      completedModuleList: [],
    };
  },
  mounted: function () {
    this.video = document.getElementById("video");

    window.scrollTo(0, 0);
  },
  methods: {
    onClickVideo() {
      if(this.videoWatched == false) {
        this.videoWatched = true
        this.$root.saveActivityLog({type: 'Watch_video', value: "Seats To Suit - Air New Zealand Product Offering"});
      }
    },
    listCompletedModule() {
      axios
        .get("/system/score/completed")
        .then((response) => {
          this.completedModuleList = response.data;
        }).catch(() => { });
    },
    onStartTraining(id) {
      this.moduleId = id;
      this.checkPersonScore();
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push(`/training/module/${this.moduleId}/`);
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push(`/training/module/${this.moduleId}/`);
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.moduleStatus = this.getModuleStatus(response.data.moduleId);
            this.dialogRetake = true;
            this.moduleId && this.getModulePageQuiz(this.moduleId);
          } else {
            this.$router.push(`/training/module/${this.moduleId}/`);
          }
        })
        .catch(() => { });
    },
    getModuleStatus(moduleId) {
      let result = this.completedModuleList.find(score => score.moduleId === moduleId);
      if (result != null) {
        return result.transitMap.scoreStatus;
      } else {
        return null;
      }
    },
    getModulePageQuiz(id) {
      if (id !== undefined) axios.get(`/system/page/module/list?moduleId=${id}`).then((response) => {
        this.showScore = response.data.some((v) => v.name.includes('choice') || v.name.includes('answer'))
      });
    },
  }
};
</script>