<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <subHeader />
    <v-container
      no-gutters
      class="main-container"
      style="width: 100%; padding: 0 0 0 10px"
      fluid
    >
      <!-- LEFT TAB -->
      <div class="d-flex flex-column left-tab">
        <!-- SVG MAP -->
        <div>
          <div
            style="
              position: relative;
              z-index: 1;
              font-size: 17px;
              height: 0;
              max-width: 680px;
            "
          >
            <div
              style="font-family: 'AirNZ Sans Semibold'; padding-bottom: 22px"
            >
              <b>Discover Australia</b>
            </div>
            <p style="line-height: 110%">
              There’s never been a better time to travel to Australia, with flights from Auckland, Wellington, Christchurch and Queenstown, so come and say g’day.
            </p>
          </div>
          <br /> <br /> <br /> <br />
          <div
            :style="{
              width: 800 * scale + 'px',
              height: 504 * scale + 'px',
              zIndex: 0,
            }"
          >
            <div
              :style="{
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                transformOrigin: 'top left',
                transform: 'scale(' + scale + ')',
                height: '465px',
                width: '521px',
                marginLeft: '120px',
                background:
                  'url(' + $bucket + '/image/discover-australia/Australia-Map.svg',
              }"
            >
              <v-container
                class="ma-0 pa-0"
                style="max-width: 680px; padding-top: 10px"
              >
                <v-btn
                  v-for="state in stateList"
                  :key="state.airportId"
                  @click="handleStateOnClick(state)"
                  class="rounded-pill"
                  :elevation="
                    selectedState.airportId === state.airportId ? 1 : 0
                  "
                  :style="{
                    fontSize: '12px',
                    padding: '0 10px 0 10px',
                    backgroundColor:
                      selectedState.airportId === state.airportId
                        ? 'white'
                        : 'transparent',
                    left: state.posX,
                    top: state.posY,
                    height: '25px',
                  }"
                  ><div v-if="state.inverted == true" class="dot" />
                  <b>{{ state.name }}</b>
                  <div v-if="state.inverted == false" class="dot"
                /></v-btn>
              </v-container>
            </div>
          </div>
        </div>
        <!-- END SVG MAP -->
      </div>
      <!-- END LEFT TAB -->

      <v-divider
        vertical
        class="center-tab"
        style="
          border-width: 1px;
          background-color: #303030;
          max-height: 510px;
          margin-top: 80px;
        "
      ></v-divider>

      <!-- RIGHT TAB -->
      <div
        ref="place"
        class="d-none d-md-block right-tab"
        style="min-width: 420px"
      >
        <h3 style="font-size: 18px; padding-bottom: 18px; padding-left: 10px">
          {{ selectedState.name }}
        </h3>
        <div class="parallax-style">
          <div v-if="selectedState.airportId === 'Hobart'" class="pb-5">
            {{ selectedState.subtitle }}
          </div>
          <v-card
            class="mb-4"
            v-for="(item, j) in selectedState.placeList"
            :key="j"
            min-width="420px"
            elevation="1"
            style="border-radius: 0"
          >
            <v-row style="width: 420px" no-gutters>
              <v-img
                :src="$bucket + '/image/discover-australia/' + item.image"
                height="180px"
                position="center center"
              >
                <v-card-title
                  class="fill-height align-end"
                  style="word-break: break-word"
                >
                  <v-container
                    fluid
                    class="py-0 pb-1 white--text"
                    style="padding-right: 0"
                  >
                    <v-row
                      no-gutters
                      style="max-height: 64px; font-size: 28px; letter-spacing: 0"
                    >
                      <strong>{{ item.name }}&nbsp;</strong>
                    </v-row>
                    <v-row no-gutters>
                      <span class="horizontal-line"></span>
                    </v-row>
                  </v-container>
                </v-card-title>
              </v-img>
              <v-card-text class="place-text" style="padding: 17px 24px 8px 24px">
                <p style="font-size: 17px; line-height: 120%">
                  {{ item.shortDescription }}
                </p>
              </v-card-text>
              <v-card-actions
                style="padding: 0 24px 29px 24px"
                class="place-action"
              >
                <v-btn
                  style="width: 193px; height: 50px; font-size: 16px"
                  color="#2d2926"
                  class="get-in-touch"
                  :elevation="0"
                  bottom
                  dark
                  @click="handleDialogOnClick(item)"
                  >Find out more</v-btn
                >
              </v-card-actions>
            </v-row>
          </v-card>
        </div>
      </div>
    </v-container>
    <!-- END RIGHT TAB -->

    <!-- DIALOG -->
    <v-dialog v-model="dialog" max-width="920px">
      <v-card
        v-if="place != null"
        min-height="420px"
        max-height="500px"
        style="overflow: auto"
      >
        <div
          style="color: white; background-color: #2d2926"
          :style="{ maxHeight: '66px' }"
        >
          <div
            class="place-dialog-name"
            style="padding-left: 38px; padding-top: 16px; padding-bottom: 16px"
          >
            <strong style="font-size: 28px">{{ place.name }}</strong>
          </div>
          <v-spacer />
          <div>
            <v-btn
              absolute
              top
              right
              icon
              style="padding-top: 16px; padding-bottom: 16px"
              @click="dialog = false"
            >
              <v-icon large color="white">mdi-close</v-icon>
            </v-btn>
          </div>
        </div>
        <v-row no-gutters>
          <v-col
            :style="{
              maxWidth: '420px',
              height: '400px',
              backgroundImage:
                'url(' +
                $bucket +
                '/image/discover-australia/' +
                place.image +
                ')',
              backgroundSize: 'cover',
              position: 'relative',
              backgroundPosition: 'center center',
            }"
          ></v-col>
          <v-col>
            <v-card-text class="ma-0 pa-7" style="height: 217px">
              <div
                style="
                  font-size: 17px !important;
                  line-height: 120%;
                  padding: 0 2px 0 12px;
                "
                v-html="place.description"
              ></div>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
    <!-- END DIALOG -->
  </v-container>
  <discover-australia-mobile v-else></discover-australia-mobile>
</template>
<style scoped>
.horizontal-line {
  width: 50px;
  padding-bottom: 12px;
  border-bottom: 4px solid rgb(255, 255, 255);
}

.dot {
  width: 11px;
  height: 11px;
  /* UI Properties */
  background: var(--unnamed-color-440099) 0% 0% no-repeat padding-box;
  background: #440099 0% 0% no-repeat padding-box;
  border-radius: 50%;
  opacity: 1;
  margin-left: 3px;
}

@media (max-width: 1599px) {
  .main-container {
    display: inline-flex;
    justify-content: center;
  }

  .center-tab {
    margin-right: 22px;
  }

  .right-tab {
    padding-right: 20px;
  }
}

@media (min-width: 1600px) {
  .main-container {
    display: inline-flex;
  }

  .left-tab {
    margin-left: 2vw;
  }

  .center-tab {
    margin-left: 1vw;
    margin-right: 3vw;
  }

  .right-tab {
    /* padding-right: 2vw; */
  }
}

.parallax-style {
  height: calc(100vh - 200px);
  overflow-y: auto;
}

.parallax-style::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
// import axios from "axios";
import subHeader from "./subHeader.vue";
import discoverAustraliaMobile from "./mobile/discoverAustraliaMobile.vue"

export default {
  components: {
    subHeader,
    discoverAustraliaMobile
  },
  created() {
    window.addEventListener("resize", this.handleResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
    this.handleResize();
    window.scrollTo(0, 0);
    this.selectedState = this.stateList[2];
  },
  data() {
    return {
      scale: 1,
      dialog: false,
      selectedState: {
        airportId: null,
        placeList: [],
      },
      place: null,
      stateList: [
        {
          airportId: "Perth",
          name: "Perth",
          posX: "-10px",
          posY: "288px",
          inverted: false,
          placeList: [
            {
              name: "Perth",
              shortDescription:
                "Perth in Western Australia is popular for world class vineyard, beaches, and shopping. With weather as welcoming as its friendly locals, Australia's sunniest capital city.",
              description:
                "<p>Perth in Western Australia is popular for world class vineyard, beaches, and shopping. With weather as welcoming as its friendly locals, Australia's sunniest capital city.<br/><br/>Flight time from Auckland to Perth: 7h 25m<br/>Flight time from Perth  to Auckland: 6h 25m</p>",
              image: "Perth.1.jpg",
            },
            {
              name: "Funky Fremantle",
              shortDescription:
                "Loved for its laid-back atmosphere, 63 beautiful beaches and 20 idyllic bays, Rottnest Island/Wadjemup is a unique island paradise located just a short ferry ride from the mainland.",
              description:
                "<p>Loved for its laid-back atmosphere, 63 beautiful beaches and 20 idyllic bays, Rottnest Island/Wadjemup is a unique island paradise located just a short ferry ride from the mainland.<br/><br/>Flight time from Auckland to Perth: 7h 25m<br/>Flight time from Perth  to Auckland: 6h 25m</p>",
                image: "Perth.2.jpg",
            },
            {
              name: "Beaches near and far",
              shortDescription:
                "Within an hour of Perth's CBD, you can swim with wild dolphins at Rockingham, cruise the canals in Mandurah, discover cystal caves in Yanchep National Park and hike the trails of Perth Hills.",
              description:
                "<p>Within an hour of Perth's CBD, you can swim with wild dolphins at Rockingham, cruise the canals in Mandurah, discover cystal caves in Yanchep National Park and hike the trails of Perth Hills.<br/><br/>Flight time from Auckland to Perth: 7h 25m<br/>Flight time from Perth  to Auckland: 6h 25m</p>",
                image: "Perth.3.jpg",
            },
            {
              name: "Swan Valley and the Margaret River Region",
              shortDescription:
                "Less than 30 minutes from Perth is the Swan Valley, where local energy goes into growing and creating wonderful food and wine. You can visit wineries, coffee roasters, honey producers, ice cream cafes, olive oil producers, cider makers and breweries.",
              description:
                "<p>Less than 30 minutes from Perth is the Swan Valley, where local energy goes into growing and creating wonderful food and wine. You can visit wineries, coffee roasters, honey producers, ice cream cafes, olive oil producers, cider makers and breweries.<br/><br/>Flight time from Auckland to Perth: 7h 25m<br/>Flight time from Perth  to Auckland: 6h 25m</p>",
                image: "Perth.4.jpg",
            },
          ],
        },
        {
          airportId: "Adelaide",
          name: "Adelaide",
          posX: "190px",
          posY: "328px",
          inverted: false,
          placeList: [
            {
              name: "Adelaide",
              shortDescription:
                "Perfect in every season, Adelaide promises exceptional food, wine, art and shopping, as well as a fizzing bar scene and world-class events.",
              description:
                "<p>Perfect in every season, Adelaide promises exceptional food, wine, art and shopping, as well as a fizzing bar scene and world-class events.<br/><br/>Flight time from Auckland to Adelaide: 4h 55m<br/>Flight time from Adelaide to Auckland: 4h 25m</p>",
              image: "Adelaide.1.jpg",
            },
            {
              name: "Browsing Art Galleries and Museums",
              shortDescription:
                "The best shopping in Adelaide is concentrated around Rundle Mall, the first shopping area in Australia to be pedestrianised.",
              description:
                "<p>The best shopping in Adelaide is concentrated around Rundle Mall, the first shopping area in Australia to be pedestrianised.<br/><br/>Flight time from Auckland to Adelaide: 4h 55m<br/>Flight time from Adelaide to Auckland: 4h 25m</p>",
              image: "Adelaide.2.jpg",
            },
            {
              name: "Hire a car and explore South Australia",
              shortDescription:
                "A day trip to Mount Lofty in the Cleland Conservation Park and Hahndorf, or the Epicurean Way road trip to McLaren Vale, Adelaide Hills, Barossa and Clare Valley.",
              description:
                "<p>A day trip to Mount Lofty in the Cleland Conservation Park and Hahndorf, or the Epicurean Way road trip to McLaren Vale, Adelaide Hills, Barossa and Clare Valley.<br/><br/>Flight time from Auckland to Adelaide: 4h 55m<br/>Flight time from Adelaide to Auckland: 4h 25m</p>",
              image: "Adelaide.3.jpg",
            },
            {
              name: "Cruise the Murray River",
              shortDescription:
                "Adelaide is also the perfect jumping off point for a Murray River experience. Australia's longest river, the Murray is continously navigable for nearly 2000 kilometres and its part of the third-largest water catchment on the planet.",
              description:
                "<p>Adelaide is also the perfect jumping off point for a Murray River experience. Australia's longest river, the Murray is continously navigable for nearly 2000 kilometres and its part of the third-largest water catchment on the planet.<br/><br/>Flight time from Auckland to Adelaide: 4h 55m<br/>Flight time from Adelaide to Auckland: 4h 25m</p>",
              image: "Adelaide.4.jpg",
            },
          ],
        },
        {
          airportId: "Melbourne",
          name: "Melbourne",
          posX: "160px",
          posY: "370px",
          inverted: false,
          placeList: [
            {
              name: "Melbourne",
              shortDescription:
                "Eat, drink and experience your way through Australia's culture capital.",
              description:
                "<p>Eat, drink and experience your way through Australia's culture capital.<br/><br/>Flight time from Auckland to Melbourne: 3h 55m<br/>Flight time from Melbourne to Auckland: 3h 30m<br/>Flight time from Wellington to Melbourne: 4h 05m<br/>Flight time from Melbourne to Wellington: 3h 35m<br/>Flight time from Christchurch to Melbourne: 3h 50m<br/>Flight time from Melbourne to Christchurch: 3h 30m<br/>Flight time from Queenstown to Melbourne: 3h 40m<br/>Flight time from Melbourne to Queenstown: 3h 15m</p>",
              image: "Melbourne.1.jpg",
            },
            {
              name: "Memorable food",
              shortDescription:
                "If you love culinary excellence, expect to have your mind blown in Melbourne. Acclaimed chefs are drawn to the city by the experimental dining scene and abundance of quality fresh ingredients.",
              description:
                "<p>If you love culinary excellence, expect to have your mind blown in Melbourne. Acclaimed chefs are drawn to the city by the experimental dining scene and abundance of quality fresh ingredients.<br/><br/>Flight time from Auckland to Melbourne: 3h 55m<br/>Flight time from Melbourne to Auckland: 3h 30m<br/>Flight time from Wellington to Melbourne: 4h 05m<br/>Flight time from Melbourne to Wellington: 3h 35m<br/>Flight time from Christchurch to Melbourne: 3h 50m<br/>Flight time from Melbourne to Christchurch: 3h 30m<br/>Flight time from Queenstown to Melbourne: 3h 40m<br/>Flight time from Melbourne to Queenstown: 3h 15m</p>",
              image: "Melbourne.2.jpg",
            },
            {
              name: "Unstoppable shopping",
              shortDescription:
                "Pack light for your Melbourne trip, to ensure there's plenty of room for the clothes, knick-knacks and homewares you'll no doubt acquire during your stay.",
              description:
                "<p>Pack light for your Melbourne trip, to ensure there's plenty of room for the clothes, knick-knacks and homewares you'll no doubt acquire during your stay.<br/><br/>Flight time from Auckland to Melbourne: 3h 55m<br/>Flight time from Melbourne to Auckland: 3h 30m<br/>Flight time from Wellington to Melbourne: 4h 05m<br/>Flight time from Melbourne to Wellington: 3h 35m<br/>Flight time from Christchurch to Melbourne: 3h 50m<br/>Flight time from Melbourne to Christchurch: 3h 30m<br/>Flight time from Queenstown to Melbourne: 3h 40m<br/>Flight time from Melbourne to Queenstown: 3h 15m</p>",
              image: "Melbourne.3.jpg",
            },
            {
              name: "Easy day trips and short breaks",
              shortDescription:
                "If you've never ventured further into Victoria, make this your year for going beyond the city. You can pick any point of the compass - there are opportunities for day trips and short breaks in every directions.",
              description:
                "<p>If you've never ventured further into Victoria, make this your year for going beyond the city. You can pick any point of the compass - there are opportunities for day trips and short breaks in every directions.<br/><br/>Flight time from Auckland to Melbourne: 3h 55m<br/>Flight time from Melbourne to Auckland: 3h 30m<br/>Flight time from Wellington to Melbourne: 4h 05m<br/>Flight time from Melbourne to Wellington: 3h 35m<br/>Flight time from Christchurch to Melbourne: 3h 50m<br/>Flight time from Melbourne to Christchurch: 3h 30m<br/>Flight time from Queenstown to Melbourne: 3h 40m<br/>Flight time from Melbourne to Queenstown: 3h 15m</p>",
              image: "Melbourne.4.jpg",
            },
          ],
        },
        {
          airportId: "Sydney",
          name: "Sydney",
          posX: "148px",
          posY: "315px",
          inverted: false,
          placeList: [
            {
              name: "Sydney",
              shortDescription:
                "Whether you're a foodie, love adventure, adore culture or need a little retail therapy, Sydney never fails to wow.",
              description:
                "<p>Whether you're a foodie, love adventure, adore culture or need a little retail therapy, Sydney never fails to wow.<br/><br/>Flight time from Auckland to Sydney: 3h 25m<br/>Flight time from Sydney to Auckland: 3h<br/>Flight time from Wellington to Sydney: 3h 45m<br/>Flight time from Sydney to Wellington: 3h 15m<br/>Flight time from Christchurch to Sydney: 3h 35m<br/>Flight time from Sydney to Christchurch: 3h 10m<br/>Flight time from Queenstown to Sydney: 3h 20m<br/>Flight time from Sydney to Queenstown: 3h 05m</p>",
              image: "Sydney.1.jpg",
            },
            {
              name: "Urban beach life",
              shortDescription:
                "If you want to see a lot of beaches in a short time, do the Bondi to Coogee Walk. As well as beaches, it takes you past a succession of ocean pool.",
              description:
                "<p>If you want to see a lot of beaches in a short time, do the Bondi to Coogee Walk. As well as beaches, it takes you past a succession of ocean pool.<br/><br/>Flight time from Auckland to Sydney: 3h 25m<br/>Flight time from Sydney to Auckland: 3h<br/>Flight time from Wellington to Sydney: 3h 45m<br/>Flight time from Sydney to Wellington: 3h 15m<br/>Flight time from Christchurch to Sydney: 3h 35m<br/>Flight time from Sydney to Christchurch: 3h 10m<br/>Flight time from Queenstown to Sydney: 3h 20m<br/>Flight time from Sydney to Queenstown: 3h 05m</p>",
              image: "Sydney.2.jpg",
            },
            {
              name: "New places to eat and drink",
              shortDescription:
                "Italian and Greek, Thai and Vietnamese, Middle-eastern and South American, French and Portuguese - diversity is alive and well in Sydney's food scene.",
              description:
                "<p>Italian and Greek, Thai and Vietnamese, Middle-eastern and South American, French and Portuguese - diversity is alive and well in Sydney's food scene.<br/><br/>Flight time from Auckland to Sydney: 3h 25m<br/>Flight time from Sydney to Auckland: 3h<br/>Flight time from Wellington to Sydney: 3h 45m<br/>Flight time from Sydney to Wellington: 3h 15m<br/>Flight time from Christchurch to Sydney: 3h 35m<br/>Flight time from Sydney to Christchurch: 3h 10m<br/>Flight time from Queenstown to Sydney: 3h 20m<br/>Flight time from Sydney to Queenstown: 3h 05m</p>",
              image: "Sydney.3.jpg",
            },
            {
              name: "Beyond Sydney",
              shortDescription:
                "You'll never run out of things to do in Sydney, however it's worth setting some time aside to explore other places in New South Wales.",
              description:
                "<p>You'll never run out of things to do in Sydney, however it's worth setting some time aside to explore other places in New South Wales.<br/><br/>Flight time from Auckland to Sydney: 3h 25m<br/>Flight time from Sydney to Auckland: 3h<br/>Flight time from Wellington to Sydney: 3h 45m<br/>Flight time from Sydney to Wellington: 3h 15m<br/>Flight time from Christchurch to Sydney: 3h 35m<br/>Flight time from Sydney to Christchurch: 3h 10m<br/>Flight time from Queenstown to Sydney: 3h 20m<br/>Flight time from Sydney to Queenstown: 3h 05m</p>",
              image: "Sydney.4.jpg",
            },
          ],
        },
        {
          airportId: "Gold Coast",
          name: "Gold Coast",
          posX: "138px",
          posY: "235px",
          inverted: true,
          placeList: [
            {
              name: "Gold Coast",
              shortDescription:
                "Gold Coast offers a holiday experience that both is relaxing and stimulating at the same time.",
              description:
                "<p>Gold Coast offers a holiday experience that both is relaxing and stimulating at the same time.<br/><br/>Flight time from Auckland to Gold Coast: 3h 35m<br/>Flight time from Gold Coast to Auckland: 3h 05m<br/>Flight time from Christchurch to Gold Coast: 3h 45m<br/>Flight time from Gold Coast to Christchurch: 3h 20m</p>",
              image: "GoldCoast.1.jpg",
            },
            {
              name: "Beaches and water fun",
              shortDescription:
                "Unsurprisingly, water is the fuel that powers tourism for Australia's sixth largest city. If you're surfer, you'll want to check out famous surf spots like Burleigh Heads and Snapper Rocks, where the Quicksilver Pro Gold Coast is held each year.",
              description:
                "<p>Unsurprisingly, water is the fuel that powers tourism for Australia's sixth largest city. If you're surfer, you'll want to check out famous surf spots like Burleigh Heads and Snapper Rocks, where the Quicksilver Pro Gold Coast is held each year.<br/><br/>Flight time from Auckland to Gold Coast: 3h 35m<br/>Flight time from Gold Coast to Auckland: 3h 05m<br/>Flight time from Christchurch to Gold Coast: 3h 45m<br/>Flight time from Gold Coast to Christchurch: 3h 20m</p>",
              image: "GoldCoast.2.jpg",
            },
            {
              name: "Theme parks and adventures",
              shortDescription:
                "If your idea of a perfect day involves free-falling 38 stories on the appropriately named Giant Drop, you've come to the right place.",
              description:
                "<p>If your idea of a perfect day involves free-falling 38 stories on the appropriately named Giant Drop, you've come to the right place.<br/><br/>Flight time from Auckland to Gold Coast: 3h 35m<br/>Flight time from Gold Coast to Auckland: 3h 05m<br/>Flight time from Christchurch to Gold Coast: 3h 45m<br/>Flight time from Gold Coast to Christchurch: 3h 20m</p> ",
              image: "GoldCoast.3.jpg",
            },
            {
              name: "Eating and shopping",
              shortDescription:
                "From sensational rooftop bars and crafts breweries to Pacific Fair and Harbour Town shopping, the Gold Coast has an urban style that's all its own.",
              description:
                "<p>From sensational rooftop bars and crafts breweries to Pacific Fair and Harbour Town shopping, the Gold Coast has an urban style that's all its own.<br/><br/>Flight time from Auckland to Gold Coast: 3h 35m<br/>Flight time from Gold Coast to Auckland: 3h 05m<br/>Flight time from Christchurch to Gold Coast: 3h 45m<br/>Flight time from Gold Coast to Christchurch: 3h 20m</p>",
              image: "GoldCoast.4.jpg",
            },
          ],
        },
        {
          airportId: "Brisbane",
          name: "Brisbane",
          posX: "440px",
          posY: "188px",
          inverted: false,
          placeList: [
            {
              name: "Brisbane",
              shortDescription:
                "Brisbane, the capital of Queensland, is known for its thriving art and entertainment scenes.",
              description:
                '<p>Brisbane, the capital of Queensland, is known for its thriving art and entertainment scenes.<br/><br/>Flight time from Auckland to Brisbane: 3h 35m<br/>Flight time from Brisbane to Auckland: 3h 05m<br/>Flight time from Wellington to Brisbane: 4h 15m<br/>Flight time from Brisbane to Wellington: 3h 25m<br/>Flight time from Christchurch to Brisbane: 4h 05m<br/>Flight time from Brisbane to Christchurch: 3h 30m</p>',
              image: "Brisbane.1.jpg",
            },
            {
              name: "Delicious food with a view",
              shortDescription:
                "Whether you're an enthusiastic foodie or simply enjoy good cooking, Brisbane has a lot to offer. The local food scene has really taken off in recent years.",
              description:
                "<p>Whether you're an enthusiastic foodie or simply enjoy good cooking, Brisbane has a lot to offer. The local food scene has really taken off in recent years.<br/><br/>Flight time from Auckland to Brisbane: 3h 35m<br/>Flight time from Brisbane to Auckland: 3h 05m<br/>Flight time from Wellington to Brisbane: 4h 15m<br/>Flight time from Brisbane to Wellington: 3h 25m<br/>Flight time from Christchurch to Brisbane: 4h 05m<br/>Flight time from Brisbane to Christchurch: 3h 30m</p>",
              image: "Brisbane.2.jpg",
            },
            {
              name: "Cultural precinct",
              shortDescription:
                "For world-class exhibits on natural history, heritage and science, walk a short distance south to the Queensland Museum and Sciencentre.",
              description:
                "<p>For world-class exhibits on natural history, heritage and science, walk a short distance south to the Queensland Museum and Sciencentre.<br/><br/>Flight time from Auckland to Brisbane: 3h 35m<br/>Flight time from Brisbane to Auckland: 3h 05m<br/>Flight time from Wellington to Brisbane: 4h 15m<br/>Flight time from Brisbane to Wellington: 3h 25m<br/>Flight time from Christchurch to Brisbane: 4h 05m<br/>Flight time from Brisbane to Christchurch: 3h 30m</p>",
              image: "Brisbane.3.jpg",
            },
            {
              name: "Get out and about",
              shortDescription:
                "Take a Brisbane sightseeing cruise on the river, explore the easy riverside paths and Bicentennial Bikeway, or sign up to climb Story Bridge for the best views in town. You can even join a kayak or paddle board tour on the river past Kangaroo.",
              description:
                "<p>Take a Brisbane sightseeing cruise on the river, explore the easy riverside paths and Bicentennial Bikeway, or sign up to climb Story Bridge for the best views in town. You can even join a kayak or paddle board tour on the river past Kangaroo.<br/><br/>Flight time from Auckland to Brisbane: 3h 35m<br/>Flight time from Brisbane to Auckland: 3h 05m<br/>Flight time from Wellington to Brisbane: 4h 15m<br/>Flight time from Brisbane to Wellington: 3h 25m<br/>Flight time from Christchurch to Brisbane: 4h 05m<br/>Flight time from Brisbane to Christchurch: 3h 30m</p>",
              image: "Brisbane.4.jpg",
            },
          ],
        },
        {
          airportId: "Sunshine Coast",
          name: "Sunshine Coast",
          posX: "391px",
          posY: "166px",
          inverted: true,
          placeList: [
            {
              name: "Sunshine Coast",
              shortDescription:
                "It's the kind of place that dreams are made of. A sun-dappled coastline that's sprinkled with resorts and gorgeous vistas.",
              description:
                "<p>It's the kind of place that dreams are made of. A sun-dappled coastline that's sprinkled with resorts and gorgeous vistas.<br/><br/>Flight time from Auckland to Sunshine Coast: 4h<br/>Flight time from Sunshine Coast to Auckland: 3h 10m<br/>Operates seasonally between June-October</p>",
              image: "SunshineCoast.1.jpg",
            },
            {
              name: "Beach culture",
              shortDescription:
                "The Sunshine Coast's beach villages are a popular place to stay with a range of accommodation options available from Caloundra to Mooloolaba and Alexandra Headlands, through to Coolum, Noosa and Rainbow Beach to the north.",
              description:
                "<p>The Sunshine Coast's beach villages are a popular place to stay with a range of accommodation options available from Caloundra to Mooloolaba and Alexandra Headlands, through to Coolum, Noosa and Rainbow Beach to the north.<br/><br/>Flight time from Auckland to Sunshine Coast: 4h<br/>Flight time from Sunshine Coast to Auckland: 3h 10m<br/>Operates seasonally between June-October</p>",
              image: "SunshineCoast.2.jpg",
            },
            {
              name: "Hinterland & Australia Zoo",
              shortDescription:
                "The extraordinary heritage-listed Glass House Mountains and the quaint hinterland hamlets of Maleny, Montville, Mapleton and Eumundi.",
              description:
                "<p>The extraordinary heritage-listed Glass House Mountains and the quaint hinterland hamlets of Maleny, Montville, Mapleton and Eumundi.<br/><br/>Flight time from Auckland to Sunshine Coast: 4h<br/>Flight time from Sunshine Coast to Auckland: 3h 10m<br/>Operates seasonally between June-October</p>",
              image: "SunshineCoast.3.jpg",
            },
            {
              name: "Food & Shopping",
              shortDescription:
                "Beach culture on the Sunshine Coast is meshed with top-notch food experiences. This means picking up a perfectly-formed espresso while you check out the waves first thing in the morning.",
              description:
                "<p>Beach culture on the Sunshine Coast is meshed with top-notch food experiences. This means picking up a perfectly-formed espresso while you check out the waves first thing in the morning.<br/><br/>Flight time from Auckland to Sunshine Coast: 4h<br/>Flight time from Sunshine Coast to Auckland: 3h 10m<br/>Operates seasonally between June-October</p>",
              image: "SunshineCoast.4.jpg",
            },
          ],
        },
        {
          airportId: "Cairns",
          name: "Cairns",
          posX: "140px",
          posY: "56px",
          inverted: false,
          placeList: [
            {
              name: "Cairns",
              shortDescription:
                "Cairns is your gateway to the Great Barrier Reef, the world's oldest wet tropics rainforest, and much more.",
              description:
                "<p>Cairns is your gateway to the Great Barrier Reef, the world's oldest wet tropics rainforest, and much more.<br/><br/>Flight time from Auckland to Cairns: 3h 55m<br/>Flight time from Cairns to Auckland: 6h 25m<br/>Operates seasonally between April-October</p>",
              image: "Cairns.1.jpg",
            },
            {
              name: "The Great Barrier Reef",
              shortDescription:
                "Australia at its most exotic - that's what you'll discover in Cairns and the Great Barrier Reef. This region has the stuff Aussie legends are made of, from the immense Great Barrier Reef.",
              description:
                "<p>Australia at its most exotic - that's what you'll discover in Cairns and the Great Barrier Reef. This region has the stuff Aussie legends are made of, from the immense Great Barrier Reef.<br/><br/>Flight time from Auckland to Cairns: 3h 55m<br/>Flight time from Cairns to Auckland: 6h 25m<br/>Operates seasonally between April-October</p>",
              image: "Cairns.2.jpg",
            },
            {
              name: "Easy day trips from Cairns",
              shortDescription:
                "Just 45 minutes offshore, Green Island and Fitzroy Island are part of the Great Barrier Reef. From Cairns you can visit the islands by fast catamaran to discover idyllic beaches and beautiful areas of coral.",
              description:
                "<p>Just 45 minutes offshore, Green Island and Fitzroy Island are part of the Great Barrier Reef. From Cairns you can visit the islands by fast catamaran to discover idyllic beaches and beautiful areas of coral.<br/><br/>Flight time from Auckland to Cairns: 3h 55m<br/>Flight time from Cairns to Auckland: 6h 25m<br/>Operates seasonally between April-October</p>",
              image: "Cairns.3.jpg",
            },
            {
              name: "Port Douglas & Daintree",
              shortDescription:
                "Cairns and The Great Barrier Reef targets all your senses, particularly your sense of wonder. If you venture as far north as Cape Tribulation you can discover an orchard that's devoted to rare tropical fruit trees.",
              description:
                "<p>Cairns and The Great Barrier Reef targets all your senses, particularly your sense of wonder. If you venture as far north as Cape Tribulation you can discover an orchard that's devoted to rare tropical fruit trees.<br/><br/>Flight time from Auckland to Cairns: 3h 55m<br/>Flight time from Cairns to Auckland: 6h 25m<br/>Operates seasonally between April-October</p>",
              image: "Cairns.4.jpg",
            },
          ],
        },
        {
          airportId: "Hobart",
          name: "Hobart",
          subtitle: "Auckland to Hobart is a seasonal service that Air New Zealand operates three direct flights a week to over the summer months from October – March. This service has been temporarily paused but we’re excited to bring it back in October 2024!",
          posX: "60px",
          posY: "396px",
          inverted: false,
          placeList: [
            {
              name: "Tasmania",
              shortDescription:
                "Explore Hobart to discover an island that's distinctively different.",
              description:
                "<p>Explore Hobart to discover an island that's distinctively different.<br/><br/>Flight time from Auckland to Hobart: 3h 50m<br/>Flight time from Gold Coast to Auckland: 3h 30m</p>",
              image: "Tasmania.1.jpg",
            },
            {
              name: "Hobart",
              shortDescription:
                "Start your Tasmanian adventure in Hobart, where it's easy to get around on foot, ferry, the double decker bus or with Uber. This compact hilly city is oozing with heritage architecture, historic places and scenic lookouts.",
              description:
                "<p>Start your Tasmanian adventure in Hobart, where it's easy to get around on foot, ferry, the double decker bus or with Uber. This compact hilly city is oozing with heritage architecture, historic places and scenic lookouts.<br/><br/>Flight time from Auckland to Hobart: 3h 50m<br/>Flight time from Gold Coast to Auckland: 3h 30m</p>",
              image: "Tasmania.2.jpg",
            },
            {
              name: "Museum of Old and New Art",
              shortDescription:
                "If you love to chase art wherever you go, a compelling reason to visit Hobart is Mona (Museum of Old and New Art). The best way to get there is by ferry from the CBD waterfront.",
              description:
                "<p>If you love to chase art wherever you go, a compelling reason to visit Hobart is Mona (Museum of Old and New Art). The best way to get there is by ferry from the CBD waterfront.<br/><br/>Flight time from Auckland to Hobart: 3h 50m<br/>Flight time from Gold Coast to Auckland: 3h 30m</p>",
              image: "Tasmania.3.jpg",
            },
            {
              name: "Summit of Kunanyi",
              shortDescription:
                "Many Kiwis are drawn to Tasmania for its natural attractions. The island has 19 national parks and 438 conservation areas, as well as numerous reserves.",
              description:
                "<p>Many Kiwis are drawn to Tasmania for its natural attractions. The island has 19 national parks and 438 conservation areas, as well as numerous reserves.<br/><br/>Flight time from Auckland to Hobart: 3h 50m<br/>Flight time from Gold Coast to Auckland: 3h 30m</p>",
              image: "Tasmania.4.jpg",  
            },
          ],
        },
      ],
    };
  },
  methods: {
    handleStateOnClick(state) {
      this.selectedState = state;
    },
    handleDialogOnClick(place) {
      this.place = place;
      this.dialog = true;
    },
    scrollToTop() {
      this.$refs.place.scrollTop = 0;
    },
    handleResize() {
      this.scale = (window.innerWidth * 0.58565) / 800;
    },
  },
};
</script>
