<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <h2 class="d-flex justify-center pb-8">
        <center>Air New Zealand House of Travel and Singapore Tourism Board Incentive Terms and Conditions<br/>
        Be in to win for a spot on a Singapore Famil!</center>
      </h2>
      <v-row>
        <v-col cols="12" class="pa-0">
          <ol>
            <li>
              Information on how to enter the promotion forms part of the conditions of entry. Entry into the promotion is deemed as acceptance of its terms and conditions. 
            </li>
            <li>
              Entry is open to all House of Travel leisure-based Retail  Agents who are New Zealand-based and IATA-accredited or part of the Travel Industry Designated Service only. The Promoters reserve the right to verify the validity of entries.
            </li>
            <li>
              Air New Zealand (the “Promoter”) employees and their immediate families, participating agents and their associated agencies are ineligible to enter. The Promoter reserves the right to verify the validity of entries. 
            </li>
            <li>
              <b>Conditions of Entry:</b><br>
              How to Enter: <br>
              <ol type="a">
                <li>Participants must visit <a href="https://duo.airnzagent.co.nz/#/login" target="_blank">duo.airnzagent.co.nz</a> and log eligible bookings to Singapore and beyond via duosales.</li>
                <li>Eligible sales must be on Air NZ 086 ticket stock from New Zealand to Singapore or beyond. Bookings must include at least one ticketed segment to Singapore. </li>
                <li>Travel may be for any time.</li>
                <li>Tickets must be issued between 2<sup>nd</sup> September and 25<sup>th</sup> September 2024 </li>
                <li>All agents must have completed the Singapore training <a href="https://duo.airnzagent.co.nz/#/training/module/164149/" target="_blank">module</a>  in Duo.</li>
              </ol>
              Agents are not permitted to ‘pool’ sales and/or log sales under their name that were issued by another agent. 
            </li>
            <li>
              Participation in the Promotion is unlimited into the prize draw per person provided all conditions of entry are met. 
            </li>
            <li>
              Promotion commences 6.00am 2<sup>nd</sup> September 2024 and closes 11.59pm 22 September 2024 (the “<b>Promotional Period</b>”). Entries can be logged in Duosales up until 25 September 2024, providing booking was made during the promotional period, however the training <a href="https://duo.airnzagent.co.nz/#/training/module/164149/" target="_blank">module</a> must have been completed by 11.59pm 22 September 2024.
            </li>
            <li>
              The prize details for the promotion include: <br>
              <b>Grand Prize Draw</b>
              <ul>
                <li>Nine (9) valid entries drawn from the Grand Prize Draw will win: </li>
                <ul>
                  <li>Return Premium Economy from Auckland to Singapore, flying Air New Zealand.</li>
                  <li>Lounge access in Auckland subject to space availability</li>
                  <li>Accommodation in Singapore</li>
                  <li>A variety of ground activities and meals</li>
                  <li>Travel Insurance</li>
                </ul>
              </ul>
              The winners will be drawn from one prize pool on Friday 27<sup>th</sup> September 2024. House of Travel will announce the winners in their weekly communication. <br/>
              The six (6) top sellers with the highest number of points from logged eligible sales during the promotion will each win a Grand Prize. A further three (3) agents drawn at random will also win a Grand Prize. <br />
              Points will be determined by: 
              <ul>
                <li>Every AKL-SIN-beyond sale = 1 point </li>
                <li>Every AKL-SIN point to point sale = 2 points </li>
                <li>1x bonus point for AKL-SIN or beyond on 086 in Premium Economy or Business Premier</li>
              </ul>
              Attempts will be made to notify winners by phone at the contact telephone number provided on the promotion entry. If the Promoter, having used reasonable efforts, is unable to contact the winners between 27<sup>th</sup> and 30<sup>th</sup> September, then the prize is forfeited and a further draw(s) from that prize pool will be conducted. The Promoter's decision is final, and no correspondence will be entered into.
            </li>
            <li>
              The winners are responsible for paying all additional costs associated with the prize that are not specifically included (as stated in clause 7), including (where applicable) transport, transfers, meal costs, spending money and all other incidentals. The winners of the Grand Prize are also responsible for all necessary travel documents, including valid travel identification, visas, travel insurance and a passport validity.
            </li>
            <li>
              The prizes cannot be converted into cash and are not transferable.
            </li>
            <li>
              The Promoter accepts no responsibility for any variation in prize value. 
            </li>
            <li>
              The Promoter reserves the right to substitute the prize in whole (or any of its components), with a substitute prize of equal or greater value.
            </li>
            <li>
              All prize travel will be subject to Air New Zealand’s Conditions of Carriage, to view visit <a href="https://airnewzealand.co.nz/conditions-of-carriage" target="_blank">www.airnewzealand.co.nz/conditions-of-carriage</a>. Prize travel will not be eligible to accrue Airpoints Dollars™ or Status Points and cannot be used in conjunction with Air New Zealand’s Airpoints™ Programme or any other carriers’ frequent flyer schemes.
            </li>
            <li>
              The Promoter assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorised access to or alteration of entries. The Promoter assumes no responsibility for any injury or damage to participants or any other person’s computer related to or resulting from participation in or downloading any materials in this promotion. Entries into this promotion will be stored only in the Air New Zealand booking database.
            </li>
            <li>
              Neither the Promoter nor any other person or party associated with this promotion, shall be liable for any loss or damage whatsoever suffered (including but not limited to indirect or consequential loss) or personal injury suffered or sustained in connection with either participation in this promotion or with any prizes offered.
            </li>
            <li>
              The Promoter collects entrants’ personal information in order to conduct the promotion. If the information requested is not provided, the entrant may not participate in the promotion. Acceptance of the prize is deemed consent for the Promoter to use the winner’s details and photographs for promotional and media purposes without any further reference, payment or other compensation to the entrant. Please refer to Air New Zealand’s Privacy Statement at <a href="https://www.airnewzealand.co.nz/privacy" target="_blank">https://www.airnewzealand.co.nz/privacy</a> regarding the collection, use, disclosure and storage of personal information. 
            </li>
            <li>
              The Promoters of this competition are Air New Zealand Limited, Private Bag 92007, Auckland, New Zealand, House of Travel Limited, Level 2, 45 Queen Street, Auckland 1010,  and Singapore Tourism Board, Tourism Court, 1 Orchard Spring Lane, Singapore 247729 
            </li>
            <li>
              If any queries, please contact the Promoter at <b>duosupportnz@airnz.co.nz</b>
                <!-- <p><a style="display: block;" href="mailto:duosupportnz@airnz.co.nz" target="_blank">duosupportnz@airnz.co.nz</a></p> -->
            </li>
          </ol>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
@counter-style list-style-custom {
  system: extends lower-alpha;
  suffix: '). ';
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>
