import { render, staticRenderFns } from "./trainingModulePageDashboardSingle9.vue?vue&type=template&id=fefdc612&scoped=true&"
import script from "./trainingModulePageDashboardSingle9.vue?vue&type=script&lang=js&"
export * from "./trainingModulePageDashboardSingle9.vue?vue&type=script&lang=js&"
import style0 from "./trainingModulePageDashboardSingle9.vue?vue&type=style&index=0&id=fefdc612&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fefdc612",
  null
  
)

export default component.exports