<template>
  <v-container fluid>
    <subHeader />
    {{ enableButton() }}
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            max-height="345"
            style="background-size: cover; background-position: center center"
            :src="$bucket + '/image/dashboard-tiles/Fiji.Landing.jpeg'"
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            It’s time to turn question time into island time with Fiji Genius!
          </h2>
          <p>
            Join us on duo between 17-26 July as we release a quiz each day to
            test your knowledge about amazing holiday experiences you can find
            when you visit Fiji. Every correct answer enters you into the draw
            to win a trip for two to Fiji.
          </p>
          <p>
            <b>Plus, you could be in to win 1 of 10 $100.00 Prezzy Cards!</b>
          </p>

          <h2>
            So, do you think you’re got what it takes to be a Fiji Genius? Play
            the quiz now.
          </h2>

          <p style="font-size: 13px">
            Completition closes midnight 26 July 2024. By participating in the
            quiz, you agree to the
            <a
              style="font-size: 13px; color: black; font-style: italic"
              :href="$window.location.origin + '/#/page/Fiji-TC'"
              target="_blank"
              >terms and conditions.</a
            >
          </p>
          <p style="font-style: italic">
            Credits: Reel footage courtesy of @anthony_walsh_, Brook Sabin, Jay
            Clue and PADI; Manta ray image courtesy of Jay Clue; Surfing and
            Sawa-i-Lau images courtesy of Tourism Fiji; Diving image courtesy of
            Frogfish Photography (UK); Overwater bure image courtesy of Likuliku
            Lagoon Resort; Sand cay picnic image courtesy of Royal Davui Island
            Resort; Ziplining and Malamala Beach Club images courtesy of Brook
            Sabin; Sigatoka River Safari image courtesy of Sigatoka River Safari
          </p>

          <div style="padding: 2rem; background-color: #eaeaea">
            <v-row>
              <div class="col-10">
                <p style="font-size: 24px; font-weight: bold; margin-top: 0">
                  The package includes:
                </p>
                <ul class="pl-10">
                  <li>
                    Return ‘The Works’ flights for two (2) adults (travelling
                    together) from any Air New Zealand serviced domestic airport
                    to Nadi, Fiji, flying Air New Zealand.
                  </li>
                  <li>
                    Four nights’ accommodation at the Shangri-La Hotel Yanuca
                    Island in a twin share or double room.
                  </li>
                  <li>
                    Vouchers for two (2) adults for two experiences whilst in
                    Fiji – one voucher for each of the below operators:
                  </li>
                  <ul>
                    <li>Sigatoka River Safari</li>
                    <li>Malamala Beach Club</li>
                  </ul>
                </ul>
              </div>
            </v-row>
          </div>
        </div>
      </v-row>
      <v-row>
        <div
          class="col-12"
          style="padding: 2rem; background-color: rgba(234, 234, 234, 0.5)"
        >
          <v-row>
            <v-col
              v-for="(tile, index) in tiles"
              :key="tile.title"
              cols="4"
              md="4"
              sm="12"
            >
              <v-card tile elevation="0">
                <v-img
                  :src="
                    $bucket + '/image/fiji/Fiji_Day_' + (index + 1) + '.jpg'
                  "
                >
                  <v-img
                    style="background: rgba(0, 0, 0, 0.5)"
                    height="100%"
                    width="100%"
                    class="d-flex flex-wrap align-end pa-2"
                  >
                    <v-col cols="12" class="py-0 pl-4">
                      <v-toolbar-title
                        class="pt-3"
                        style="font-size: 28px; color: white"
                      >
                        <div
                          class="d-flex flex-column pb-0"
                          style="
                            font-family: AirNZ Black Italic;
                            font-size: 3rem;
                          "
                        >
                          <span
                            ><b>Day {{ index + 1 }}</b></span
                          >
                        </div>
                      </v-toolbar-title>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-btn
                        @click="visit(tile.link, index + 1)"
                        width="193px"
                        height="50px"
                        color="white"
                        :elevation="0"
                        class="my-4 mx-auto"
                        v-bind:style="
                          tile.button !== true
                            ? {
                                'font-size': '1.5rem',
                                'background-color': 'grey !important',
                              }
                            : { 'font-size': '1.5rem' }
                        "
                        :disabled="!tile.button"
                        >Play Now</v-btn
                      >
                    </v-col>
                  </v-img>
                </v-img>
              </v-card>
            </v-col>
            <v-col cols="12" md="12" sm="12" max-height="300px">
              <v-card tile elevation="0" max-height="300px">
                <v-img
                  :src="$bucket + '/image/fiji/Fiji_Day_10.jpg'"
                  max-height="300px"
                >
                  <v-img
                    style="background: rgba(0, 0, 0, 0.5)"
                    height="100%"
                    width="100%"
                    class="d-flex align-end pa-2"
                  >
                    <v-col cols="12" class="py-0 pl-4 align-end">
                      <v-toolbar-title
                        class="pt-3"
                        style="font-size: 28px; color: white"
                      >
                        <div
                          class="d-flex flex-column pb-0"
                          style="
                            font-family: AirNZ Black Italic;
                            font-size: 3rem;
                          "
                        >
                          <span><b>Day 10</b></span>
                        </div>
                      </v-toolbar-title>
                    </v-col>
                    <v-col cols="12" class="pt-0">
                      <v-btn
                        @click="
                          visit(
                            'https://xd.wayin.com/display/container/dc/c3cfe8db-3aaf-4e89-ac09-405c60dcd5c4',
                            10
                          )
                        "
                        width="193px"
                        height="50px"
                        color="white"
                        :elevation="0"
                        class="my-4 mx-auto"
                        v-bind:style="
                          tile10Button !== true
                            ? {
                                'font-size': '1.5rem',
                                'background-color': 'grey !important',
                              }
                            : { 'font-size': '1.5rem' }
                        "
                        :disabled="!tile10Button"
                        >Play Now</v-btn
                      >
                    </v-col>
                  </v-img>
                </v-img>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tiles: [
        {
          link: "https://xd.wayin.com/display/container/dc/933adc1b-797e-46cf-8708-d056aa4be62a",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/76403d50-dcd9-4685-a363-4a3d2f0699cc",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/ab2a133b-e3e7-4a84-acf2-2b2f5d00a9a8",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/7a7129e8-1287-48a3-8884-1b3dea847608",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/586b051c-12c2-4aa8-99b2-819bdb1d1480",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/08b45743-5a8b-411d-9892-ca6803d65964",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/07ad4413-5921-4abc-a907-3b944b09baec",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/babfe4d4-b74e-491e-a612-2d966af9ea8c",
          button: false,
        },
        {
          link: "https://xd.wayin.com/display/container/dc/770ba8d0-c72f-483a-bb4b-82e80854bc2b",
          button: false,
        },
      ],
      tile10Button: false,
    };
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    visit(url, day) {
      window.open(url, "_blank");
      this.$root.saveActivityLog({
        type: "Visit_dashboard_tile",
        value: "Fiji Day " + day,
      });
    },
    enableButton() {
      let tzOffset = new Date().getTimezoneOffset() / -60;
      let startDate = new Date(Date.UTC(2024, 6, 16, 23, 59, 59));
      let dateNow = new Date().getDate();

      // eslint-disable-next-line no-console
      console.log(new Date(startDate.setHours(startDate.getHours() - tzOffset)), new Date())
      
      startDate = new Date(startDate.setHours(startDate.getHours() - tzOffset)).getDate()
      
      // eslint-disable-next-line no-console
      console.log(startDate, dateNow)

      if (dateNow - startDate <= 10) {
        for (let i = 1; i <= dateNow - startDate; i++) {
          i == 10 ? this.tile10Button = true : this.tiles[i - 1].button = true;
        }
      } else {
        for (let i = 1; i <= 10; i++) {
          i == 10 ? this.tile10Button = true : this.tiles[i - 1].button = true;
        }
      }
    },
  },
};
</script>
