<template>
	<v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
		<subHeader />
		<v-row class="mx-0">
			<v-col cols="12" class="mb-8" style="padding: 0 100px">
				<v-row no-gutters class="mb-4" style="font-size: 28px !important; margin-top: 14.29px"> <b class="pr-2">Edit</b> personal details </v-row>
				<v-card elevation="0" rounded="lg" style="padding: 25px 0">
					<v-row no-gutters style="padding: 0 40px">
						<p style="font-family: 'AirNZ Sans Semibold'; font-size: 18px">Personal details</p>
					</v-row>

					<v-form ref="form" style="padding: 0 40px" v-model="valid" autocomplete="off">
						<v-row no-gutters>
							<!-- airpoints -->
							<v-col cols="5" class="px-2">
								<v-row no-gutters style="font-size: 17px"> Airpoints&trade; Number </v-row>
								<v-row no-gutters class="pl-2">
									<v-text-field
										id="airpoints"
										v-model="entity.airpointsNumber"
										type="number" 
										maxlength="11"
										oninput="javascript: if (this.value.length > 11) this.value = this.value.slice(0, 11);"
										class="duo-description rounded-0"
										:class="{ 'line-blue': entity.airpointsNumber !== undefined && entity.airpointsNumber.length > 0 }"
										flat
										dense
										full-width
									></v-text-field>
								</v-row>
							</v-col>
							<!-- airpoints end -->
						</v-row>
						<v-row no-gutters justify="space-between">
							<!-- first name -->
							<v-col cols="5" class="px-2">
								<v-row no-gutters class="pb-1" style="font-size: 17px"> First name </v-row>
								<v-row no-gutters>
									<v-text-field
										id="firstName"
										v-model="entity.firstName"
										class="duo-description rounded-0"
										:class="{ 'line-blue': entity.firstName !== undefined && entity.firstName.length > 0 }"
										:rules="[ruleName]"
										flat
										solo
										dense
										full-width
									></v-text-field>
								</v-row>
							</v-col>
							<!-- first name end -->
							<!-- last name -->
							<v-col cols="5" class="px-2">
								<v-row no-gutters class="pb-1" style="font-size: 17px"> Last name </v-row>
								<v-row no-gutters>
									<v-text-field
										id="lastName"
										v-model="entity.lastName"
										class="duo-description rounded-0"
										:class="{ 'line-blue': entity.lastName !== undefined && entity.lastName.length > 0 }"
										:rules="[ruleName]"
										flat
										solo
										dense
										full-width
									></v-text-field>
								</v-row>
							</v-col>
							<!-- last name end -->
							<v-spacer></v-spacer>
						</v-row>

						<v-row no-gutters>
							<!-- email -->
							<v-col cols="5" class="px-2">
								<v-row no-gutters class="pb-1" style="font-size: 17px"> Email </v-row>
								<v-row no-gutters>
									<v-text-field
										id="email"
										v-model="entity.email"
										class="duo-description rounded-0"
										:class="{ 'line-blue': entity.email !== undefined && entity.email.length > 0 }"
										:rules="[ruleEmail]"
										flat
										solo
										dense
										full-width
									></v-text-field>
								</v-row>
							</v-col>
							<!-- email end -->
							<!-- industry start date -->
							<v-col cols="5" class="px-2">
								<div style="font-size: 17px; color: #2d2926;">Industry start date</div>
								<v-menu ref="datePickerIndustry" v-model="datePickerIndustry" :close-on-content-click="false" :return-value.sync="entity.map.industryStartDate" transition="scale-transition" offset-y max-width="320px">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field id="industryStartDate" class="mt-2" style="font-size: 17px; color: #2d2926;" v-model="entity.map.industryStartDate" readonly v-on="on" v-bind="attrs" color="#2D2926" dense>
											<template v-slot:append>
												<img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
											</template>
										</v-text-field>
									</template>
									<v-date-picker v-model="entity.map.industryStartDate" type="month" :allowed-dates="allowedMonths" no-title scrollable style="width: 100%">
										<v-row no-gutters justify="space-around" class="pa-4">
											<v-btn width="120" id="saveDateBtn" class="mx-0" outlined color="primary" @click="$refs.datePickerIndustry.save(entity.map.industryStartDate)">Confirm</v-btn>
											<v-btn width="120" id="cancelDateBtn" class="mx-0" outlined @click="datePickerIndustry = false">Cancel</v-btn>
										</v-row>
									</v-date-picker>
								</v-menu>
							</v-col>
							<!-- industry start date end -->
						</v-row>

						<v-divider></v-divider>

						<v-col cols="12" class="px-2">
							<!-- postal address -->
							<v-row no-gutters>
								<v-col cols="12">
									<v-row no-gutters>
										<p class="duo-description"><b>Postal address</b></p>
									</v-row>
									<v-row no-gutters>
										<p class="duo-description">
											Please provide a postal address that can accept courier deliveries should this be required for any <b>duo</b> rewards. If your address has changed, please update the details below.
										</p>
									</v-row>
								</v-col>
								<v-col cols="12" style="margin-bottom:20px;">
									<v-checkbox @change="onAddressCheckboxChange($event)" v-model="addressChangeFlag" label="My address has changed."></v-checkbox>
								</v-col>
							</v-row>

							<!-- manual -->
							<v-row no-gutters>
								<v-row no-gutters justify="space-between">
									<!-- Apartment, Suite, Unit, Level etc -->
									<v-col cols="12" md="3" style="min-width: 255px">
										<v-row no-gutters class="duo-description pb-1" style="color: #2d2926 !important"> Apartment, suite, unit, level etc. </v-row>
										<v-row no-gutters>
											<v-text-field :disabled="!addressChangeFlag" v-model="entity.addressUnit" label="Please enter Apartment, Suite, Unit, Level etc" class="duo-description rounded-0" flat solo dense full-width></v-text-field>
										</v-row>
									</v-col>
									<!-- Apartment, Suite, Unit, Level etc end -->

									<!-- Street, Road etc. -->
									<v-col cols="12" md="3" style="min-width: 255px">
										<v-row no-gutters class="duo-description pb-1" style="color: #2d2926 !important"> Street, road etc.* </v-row>
										<v-row no-gutters>
											<v-menu v-if="!manualEditPostalAddress" ref="streetPickerPostalAddress" v-model="streetPickerPostalAddress" :close-on-content-click="false" offset-overflow>
												<template v-slot:activator="{ on, attrs }">
													<v-text-field
														:disabled="!addressChangeFlag"
														v-model="entity.addressStreet"
														label="Please enter Street, Road etc."
														:rules="[ruleStreet]"
														:class="{ 'line-blue': entity.addressStreet != null }"
														:loading="addressFetching"
														class="duo-description rounded-0 pb-n1 mb-n1"
														v-on="on"
														v-bind="attrs"
														readonly
														flat
														solo
														dense
														full-width
													></v-text-field>
												</template>
												<v-card color="rgba(255, 255, 255, 1) !important">
													<v-row no-gutters>
														<vue-google-autocomplete
															:disabled="!addressChangeFlag"
															ref="addressGoogle"
															types="address"
															id="addressGoogleId"
															style="border-style: none !important; background-color: white !important; width: 100% !important; height: 100% !important; padding: 5px 10px"
															class="form-control duo-description rounded-0"
															placeholder="Search Address"
															v-on:placechanged="fetchPostalAddress"
															:country="['au', 'nz']"
															v-on:inputChange="isInputAddress = true"
															v-on:blur="googleAddressFocus = false"
															v-on:focus="googleAddressFocus = true"
														></vue-google-autocomplete>
													</v-row>
													<v-row no-gutters>
														<a
															v-if="isInputAddress && !isTypingInputAddress"
															@click="
																manualEditPostalAddress = true;
																streetPickerPostalAddress = false;
																selectnotfindAddress();
															"
														>
															Click here if you can't find your address
														</a>
													</v-row>
												</v-card>
											</v-menu>

											<v-text-field
												:disabled="!addressChangeFlag"
												v-else
												v-model="entity.addressStreet"
												label="Street, Road etc."
												:rules="[ruleStreet]"
												:class="{ 'line-blue': entity.addressStreet != null }"
												class="duo-description rounded-0"
												:loading="addressFetching"
												flat
												solo
												dense
												full-width
											></v-text-field>
										</v-row>
									</v-col>
									<!-- Street, Road etc. end -->

									<!-- Suburb -->
									<v-col cols="12" md="3" style="min-width: 255px">
										<v-row no-gutters class="duo-description pb-1" style="color: #2d2926 !important"> Suburb* </v-row>
										<v-row no-gutters>
											<v-text-field :disabled="!addressChangeFlag" v-model="entity.addressSuburb" label="Please enter Suburb" :class="{ 'line-blue': entity.addressSuburb != null }" class="duo-description rounded-0" flat solo dense full-width></v-text-field>
										</v-row>
									</v-col>
									<!-- Suburb end -->
								</v-row>
								<v-row no-gutters justify="space-between" v-if="entity.addressStreet != '' || manualEditPostalAddress">
									<!-- City -->
									<v-col cols="12" md="3" style="min-width: 255px">
										<v-row no-gutters class="duo-description pb-1" style="color: #2d2926 !important"> City* </v-row>
										<v-row no-gutters>
											<v-text-field :disabled="!addressChangeFlag" v-model="entity.addressCity" label="Please enter City" :class="{ 'line-blue': entity.addressCity != null }" class="duo-description rounded-0" flat solo dense full-width></v-text-field>
										</v-row>
									</v-col>
									<!-- City end -->

									<!-- Postcode -->
									<v-col cols="12" md="3" style="min-width: 255px">
										<v-row no-gutters class="duo-description pb-1" style="color: #2d2926 !important"> Postcode* </v-row>
										<v-row no-gutters>
											<v-text-field
												:disabled="!addressChangeFlag"
												v-model="entity.addressPostCode"
												label="Please enter Postcode"
												type="number"
												:class="{ 'line-blue': entity.addressPostCode != null }"
												class="duo-description rounded-0"
												flat
												solo
												dense
												full-width
											></v-text-field>
										</v-row>
									</v-col>
									<!-- Postcode end -->
									<!-- phone -->
									<v-col cols="12" md="3" style="min-width: 255px">
										<v-row no-gutters class="duo-description pb-1" style="color: #2d2926 !important"> Personal mobile number </v-row>
										<v-row no-gutters>
											<v-text-field
												:disabled="!addressChangeFlag"
												id="mobileNumber"
												label="Please enter Personal Contact Number"
												type="number"
												v-model="entity.mobileNumber"
												class="duo-description rounded-0"
												:class="{ 'line-blue': entity.mobileNumber !== undefined && entity.mobileNumber.length > 0 }"
												:loading="mobileNumberFetching"
												flat
												solo
												dense
												full-width
											></v-text-field>
										</v-row>
									</v-col>
									<!-- phone end -->
								</v-row>
								<v-row>
									<v-col cols="12" class="d-flex flex-column" style="padding-top:0;">
										<span style="font-size:12px;"><i>*Air New Zealand will deliver to the provided address. Air New Zealand does not accept liability for any loss or damage that occurs to unattended parcels.</i></span>
										<v-checkbox @focus="$forceUpdate()" v-model="entity.map.agreeToPostalTerms" label="Agree to the postal terms*"></v-checkbox>
									</v-col>
								</v-row>
							</v-row>
							<!-- manual end -->
							<!-- postal address end -->
						</v-col>

						<v-divider style="margin-bottom: 25px"></v-divider>

						<!-- action -->
						<v-row class="actions" no-gutter justify="end" style="margin: 10px 40px">
							<v-btn id="submitBtn" class="mr-4" :disabled="!valid || !entity.map.agreeToPostalTerms" color="#00B0B9" width="193px" height="50px" outlined elevation="0" @click="submitFileEdit()"> Save </v-btn>
							<v-btn id="cancelBtn" class="mr-0" color="#2D2926" width="193px" height="50px" outlined elevation="0" @click="$router.go(-1)">Cancel</v-btn>
						</v-row>
						<!-- action end -->
					</v-form>
				</v-card>
			</v-col>
		</v-row>
	</v-container>
	<personal-mobile v-else></personal-mobile>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "./subHeader.vue";
import lodash from "lodash";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import personalMobile from "./mobile/personalMobile.vue";

export default {
	components: {
		subHeader,
		VueGoogleAutocomplete,
		personalMobile
	},
	data() {
		return {
			date: new Date().toISOString().substr(0, 7),
			entity: {
				map: {
					industryStartDate: "",
				},
				addressPostCode: null,
				addressStreet: null,
				addressSuburb: null,
				addressState: null,
				addressUnit: null,
				addressCity: null,
			},
			titleList: [],
			attachment: null,
			valid: true,
			allowedMonths: (val) => val <= new Date().toJSON().slice(0, 7).replace(/-/g, "-"),
			datePickerIndustry: false,
			mobileNumberHint: "",
			mobileNumberFetching: false,
			emailFetching: false,
			emailHint: "",
			emailBannedList: [],
			addressFetching: false,
			addressFetchingPersonal: false,
			address: {
				street_number: "",
				route: "",
				locality: "",
				administrative_area_level_1: "",
				postal_code: "",
			},
			selectedAddressPersonal: "",
			streetPickerPostalAddress: false,
			manualEditPostalAddress: false,
			isTypingInputAddress: false,
			googleAddressFocus: false,
			isInputAddress: false,
			addressChangeFlag: false
		};
	},

	mounted: function () {
		this.profile();
		this.listTitle();
		this.listBannedEmail();
	},

	created: function () {
		this.debouncedCheckMobileNumber = lodash.debounce(this.checkMobileNumber, 500);
		this.debouncedCheckEmail = lodash.debounce(this.checkEmail, 500);
		this.debouncedValidatePostalAddress = lodash.debounce(this.validatePostalAddress, 500);
	},

	methods: {
		profile() {
			axios
				.get("/system/person/me")
				.then((response) => {
					this.entity = response.data;
				})
				.catch(() => {});
		},

		onAddressCheckboxChange(value) {
			if(value == true){
				this.entity.map.agreeToPostalTerms = false;
			}
		},

		fetchPostalAddress(addressData) {
			this.address = addressData;
			this.entity.addressPostCode = this.address.postal_code ? this.address.postal_code : "";
			if (this.address.street_number && this.address.route) {
				this.entity.addressStreet = this.address.street_number + " " + this.address.route;
			} else if (this.address.route) {
				this.entity.addressStreet = this.address.route;
			} else {
				this.entity.addressStreet = "";
			}
			this.entity.addressSuburb = this.address.locality ? this.address.locality : "";
			this.entity.addressState = this.address.administrative_area_level_1 ? this.address.administrative_area_level_1 : "";
			this.entity.addressUnit = "";
			this.entity.addressCity = "";
			this.$forceUpdate();
		},

		listTitle() {
			axios
				.get("/system/common/title/list")
				.then((response) => {
					this.titleList = response.data;
				})
				.catch(() => {});
		},

		checkMobileNumber(v) {
			this.mobileNumberHint = "";
			if (!v) {
				return;
			}
			this.mobileNumberFetching = true;
			axios
				.post("/system/authentication/mobile/check", {
					mobileNumber: v,
				})
				.then((response) => {
					if (response.data == true) {
						this.mobileNumberHint = "This contact number is already registered to duo!";
					}
				})
				.catch(() => {})
				.finally(() => {
					this.mobileNumberFetching = false;
				});
		},

		ruleEmail(v) {
			this.debouncedCheckEmail(v);
			if (v != undefined && v.length > 0) {
				if (this.$root.isAllowedEmail(v)) {
					return true;
				} else if (v.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)) {
					let type = null;
					let prefix = v.slice(v.indexOf("@"));
					for (let o of this.emailBannedList) {
						if (prefix.indexOf(o.prefix) == 0) {
							type = o.type;
							break;
						}
					}
					if (type) {
						if (type === "Personal") {
							return "duo cannot accept personal email address, please enter a work email address. If this is your work email address please contact duosupportnz@airnz.co.nz for assistance.";
						}
						return "This email domain cannot be accepted. The duo program is available to Australian travel agents only.";
					} else {
						if (!this.emailFetching && this.emailHint != "") {
							return this.emailHint;
						} else {
							return true;
						}
					}
				} else {
					return "Please enter a valid email address";
				}
			} else {
				return "Please enter a valid email address";
			}
		},

		checkEmail(v) {
			this.emailHint = "";
			if (!v) {
				return;
			}
			this.emailFetching = true;
			axios
				.post("/system/authentication/email/check", { email: v })
				.then((response) => {
					if (response.status == 200 && response.data == true) {
						this.emailHint = "This email address is already registered to duo!";
					}
				})
				.catch(() => {})
				.finally(() => {
					this.emailFetching = false;
				});
		},

		submitFileEdit() {
			let formData = new FormData();
			formData.append("entity", JSON.stringify(this.entity));

			if (this.attachment) {
				formData.append("attachment", this.attachment);
			}

			this.$root.saveActivityLog({type: 'Update_profile', value: "Personal details"});

			axios
				.post("/system/person/edit", formData, {
					headers: {
						"Content-Type": "multipart/form-data",
					},
				})
				.then(() => {
					location.href = "#/profile";
				})
				.catch(() => {});
		},

		ruleName(v) {
			if (v !== undefined && v.length > 0) {
				if (v.match(/[^A-Za-z_.-\s]/)) {
					return "Only characters allowed";
				} else if (v.length > 30) {
					return "Maximum of 30 characters";
				} else {
					return true;
				}
			} else {
				return "Only characters allowed";
			}
		},

		ruleStreet(v) {
			let text = v !== null ? v.toLowerCase().replace(/\./g, "") : "";
			if (text.startsWith("po box")) {
				return "PO Boxes are not be accepted, we require an address which will accept couriers";
			} else {
				return true;
			}
		},

		validatePostalAddress(v) {
			if (!v) {
				return;
			}
			if (this.selectedAddressPersonal == v) {
				// user just selected an address from addressify, don't bother going to backend
				return;
			}
			if (this.personalAdress == true) {
				return;
			}
			this.addressFetchingPersonal = true;
		},

		listBannedEmail() {
			axios
				.get("/system/common/email/banned/list")
				.then((response) => {
					this.emailBannedList = response.data;
				})
				.catch(() => {});
		},
	},
};
</script>

<style scoped>
.actions .v-btn {
	font-size: 17px;
}
</style>
