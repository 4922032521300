<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="headers" :items="landingPageList" sort-by="id" :search="keyword"
			:footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<h2>{{ $root.capitalizeFirst(title) }} list</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details
								style="margin-right:10px;" />
							<v-btn small fab dark color="secondary" title="Add" @click="showAddDialog">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 100) }}</template>
			<template v-slot:item.istnc="{ item }">
				<v-icon v-if="item.istnc === true" color="green" title="Active">mdi-check</v-icon>
			</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex flex-row">
					<v-icon
						@click="$router.push('/administration/creator/page/landing/' + item.id)">mdi-clipboard-edit-outline</v-icon>
					<v-icon class="ml-2" @click="showUpdateDialog(item)">mdi-pencil</v-icon>
					<v-icon class="ml-2" @click="entity = item; confirmationDialog = true">mdi-delete</v-icon>
				</div>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>

		<v-dialog v-model="dialogUpdate" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">{{ entity.id ? "Update Page Info" : "Add New Page" }}</span>
				</v-card-title>
				<v-card-text>
					<v-form ref="form">
						<v-text-field @input="entity.slug = entity.title ? entity.title.replaceAll(' ', '') : null"
							v-model="entity.title" :rules="[(v) => !!v || 'Enter Title']" label="Title" rows="3" />
						<v-text-field v-model="entity.slug" label="Landing page url" :rules="[(v) => !!v || 'Enter Url']" />
						<span>
							{{ hostname() + "/#/page/" + (entity.slug ? entity.slug : "") }} &nbsp;&nbsp;&nbsp;&nbsp;
							<v-btn @click="copyToClipboard(hostname() + '/#/page/' + (entity.slug ? entity.slug : ''))"><v-icon>mdi-clipboard-text</v-icon></v-btn>
							
						</span>
						
						<div class="mt-5">
							<v-checkbox id="hideTitle" v-model="entity.istnc" class="mt-0 pt-0" :disabled="entity.id">
								<template v-slot:label>
									<span style="font-size: 14px; color: #2d2926 !important">This is a terms and conditions page</span>
								</template>
							</v-checkbox>
						</div>

					</v-form>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="closeDialog">Cancel</v-btn>
					<v-btn @click="save" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<confirmation-dialog title="Confirm delete" :show="confirmationDialog"
			content="Are you sure you want to delete this page?"
			@onCloseDialog="entity = {}; confirmationDialog = false" @onClickConfirm="remove" />
		<v-snackbar
			v-model="snackbar"
			:timeout="1000"
			color="success"
			variant="outlined"
		>
			Copied to clipboard
		</v-snackbar>
	</v-main>
</template>

<script>
import axios from "axios";
import confirmationDialog from "./dialog/confirmationDialog.vue";

export default {
	components: {
		confirmationDialog
	},
	mounted() {
		this.getList();
	},
	data() {
		return {
			snackbar: false,
			keyword: "",
			headers: [
				{ text: "Title", value: "title" },
				{ text: "Url", value: "slug" },
				{ text: "Is T&C ?", value: "istnc" },
				{ text: "", value: "action", sortable: false },
			],
			entity: {},
			landingPageList: [],
			dialogUpdate: false,
			confirmationDialog: false,
		}
	},
	methods: {
		hostname() {
			return window.location.origin;
		},
		getList() {
			axios.get("/system/creator/page/list").then(response => {
				this.landingPageList = response.data;
			})
		},
		showAddDialog() {
			this.entity = {};
			this.dialogUpdate = true;
		},
		showUpdateDialog(item) {
			this.entity = {...item};
			this.dialogUpdate = true;
		},
		closeDialog() {
			this.dialogUpdate = false;
			this.entity = {};
		},
		save() {
			let url = this.entity.id ? "/system/creator/page/table/edit" : "/system/creator/page/add";
			axios.post(url, { entity: this.entity }).then(
				(response) => {
					if (response.status == 200) {
						this.getList();
						this.closeDialog();
					}
				}
			)
		},
		remove() {
			axios.post('/system/creator/page/delete', { id: this.entity.id }).then(
				(response) => {
					if (response.status == 200) {
						this.getList();
						this.confirmationDialog = false;
						this.entity = {};
					}
				}
			)
		},
		copyToClipboard(text) {
			navigator.clipboard.writeText(text);
			this.snackbar = true;
		}

	}
}
</script>