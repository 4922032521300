<template>
	<v-container fluid>
		<v-row class="d-flex justify-center align-center">
			<!-- <v-card tile style="background-color: rgba(255, 255, 255, 0.75);padding: 25px !important;width: 85%;"> -->
			<v-container class="container-mysales">
				<template>
					<h2 class="duo" style="font-size: 28px">my<b style="font-family: 'AirNZ Sans Semibold' !important">sales</b></h2>
				</template>

				<v-expansion-panels v-model="panel" multiple>
					<v-expansion-panel v-for="(item, i) in dataTable" :key="i" :style="{ 'margin-top': i > 0 ? '15px' : '0px' }">
						<v-expansion-panel-header class="custom-color">
							<h3>{{ item.monthyear }}</h3>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-row no-gutters class="my-0" style="border-bottom: 2px solid black"> </v-row>

							<!-- filter -->
							<v-card-actions class="pa-0">
								<v-container fluid style="padding: 11px 0 3px 0">
									<v-row no-gutters align="center" justify="space-around">
										<!-- filter search -->
										<v-col class="px-0 pt-2 pb-8" cols="12">
											<v-row class="d-flex flex-row pl-3" align="end" justify="end">
												<v-text-field :id="'txt-search-' + i" v-model="search[i]" multiple label="Search PNR" style="color: red" @input="searchquery(i)" :hide-details="true" clearable class="cleariconsize"></v-text-field>
												<v-btn :id="'btn-search-' + i" class="btn-search" icon tile elevation="0" @click="searchquery(i)" color="white">
													<v-icon size="20" class="icon-duo-search"></v-icon>
												</v-btn>
											</v-row>
										</v-col>
										<!-- filter search end -->

										<v-col class="pr-3 mb-n2" cols="12" align-self="end">
											<span class="duo" style="font-size: 14px">Filter:</span>
										</v-col>

										<!-- filter status -->
										<!-- previous used icon for close button: clear-icon="icon-duo-close panel" -->
										<v-col class="px-lg-3" cols="12" >
											<v-select
												:id="'cmb-status-' + i"
												label="Status"
												:items="statusItems"
												v-model="status[i]"
												multiple
												@input="searchquery(i)"
												:hide-details="true"
												clearable
												class="cleariconsize"
											>
												<template v-slot:label>
													<span style="font-size: 14px; line-height: 14px; color: black">Status</span>
												</template>
											</v-select>
										</v-col>
										<!-- filter status end -->

										<!-- filter departure -->
										<v-col class="px-lg-3" cols="12" >
											<v-select
												:id="'cmb-departure-' + i"
												label="Departure"
												:items="departureItems"
												v-model="departure[i]"
												multiple
												@input="searchquery(i)"
												:hide-details="true"
												clearable
												class="cleariconsize"
											>
												<template v-slot:label>
													<span style="font-size: 14px; line-height: 14px; color: black">Departure</span>
												</template>
											</v-select>
										</v-col>
										<!-- filter departure end -->

										<!-- filter campaign -->
										<v-col class="px-lg-3" cols="12" >
											<v-text-field :id="'txt-campaign-' + i" label="Campaign" v-model="campaign[i]" multiple @input="searchquery(i)" :hide-details="true" clearable class="cleariconsize">
												<template v-slot:label>
													<span style="font-size: 14px; line-height: 14px; color: black">Campaign</span>
												</template>
											</v-text-field>
										</v-col>
										<!-- filter campaign end -->

										<v-col class="px-0 pb-1" cols="12" align-self="end">
											<span class="duo" @click="clearfilter(i)" style="cursor: pointer; font-size: 14px">Clear all</span>
										</v-col>

										
									</v-row>
								</v-container>
							</v-card-actions>

							<!--  -->
							<v-container>
								<!-- Divider -->
								<v-row cols="12" class="d-flex pt-6" style="border-bottom: thin solid rgba(0, 0, 0, 0.12)">
								</v-row>

								<v-row v-for="(row, index) in visibleData(i)" :key="index">
									<template v-for="h in headers">
										<!-- Status -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Status'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1 mr-n2">
													<v-tooltip right>
														<template v-slot:activator="{ on, attrs }">
															<v-img :style="{ width: '40px', margin: '5px' }" v-if="row.status === 'Pending'" src="/icon/icon-pnr-pending.svg" v-bind="attrs" v-on="on"></v-img>

															<v-img :style="{ width: '40px', margin: '5px' }" v-else-if="row.status === 'Validated' || row.status === 'Matched_PNR'" src="/icon/icon-pnr-approve.svg" v-bind="attrs" v-on="on"></v-img>

															<v-img :style="{ width: '40px', margin: '5px' }" v-else src="/icon/icon-pnr-error.svg" v-bind="attrs" v-on="on"></v-img>
														</template>
														<!-- <span v-if="item.status.toLowerCase() == 'error'" style="font-size: 10px; letter-spacing: 1px">Error: {{ item.comments }}</span> -->
														<div v-if="row.status.toLowerCase() == 'error'">
															<span style="font-size: 10px; letter-spacing: 1px">Your booking could not be validated due to one of these possible reasons:</span>
															<ul style="font-size: 10px; letter-spacing: 1px">
																<li>Not ticketed with the 15day ticketing window</li>
																<li>Booking not originated ex NZ</li>
																<li>PNR has not been matched in the GDS</li>
															</ul>
															<span style="font-size: 10px; letter-spacing: 1px">Please check and resubmit your PNR</span>
														</div>
														<span v-else style="font-size: 10px; letter-spacing: 1px">{{ row.status === 'Matched_PNR' ? 'Validated' : row.status }}</span>
													</v-tooltip>
												</v-col>
											</v-row>
										</v-col>

										<!-- PNR -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'PNR'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="6" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="6" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>
										
										<!-- Created -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Date Created'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="6" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="6" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Group Booking -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Group Booking'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Departure -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Departure'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Arrival -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Arrival'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Total Passengers -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Total Passengers'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Economic -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Econ.'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Premium -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Prem.'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Business -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Bus.'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Campaign -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == 'Campaign'">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-1">
													<span>{{ row[h.value] }}</span>
												</v-col>
											</v-row>
										</v-col>

										<!-- Delete -->
										<v-col cols="12" class="d-flex" :key="h.bookingCode" v-if="h.text == ''" style="border-top: thin solid rgba(0, 0, 0, 0.12);" :style="{borderBottom:  index != item.data.length-1 ? 'thin solid rgba(0, 0, 0, 0.12)' : ''}">
											<v-row class="d-flex justify-space-between">
												<v-col cols="10" class="d-flex align-center justify-start pl-1">
													<span>{{ h.text }}</span>
												</v-col>
												<v-col cols="2" class="d-flex align-center justify-end pr-0">
													<div style="margin-right: 5.8px">
														<v-btn :id="'btn-delete-' + row.bookingCode" v-if="!row.editrow" :disabled="!(row.status === 'Pending')" tile small icon @click="deleteitem(row, i)" class="no-background-hover mr-0">
															<!-- <v-img src="/icon/icon-trash.svg" contain width="20" height="20"></v-img> -->
															<!-- <v-icon :color=" size="20" class="pt-1">icon-duo-trash</v-icon> -->
															<svg xmlns="http://www.w3.org/2000/svg" width="15" height="17.02" viewBox="0 0 15.084 17.112">
																<path
																	class="a"
																	:fill="row.status === 'Pending' ? 'black' : 'grey'"
																	d="M5.3,18.143h7.314a1.659,1.659,0,0,0,1.185-.4,1.62,1.62,0,0,0,.46-1.161l.489-11.191h1.108a.628.628,0,0,0,.456-.183.617.617,0,0,0,.187-.46.6.6,0,0,0-.187-.444.628.628,0,0,0-.456-.183H2.051A.618.618,0,0,0,1.6,4.3a.6.6,0,0,0-.187.444.633.633,0,0,0,.635.643H3.167l.489,11.191A1.611,1.611,0,0,0,4.12,17.74,1.662,1.662,0,0,0,5.3,18.143Zm1.2-2.3a.516.516,0,0,1-.362-.13.486.486,0,0,1-.151-.358l-.22-8.275a.447.447,0,0,1,.13-.354.515.515,0,0,1,.375-.134.505.505,0,0,1,.354.13.486.486,0,0,1,.151.358L7,15.358a.447.447,0,0,1-.13.354A.515.515,0,0,1,6.5,15.846Zm2.46,0a.53.53,0,0,1-.379-.134.454.454,0,0,1-.143-.346V7.083a.464.464,0,0,1,.143-.354.6.6,0,0,1,.753,0,.458.458,0,0,1,.147.354v8.283a.448.448,0,0,1-.147.346A.533.533,0,0,1,8.958,15.846Zm2.452,0a.515.515,0,0,1-.375-.134.471.471,0,0,1-.138-.354l.236-8.275a.486.486,0,0,1,.151-.358.579.579,0,0,1,.737,0,.447.447,0,0,1,.13.354l-.236,8.283a.451.451,0,0,1-.143.35A.516.516,0,0,1,11.41,15.846ZM5.423,4.379H6.93V3.027a.572.572,0,0,1,.175-.44.672.672,0,0,1,.476-.163h2.737a.687.687,0,0,1,.481.163.565.565,0,0,1,.179.44V4.379h1.5V2.953a1.833,1.833,0,0,0-.55-1.409,2.139,2.139,0,0,0-1.519-.513H7.484a2.127,2.127,0,0,0-1.511.513,1.833,1.833,0,0,0-.55,1.409Z"
																	transform="translate(-1.416 -1.031)"
																/>
															</svg>
														</v-btn>
														<v-btn v-else tile small icon @click="closeitem(row)" class="no-background-hover">
															<v-icon class="icon-duo-close"></v-icon>
														</v-btn>
													</div>
												</v-col>
											</v-row>
										</v-col>
									</template>
								</v-row>
							</v-container>
							<!-- filter end -->
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</v-container>
		</v-row>

		<!---Delete Sales--->
		<v-dialog v-model="dialogDelete" width="505px">
			<v-card class="white" height="240px" :style="{ borderRadius: '8px'}">
				<v-card-title class="pa-0" height="66px" :style="{ backgroundColor: '#440099' }">
					<div :style="{ margin: '18px 17px 18px 24.5px' }">
						<v-img width="30px" height="30px" :src="$bucket + '/image/warning.svg'" contain style="color: white;"></v-img>
					</div>
					<div>
						<span class="notification-title-text-style" style="color: white;">Warning</span>
					</div>
				</v-card-title>
				<v-card-text class="pa-0 px-3 pt-2 d-flex justify-center" height="190.71px">
					<div class="notification-content-warning-style">
						<div v-if="$store.state.notification.content && $store.state.notification.content.type == 'NOTIFICATION'">
							<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.fieldErrorList">{{ item }}</div>
							<div class="notification-content-text-style" :key="item" v-for="item in $store.state.notification.content.errorList">{{ item }}</div>
							<div :key="item" v-for="(item, index) in $store.state.notification.content.noticeList">
								<div v-if="index == 0 && item.includes('Session')" class="notification-content-text-style">{{ item }}</div>
								<div v-else class="regular-text-style" style="color: #2D2926 !important; padding-top: 20px;">{{ item }}</div>
							</div>
						</div>
						<div v-else>
							<div class="notification-content-text-style">Are you sure you want to delete this booking? Once deleted, you will not be able to retrieve the details and will be required to enter the PNR again.</div>
						</div>
					</div>
				</v-card-text>
				<v-card-actions class="px-3 pt-3 justify-center">
					<v-row class="d-flex justify-space-between">
						<v-col cols="6">
							<v-btn @click="submitDelete(selectedRow, selectedMonthYearindex)" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" block bottom dark>
								<span class="regular-text-style">Delete</span>
							</v-btn>
						</v-col>
						<v-col cols="6">
							<v-btn @click="dialogDelete = false" class="pa-0 mr-0" color="#2d2926" :elevation="0" height="50px" block bottom outlined>
								<span class="regular-text-style" style="color: black !important;">Cancel</span>
							</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-dialog>

	</v-container>
</template>

<style scoped>
.mysales.v-data-table > .v-data-table__wrapper > table > tbody > tr > td.text-end {
	/* border-bottom: thin solid rgba(0, 0, 0, 0.12); */
	padding-right: 0;
	font-size: 0.875rem;
	height: 48px;
}
.v-application--is-ltr .v-text-field .v-label {
	color: black !important;
	font-size: 14px !important;
	font-family: "AirNZ Sans Regular" !important;
}
.theme--light.v-data-table {
	background-color: transparent !important;
	color: rgba(0, 0, 0, 0.87);
}
.cleariconsize.v-select__selections {
	font-size: 14px;
}
.cleariconsize .v-input__append-inner .icon-duo-close {
	font-size: 14px;
}
.cleariconsize.v-input input {
	font-size: 14px !important;
}
.cleariconsize .v-select__selection--comma {
	margin: 7px 4px 7px 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 14px;
}
.cleariconsize .mdi-menu-down::before {
	content: "\F0140";
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
	font-size: 14px !important;
	font-family: "AirNZ Sans Regular" !important;
	color: black !important;
	text-align: center;
	font-weight: 400;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th {
	font-size: 13px !important;
	text-align: center !important;
	font-family: "AirNZ Sans Regular" !important;
}
.theme--light.v-expansion-panels .custom-color.v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
	color: black !important;
}
.v-expansion-panel--active > .v-expansion-panel-header {
	min-height: 54px;
}
.v-expansion-panels > .v-expansion-panel {
	background: rgba(255, 255, 255, 0.9) !important;
	border-radius: 6px !important;
}
.no-background-hover::before {
	background-color: transparent !important;
}
.title-line {
	border: 1px solid black;
	background-color: black;
	margin-top: -3px;
	width: 50%;
}
.active-line {
	border: 3.5px solid black;
	background-color: black;
	margin-top: 10px;
}
.non-active-line {
	border: 1px solid transparent;
	background-color: transparent;
}
.v-input__icon--append-outer .v-icon {
	background-color: #221551 !important;
	color: white !important;
	padding: 5px;
}
.btn-search {
	width: 40px;
	height: 40px;
	background-color: #440099;
	border-radius: 4px;
	margin-left: 10px;
}
.container-mysales {
	width: 80%;
}
@media (max-width: 1200px) {
	.container-mysales {
		width: 95%;
	}
}
@media (max-width: 600px) {
	.theme--light.v-label {
		color: black !important;
		font-size: 14px !important;
	}
	.row-pnr-input {
		padding: 0 10px 10px 10px;
	}
	.container-mysales {
		padding: 0px 5px;
		margin-top: 40px;
	}
}
.v-expansion-panel::before {
	box-shadow: none;
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";

export default {
	components: {},
	data() {
		return {
			panel: [0],
			month_names: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
			month_names_short: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
			dialogDelete: false,
			totalValidated: 0,
			totalPending: 0,
			totalMatched: 0,
			totalError: 0,
			selectedMonthYearindex: 0,
			selectedRow: [],
			selectedindex: 0,
			pnr: "",
			statusItems: ["Validated", "Pending", "Matched_PNR", "Error"],
			status: [],
			departure: [],
			departureItems: ["SYD", "BNE", "OOL", "MCY", "CNS", "PER", "ADL", "MEL", "HBA"],
			arrival: [],
			arrivalItems: ["AKL", "WLG", "CHC", "ZQN", "RAR", "NOR", "HBA"],
			final: [],
			finalItems: ["LAX", "HNL", "SFO", "IAH", "ORD"],
			campaign: [],
			bookingCodes: [],
			groupBooking: [],
			bookingList: [],
			search: [],
			headers: [
				{
					text: "Status",
					align: "start",
					sortable: false,
					value: "status"
				},
				{ text: "PNR", value: "bookingCode", sortable: false, align: "center" },
				{ text: "Date Created", value: "creationDateShow", sortable: false, align: "center" },
				{ text: "Departure", value: "itineraryOriginAirportCode", sortable: false, align: "center" },
				{ text: "Arrival", value: "itineraryDestinationAirportCode", sortable: false, align: "center" },
				{ text: "Total Passengers", value: "passengerNum", sortable: false, align: "center" },
				{ text: "Econ.", value: "economySectors", sortable: false, align: "center" },
				{ text: "Prem.", value: "premiumEconomySectors", sortable: false, align: "center" },
				{ text: "Bus.", value: "businessPremierSectors", sortable: false, align: "center" },
				{ text: "Campaign", value: "campaign", sortable: false, align: "center" },
				// { text: "", value: "btn1", sortable: false },
				{ text: "", value: "btn2", sortable: false, align: "end", class: "delete" }
			],
			dataTable: [],
			datacache: [],
			allowedDates: (val) => val <= new Date().toJSON().slice(0, 10).replace(/-/g, "-") && val >= new Date(new Date().getTime() + -90 * 24 * 60 * 60 * 1000).toJSON().slice(0, 10).replace(/-/g, "-")
		};
	},
	mounted: function () {
		this.initialize();
	},
	computed: {
		visibleData: function() {
			return (i) => {
				if (this.search[i] && this.search[i].length > 0) {
					var filteredData = this.dataTable[i].data.filter(item => {
						return item.bookingCode.toLowerCase().includes(this.search[i].toLowerCase());
					});

					return filteredData;
				} else if (this.dataTable[i] && this.dataTable[i].data) {
					return this.dataTable[i].data;
				}
				return [];
			};
		},
	},
	methods: {
		initialize() {
			this.loadgrid();
			this.$root.$on("update", this.loadgrid);
		},

		loadgrid() {
			axios
				.get("/system/sales/booking/list/month", { params: { months: 12 } })
				.then((response) => {
					this.dataTable = [];
					this.mappingdata(response.data);
				})
				.catch(() => {});
		},

		mappingdata(data) {
			var indexarraydata = 0;
			for (let item of data) {
				//let passengerNum = item.economySectors > 0 || item.premiumEconomySectors > 0 || item.businessPremierSectors > 0 ? item.totalPassengerCount / (item.economySectors + item.premiumEconomySectors + item.businessPremierSectors) : item.totalPassengerCount;
				
				if (this.dataTable.filter((e) => e.monthyearcode == item.creationDate.date.month + item.creationDate.date.year).length > 0) {
					this.dataTable[indexarraydata - 1].data.push({
						id: item.id,
						status: item.status,
						creationDate: item.creationDate,
						creationDateShow: item.creationDate.date.day + "-" + this.month_names_short[item.creationDate.date.month - 1] + "-" + new Date("" + item.creationDate.date.year + "").getFullYear().toString().substr(2, 2),
						creationNewDate: new Date(item.creationDate.date.year + "/" + item.creationDate.date.month + "/" + parseInt(item.creationDate.date.day)).toISOString().substr(0, 10),
						bookingCode: item.bookingCode,
						cty: item.cty,
						//passengerNum: passengerNum,
						passengerNum: item.totalPassengerCount,
						totalPassengerCount: item.totalPassengerCount,
						ySectorCount: item.ySectorCount,
						uSectorCount: item.uSectorCount,
						cSectorCount: item.cSectorCount,
						economySectors: item.businessPremierSectors > 0 || item.premiumEconomySectors > 0 || item.economySectors == 0 ? 0 : item.totalPassengerCount,
						premiumEconomySectors: item.businessPremierSectors > 0 || item.premiumEconomySectors == 0 ? 0 : item.totalPassengerCount,
						businessPremierSectors: item.businessPremierSectors == 0 ? 0 : item.totalPassengerCount,
						campaign: item.campaign,
						itineraryOriginAirportCode: item.itineraryOriginAirportCode,
						arrivalAirport: item.arrivalAirport,
						itineraryDestinationAirportCode: item.itineraryDestinationAirportCode,
						clazz: item.clazz,
						comments: item.comments,
						personId: item.personId,
						askLog: item.askLog,
						askCount: item.askCount,
						answer: item.answer,
						btn1: 1,
						btn2: 1,
						// bookingGroup: item.bookingGroup,
						editrow: false,
						ticketNumber: item.ticketNumber,
						stock: item.stock,
						bookingGroupId: item.bookingGroupId,
						creationDateMenu: false
					});
					this.datacache[indexarraydata - 1] = Object.assign({}, this.dataTable[indexarraydata - 1]);
					if (item.status == "Validated") {
						this.totalValidated++;
					} else if (item.status == "Pending") {
						this.totalPending++;
					} else if (item.status == "Matched_PNR") {
						this.totalMatched++;
					} else {
						this.totalError++;
					}
					this.status[indexarraydata - 1] = "";
					this.departure[indexarraydata - 1] = "";
					this.arrival[indexarraydata - 1] = "";
					this.final[indexarraydata - 1] = "";
					this.campaign[indexarraydata - 1] = "";
					this.groupBooking[indexarraydata - 1] = "";
				} else {
					this.dataTable.push({
						monthyear: this.month_names[item.creationDate.date.month - 1] + " " + item.creationDate.date.year,
						data: [],
						monthyearcode: item.creationDate.date.month + item.creationDate.date.year
					});
					this.dataTable[indexarraydata].data.push({
						id: item.id,
						status: item.status,
						creationDate: item.creationDate,
						creationDateShow: item.creationDate.date.day + "-" + this.month_names_short[item.creationDate.date.month - 1] + "-" + new Date("" + item.creationDate.date.year + "").getFullYear().toString().substr(2, 2),
						creationNewDate: new Date(item.creationDate.date.year + "/" + item.creationDate.date.month + "/" + item.creationDate.date.day).toISOString().substr(0, 10),
						bookingCode: item.bookingCode,
						cty: item.cty,
						//passengerNum: passengerNum,
						passengerNum: item.totalPassengerCount,
						totalPassengerCount: item.totalPassengerCount,
						ySectorCount: item.ySectorCount,
						uSectorCount: item.uSectorCount,
						cSectorCount: item.cSectorCount,
						economySectors: item.businessPremierSectors > 0 || item.premiumEconomySectors > 0 || item.economySectors == 0 ? 0 : item.totalPassengerCount,
						premiumEconomySectors: item.businessPremierSectors > 0 || item.premiumEconomySectors == 0 ? 0 : item.totalPassengerCount,
						businessPremierSectors: item.businessPremierSectors == 0 ? 0 : item.totalPassengerCount,
						campaign: item.campaign,
						itineraryOriginAirportCode: item.itineraryOriginAirportCode,
						arrivalAirport: item.arrivalAirport,
						itineraryDestinationAirportCode: item.itineraryDestinationAirportCode,
						clazz: item.clazz,
						comments: item.comments,
						personId: item.personId,
						askLog: item.askLog,
						askCount: item.askCount,
						answer: item.answer,
						btn1: 1,
						btn2: 1,
						// bookingGroup: item.bookingGroup,
						editrow: false,
						ticketNumber: item.ticketNumber,
						stock: item.stock,
						bookingGroupId: item.bookingGroupId,
						creationDateMenu: false
					});
					this.datacache[indexarraydata] = Object.assign({}, this.dataTable[indexarraydata]);
					if (item.status == "Validated") {
						this.totalValidated++;
					} else if (item.status == "Pending") {
						this.totalPending++;
					} else if (item.status == "Matched_PNR") {
						this.totalMatched++;
					} else {
						this.totalError++;
					}
					this.status[indexarraydata] = "";
					this.departure[indexarraydata] = "";
					this.arrival[indexarraydata] = "";
					this.final[indexarraydata] = "";
					this.campaign[indexarraydata] = "";
					this.groupBooking[indexarraydata] = "";
					indexarraydata++;
				}
			}
		},

		deleteitem(row, index) {
			this.selectedRow = row;
			this.selectedMonthYearindex = index;
			this.dialogDelete = true;
		},

		submitDelete(rowSelected, indexSelected) {
			axios
				.get("/system/sales/delete/booking", { params: { id: rowSelected.id } })
				.then(() => {
					let selectedData = this.dataTable[indexSelected].data.find((datarow) => datarow === rowSelected);
					this.dataTable[indexSelected].data = this.dataTable[indexSelected].data.filter((datarow) => datarow !== rowSelected);
					if (rowSelected.status == "Validated") {
						this.totalValidated--;
					} else if (rowSelected.status == "Pending") {
						this.totalPending--;
					} else if (rowSelected.status == "Matched_PNR") {
						this.totalMatched--;
					} else {
						this.totalError--;
					}
					this.closeDelete();
					this.$emit("on-change", selectedData);
				})
				.catch(() => {});
		},

		closeitem(row) {
			this.selectedindex = this.dataTable[this.selectedMonthYearindex].data.indexOf(row);
			this.dataTable[this.selectedMonthYearindex].data[this.selectedindex].editrow = false;
		},

		closeDelete() {
			this.initialize();
			this.dialogDelete = false;
		},

		searchquery(index) {
			let tempData = [];
			tempData = this.datacache[index].data;
			if (this.status[index] && this.status[index].length > 0) {
				tempData = tempData.filter((x) => {
					for (let status of this.status[index]) {
						if (x.status.toLowerCase() == status.toLowerCase()) {
							return true;
						}
					}
					return false;
				});
			}
			if (this.departure[index] && this.departure[index].length > 0) {
				tempData = tempData.filter((x) => {
					for (let departure of this.departure[index]) {
						if (x.itineraryOriginAirportCode == departure) {
							return true;
						}
					}
					return false;
				});
			}
			if (this.arrival[index] && this.arrival[index].length > 0) {
				tempData = tempData.filter((x) => {
					for (let arrival of this.arrival[index]) {
						if (x.itineraryOriginAirportCode == arrival) {
							return true;
						}
					}
					return false;
				});
			}
			if (this.final[index] != undefined && this.final[index] != "" && this.final[index].trim().length > 0) {
				tempData = tempData.filter((x) => {
					for (let final of this.final[index]) {
						if (x.itineraryDestinationAirportCode == final) {
							return true;
						}
					}
					return false;
				});
			}
			if (this.campaign[index] && this.campaign[index] != "" && this.campaign[index].trim().length > 0) {
				tempData = tempData.filter((x) => {
					for (let campaign of this.campaign[index]) {
						if (x.campaign.toLowerCase().indexOf(campaign.toLowerCase()) > -1) {
							return true;
						}
					}
					return false;
				});
			}

			this.dataTable[index].data = tempData;
		},

		clearfilter(index) {
			this.departure[index] = "";
			this.arrival[index] = "";
			this.final[index] = "";
			this.campaign[index] = "";
			this.groupBooking[index] = "";
			this.pnr = "";
			this.dataTable[index].data = this.datacache[index].data;
		},

		filter(v, index) {
			if (v != "All") {
				this.status[index] = v;
				this.searchquery(index);
			} else {
				this.status[index] = "";
				this.searchquery(index);
			}
		}
	}
};
</script>
