<template>
	<v-container v-if="$vuetify.breakpoint.mdAndUp" fluid style="height: 80vh">
		<subHeader />
		<v-row no-gutters justify="center" justify-lg="center" align="center" style="height: 100%">
			<!-- personal details -->
			<v-card class="my-4 mr-2" elevation="0" rounded="lg" width="400px" height="272px">
				<v-row no-gutters style="height: 51px; padding-left: 5px; margin-bottom: 15px" align="center">
					<v-btn class="ml-1" fab color="#2d2926" elevation="0" height="43" width="43">
						<v-icon class="icon-profile-personal" color="white" size="45"></v-icon>
					</v-btn>
					<v-toolbar-title class="box-title pl-2"> <strong class="pr-1">Personal</strong>details </v-toolbar-title>
					<v-spacer></v-spacer>
					<a href="#/profile/personal" style="text-decoration: none !important" class="flex text-right">
						<v-icon size="35px" color="#2D2926">mdi-dots-vertical</v-icon>
					</a>
				</v-row>
				<v-card-text class="px-4 py-1">
					<v-row no-gutters class="text-name py-1">{{ entity.firstName }} {{ entity.lastName }}</v-row>
					<v-row no-gutters class="py-1" style="font-size: 17px">Joined&nbsp;<b>duo</b>:&nbsp;{{ entity.map.joined }}</v-row>
				</v-card-text>
				<v-card-text class="px-4 py-1" style="line-height: 21px">
					<br />
					<v-row no-gutters><b class="pr-1">Airpoints&trade; Number:</b> {{ entity.airpointsNumber }}</v-row>
					<v-row no-gutters><b class="pr-1">Email:</b> {{ entity.email }}</v-row>
					<v-row no-gutters><b class="pr-1">Phone:</b> {{ entity.mobileNumber }}</v-row>
					<v-row no-gutters class="duo"> <b class="pr-1">Industry experience:</b>{{ industryExperience }}</v-row>
				</v-card-text>
			</v-card>
			<!-- personal details end -->
			<!-- company details -->
			<v-card class="my-4 mx-1" elevation="0" rounded="lg" width="400px" height="272px">
				<v-row no-gutters style="height: 51px; padding-left: 5px; margin-bottom: 15px" align="center">
					<v-btn class="ml-1" fab color="#2d2926" elevation="0" height="43" width="43">
						<v-icon class="icon-profile-company" color="white" size="45"></v-icon>
					</v-btn>
					<v-toolbar-title class="box-title pl-2"> <strong class="pr-1">Company</strong>details </v-toolbar-title>
					<v-spacer></v-spacer>

					<a href="#/profile/company" style="text-decoration: none !important" class="flex text-right">
						<v-icon size="35px" color="#2D2926">mdi-dots-vertical</v-icon>
					</a>
				</v-row>
				<v-card-text class="px-4 py-1">
					<v-row no-gutters class="text-name py-1">{{ entity.map.agency.name }}</v-row>
					<v-row no-gutters class="py-1" style="font-size: 17px">
						<template v-if="entity.map.agency.name != entity.map.agencyGroup.name">{{ entity.map.agencyGroup.name }}</template>
						<br v-else />
					</v-row>
				</v-card-text>
				<v-card-text class="px-4 py-1" style="line-height: 21px"
					><br />
					<!-- <v-row no-gutters><b class="pr-1">State:</b> {{ entity.map.agency.state }}</v-row> -->
					<v-row no-gutters><b class="pr-1">Job title:</b> {{ entity.map.jobTitle }}</v-row>
					<v-row no-gutters><b class="pr-1">Mobile travel agent:</b> {{ entity.mobileAgent ? "Yes" : "No" }}</v-row>
				</v-card-text>
			</v-card>
			<!-- company details end -->
			<!-- account setting -->
			<v-card class="my-4 ml-2" elevation="0" rounded="lg" width="400px" height="272px">
				<v-row no-gutters style="height: 51px; padding-left: 5px; margin-bottom: 15px" align="center">
					<v-btn class="ml-1" fab color="#2d2926" elevation="0" height="43" width="43">
						<v-icon class="icon-profile-account" color="white" size="45"></v-icon>
					</v-btn>
					<v-toolbar-title class="box-title pl-2"> <strong class="pr-1">Account</strong>settings </v-toolbar-title>
					<v-spacer></v-spacer>
				</v-row>
				<v-card-text class="px-4 pt-2 d-flex flex-column">
					<p style="font-size: 14px; line-height: 17px; margin-bottom: 11px">
						Here’s where you can manage your <b>duo</b> account and review important information. If you require further support, please email <a href="mailto:duosupportnz@airnz.co.nz" style="font-size: 14px">duosupportnz@airnz.co.nz</a>
					</p>
					<br />
					<a href="#/profile/accountsetting" style="text-decoration: none !important" class="flex">
						<v-btn class="btn-view-setting" elevation="0" outlined color="#04A0A8" style="text-transform: none">View settings</v-btn>
					</a>
				</v-card-text>
			</v-card>
			<!-- account setting end -->
		</v-row>

		<!-- <v-row no-gutters justify="start" justify-lg="center"> -->
			<!-- account setting -->
			<!-- <v-card class="ma-4" elevation="0" rounded="lg" width="420px" min-height="251px" max-height="251px">
				<v-row no-gutters style="height: 51px; padding-left: 5px; margin-bottom: 15px" align="center">
					<v-btn class="ml-1" fab color="#2d2926" elevation="0" height="43" width="43">
						<v-icon class="icon-profile-account" color="white" size="45"></v-icon>
					</v-btn>
					<v-toolbar-title class="box-title pl-2"> <strong class="pr-1">Account</strong>settings </v-toolbar-title>
					<v-spacer></v-spacer>
				</v-row>
				<v-card-text class="px-4 pt-2 d-flex flex-column">
					<p style="font-size: 14px; line-height: 17px; margin-bottom: 11px">
						Here’s where you can manage your <b>duo</b> account and review important information. If you require further support, please email <a href="mailto:duosupportnz@airnz.co.nz" style="font-size: 14px">duosupportnz@airnz.co.nz</a>
					</p>
					<br />
					<a href="#/profile/accountsetting" style="text-decoration: none !important" class="flex">
						<v-btn class="btn-view-setting" elevation="0" outlined color="#04A0A8" style="text-transform: none">View settings</v-btn>
					</a>
				</v-card-text>
			</v-card> -->
			<!-- account setting end -->
			<!-- <v-card class="ma-4" elevation="0" rounded="lg" width="420px" min-height="251px" max-height="251px" style="opacity: 0">
			</v-card>
		</v-row> -->
	</v-container>
	<profile-mobile v-else></profile-mobile>
</template>

<style scoped>
.custom-appbar .v-toolbar__content,
.v-toolbar__extension {
	padding: 0 0 0 6px;
	height: 51px !important;
}
</style>
<style scoped>
.v-card__text {
	color: #2d2926 !important;
}
.btn-view-setting {
	width: 192.7px !important;
	height: 50px !important;
	border: 1px solid #00b0b9;
	border-radius: 4px;
	opacity: 1;
	font-size: 17px;
}
.text-name {
	font-size: 28px;
	display: block;
	max-width: 98%;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
}
.box-title {
	font-size: 18px !important;
}
.v-card {
	padding: 10px;
}
/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 80px;
		margin-left: 0%;
		margin-right: 0%;
	}
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "./subHeader.vue";
import profileMobile from "./mobile/profileMobile.vue"

export default {
	components: {
		subHeader,
		profileMobile
	},

	data() {
		return {
			entity: { map: { agency: {}, agencyGroup: {}, industryExperienceMonth: "", industryExperienceYear: "" } },
			industryExperience: "",
			joined: ""
		};
	},
	mounted: function () {
		this.profile();
	},
	methods: {
		profile() {
			axios
				.get("/system/person/me")
				.then((response) => {
					this.entity = response.data;
					this.parseIndustryExperience(this.entity.map);
				})
				.catch(() => {});
		},
		parseIndustryExperience(data) {
			if (data.industryExperienceYear > 0) {
				this.industryExperience = data.industryExperienceYear + (data.industryExperienceYear > 1 ? " years and " : " year and ") + data.industryExperienceMonth + (data.industryExperienceMonth > 1 ? " months" : " month");
			} else {
				if (data.industryExperienceMonth >= 1) {
					this.industryExperience = data.industryExperienceMonth + (data.industryExperienceMonth > 1 ? " months" : " month");
				}
			}
		}
	}
};
</script>
