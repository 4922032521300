<template>
  <div v-if="$vuetify.breakpoint.mdAndUp" style="padding-top: 12px">
    <subHeader />
  </div>
</template>

<script>
import subHeader from "./subHeader.vue";
export default {
  components: {
    subHeader
  },
  mounted: function () {
    window.scrollTo(0, 0);
  }
};
</script>
