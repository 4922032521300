<template>
	<v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
		<subHeader />
		<div class="seemoreofnz">
			<iframe
				:src="$window.location.origin.includes('test') || $window.location.origin.includes('localhost') ? 'https://duo-php-stage.pontoonplatform.com.au' : 'https://duo-php-live.pontoonplatform.com.au'"
				frameBorder="0"
			></iframe>
		</div>
	</v-container>

	<see-more-nz-mobile v-else></see-more-nz-mobile>
</template>

<style scoped>
.seemoreofnz {
	height: 100vh;
	width: 100%;
}
.seemoreofnz iframe {
	width: 100%;
	height: 100%;
	border: none;
}
</style>

<script>
import subHeader from "./subHeader.vue";
import seeMoreNzMobile from './mobile/seeMoreNzMobile.vue';

export default {
	components: {
		subHeader,
		seeMoreNzMobile
	},
	mounted: function () {
		window.scrollTo(0,0);
	},
};
</script>