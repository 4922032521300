<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1266px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img
            max-width="1266"
            max-height="345"
            style="background-size: cover; background-position: center center"
            :src="
              $bucket + '/image/dashboard-tiles/LasVegasWarrios.Landing.jpeg'
            "
          />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <h2>
            Warriors fans will have the exciting opportunity to fly non-stop to
            Las Vegas for the first time ever next year. Air New Zealand’s
            Grabaseat is launching a special one-off charter flight between
            Auckland and Las Vegas to support the team.
          </h2>
          <p style="padding-bottom: 1rem">
            The flight, named NZ1995 in honour of the Warriors’ inaugural year,
            is expected to be fully booked and will be hosted by Warriors
            superfans Dai Henwood and Ben Hurley. This direct service will
            depart for Las Vegas on 25 February 2025, returning to Aotearoa on 4
            March 2025.
          </p>
          <div style="padding: 2rem; background-color: #eaeaea">
            <v-row>
              <div class="col-9">
                <p style="font-size: 24px; font-weight: bold; margin-top: 0">
                  The package includes:
                </p>
                <ul class="pl-10">
                  <li>
                    Return flights from Auckland to Las Vegas with Air New
                    Zealand
                  </li>
                  <li>
                    Tickets to watch four back-to back rugby league matches (one
                    featuring the One New Zealand Warriors)
                  </li>
                  <li>Five nights’ accommodation at the Park MGM</li>
                  <li>Return airport transfers in Las Vegas</li>
                  <li>Welcome function</li>
                  <li>Activities and giveaways onboard</li>
                </ul>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 200px;
                "
              >
                <v-img
                  max-width="auto"
                  :src="
                    $bucket +
                    '/image/dashboard-tiles/LasVegasWarrios.LandingLogo.png'
                  "
                  style="
                    display: inline-block;
                    vertical-align: middle;
                    object-fit: contain !important;
                  "
                ></v-img>
              </div>
            </v-row>
          </div>
        </div>
      </v-row>

      <v-row>
        <div style="width: 1266px !important; padding-bottom: 1rem">
          <div style="padding: 2rem; background-color: #eaeaea">
            <v-row>
              <div class="col-12">
                <p style="font-size: 24px; font-weight: bold; margin-top: 0">
                  Package rates:
                </p>
                <ul class="pl-10">
                  <li>Economy from: $4999pp twin share</li>
                </ul>
              </div>
            </v-row>
          </div>
        </div>
      </v-row>
      <p>
        As an agent, we want you to be part of the action. Sell and book the
        above packages via our Grabaseat website and earn yourself a booking
        commission of $200 per person. You can choose between Prezzy Cards or
        Airpoints Dollars<span
          style="vertical-align: super; font-size: 10px; font-weight: bold"
          >TM</span
        >
        for your commission. Any agent that sells a package and registers it,
        also goes into the draw to win a jersey of their choice and other merch
        up to $300.
      </p>

      <p>
        Simply enter your booking references on <a style="color: black;" :href="$window.location.origin + '/#/sales'">duosales</a>, and we will ensure
        your commission if loaded or sent out!
      </p>

      <a
        style="font-size: 13px; color: black; font-style: italic"
        :href="$window.location.origin + '/#/page/LasVegasWarriors-TC'"
        target="_blank"
        >Click here for T&C's.</a
      >
    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    showPopup() {
      this.show = true;
      this.$root.saveActivityLog({
        type: "Visit_dashboard_tile",
        value: "Register WinTheWarriors",
      });
    },
    closePopup() {
      this.show = false;
    },
  },
};
</script>
