<template>
	<v-main style="margin:0 10px;">
		<div style="height:10px;"></div>
		<v-data-table :headers="fieldList" :items="trainingModuleList" sort-by="id" :search="keyword" :footer-props="{ itemsPerPageOptions: [50,100,500,-1] }">
			<template v-slot:top>
				<v-row no-gutters style="padding:20px 10px 15px;">
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<h2>{{ $root.capitalizeFirst(title) }} list</h2>
						</div>
					</v-col>
					<v-col cols="12" sm="6">
						<div class="d-flex px-2">
							<v-text-field v-model="keyword" append-icon="mdi-magnify" label="Search" hide-details style="margin-right:10px;"></v-text-field>
							<v-btn small fab dark color="secondary" title="Add" @click="showAddTrainingModule">
								<v-icon>mdi-plus</v-icon>
							</v-btn>
						</div>
					</v-col>
				</v-row>
			</template>
			<template v-slot:item.description="{ item }">{{ $root.ellipsify(item.description, 100) }}</template>
			<template v-slot:item.active="{ item }">
				<v-icon v-if="item.active === true" color="green" title="Active">mdi-check</v-icon>
				<v-icon v-else color="red" title="Inactive">mdi-block-helper</v-icon>
			</template>
			<template v-slot:item.action="{ item }">
				<div class="d-flex flex-row">
					<v-icon @click="$router.push('/administration/trainingModule/' + item.id + '/configuration')">mdi-clipboard-edit-outline</v-icon>
					<v-icon class="ml-2" @click="showUpdateTrainingModule(item)">mdi-bullseye-arrow</v-icon>
					<v-icon class="ml-2" @click="showEditTrainingModule(item)">mdi-pencil</v-icon>
					<v-icon class="ml-2" @click="showDeleteTrainingModule(item)">mdi-delete</v-icon>
				</div>
			</template>
			<template v-slot:no-data>Empty list</template>
		</v-data-table>

		<v-dialog v-model="dialogAdd" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Add {{ title }}</span>
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="trainingModule.name" label="Name"></v-text-field>
					<v-textarea v-model="trainingModule.description" label="Description" rows="3"></v-textarea>
					<v-switch v-model="trainingModule.active" label="Active"></v-switch>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="dialogAdd = false">Cancel</v-btn>
					<v-btn @click="addTrainingModule" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogEdit" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Edit {{ title }}</span>
				</v-card-title>
				<v-card-text>
					<v-text-field v-model="trainingModule.name" label="Name"></v-text-field>
					<v-textarea v-model="trainingModule.description" label="Description" rows="3"></v-textarea>
					<v-switch v-model="trainingModule.active" label="Active"></v-switch>
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="dialogEdit = false">Cancel</v-btn>
					<v-btn @click="editTrainingModule" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogDelete" max-width="600px">
			<v-card>
				<v-card-title>
					<span class="headline">Delete {{ title }}</span>
				</v-card-title>
				<v-card-text>Are you sure you want to delete '{{ trainingModule.name }}' ({{ trainingModule.id }})?</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn @click="dialogDelete = false">Cancel</v-btn>
					<v-btn @click="deleteTrainingModule" color="accent">Delete</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog v-model="dialogUpdate" max-width="400">
			<v-card class="mx-auto" max-width="400">
				<v-toolbar flat color="primary" dark>
					<v-btn icon @click="dialogUpdate = false">
						<v-icon>mdi-close</v-icon>
					</v-btn>
					<v-toolbar-title>Target results</v-toolbar-title>
				</v-toolbar>

				<v-card-text>
					<h2 class="text-h6 my-2">Choose state</h2>

					<v-chip-group v-model="trainingModule.states" column multiple>
						<v-chip :value="target" filter outlined v-for="target of targetList" :key="target" @update:active="true">{{ target }}</v-chip>
					</v-chip-group>
				</v-card-text>
				<v-card-actions class="pa-4">
					<v-spacer></v-spacer>
					<v-btn @click="updateTrainingModule" color="primary">Save</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-main>
</template>

<script>
/* eslint-disable no-unused-vars */
import axios from "axios";

export default {
	data: function() {
		return {
			targetList: ["NSW", "ACT", "QLD", "VIC", "SA", "TAS", "WA", "NT"],
			title: "training module",
			fieldList: [
				{
					text: "Id",
					value: "id",
					width: "10%"
				},
				{ text: "Name", value: "name", width: "30%" },
				{ text: "Description", value: "description" },
				{ text: "Active", value: "active", width: "10%" },
				{ text: "", value: "action", sortable: false }
			],
			keyword: "",
			trainingModuleList: [],
			trainingModule: {},
			dialogAdd: false,
			dialogEdit: false,
			dialogDelete: false,
			dialogUpdate: false
		};
	},
	mounted: function() {
		this.listTrainingModule();
	},
	methods: {
		listTrainingModule() {
			axios
				.get("/api/trainingModule/list/classic")
				.then(response => {
					this.trainingModuleList = response.data;
				})
				.catch(() => {});
		},
		showAddTrainingModule() {
			this.trainingModule = {};
			this.dialogAdd = true;
		},
		addTrainingModule() {
			axios
				.post("/api/trainingModule/add", { trainingModule: this.trainingModule })
				.then(() => {
					this.dialogAdd = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},
		showEditTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogEdit = true;
		},
		editTrainingModule() {
			axios
				.post("/api/trainingModule/edit", { trainingModule: this.trainingModule })
				.then(() => {
					this.dialogEdit = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},
		showDeleteTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogDelete = true;
		},
		deleteTrainingModule() {
			axios
				.post("/api/trainingModule/delete", { id: this.trainingModule.id })
				.then(() => {
					this.dialogDelete = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		},
		showUpdateTrainingModule(item) {
			this.trainingModule = this.$root.clone(item);
			this.dialogUpdate = true;
		},
		updateTrainingModule() {
			axios
				.post("/system/trainingModule/state/edit", { trainingModule: this.trainingModule })
				.then(() => {
					this.dialogUpdate = false;
					this.listTrainingModule();
				})
				.catch(() => {});
		}
	}
};
</script>