<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <h2 class="d-flex justify-center pb-8">
        Air New Zealand Honolulu Book and Win Terms and Conditions – Be in to win flights to Hawaii!
      </h2>
      <v-row>
        <v-col cols="12" class="pa-0">
          <ol>
            <li>
              Information on how to enter the promotion forms part of the conditions of entry. Entry into the promotion is deemed as acceptance of its terms and conditions. 
            </li>
            <li>
              Entry is open to all leisure-based Travel Agents and Brokers who are New Zealand-based and IATA-accredited or part of the Travel Industry Designated Service only. The Promoters reserve the right to verify the validity of entries.
            </li>
            <li>
              Air New Zealand (the “Promoter”) employees and their immediate families, participating agents and their associated agencies are ineligible to enter. The Promoter reserves the right to verify the validity of entries. 
            </li>
            <li>
              <b>Conditions of Entry:</b><br>
              How to Enter: <br>
              Participants must visit <a href="https://duo.airnzagent.co.nz/#/login" target="_blank">duo.airnzagent.co.nz</a> and log their Premium Economy and Business Class bookings via duosales. Participants must log sales to Hawaii only on 086 ticket stock on NZ metal. <br>
              Agents are not permitted to ‘pool’ sales and/or log sales under their name that were issued by another agent. 
            </li>
            <li>
              Participation in the Promotion is unlimited into the prize draw per person provided all conditions of entry are met. 
            </li>
            <li>
              Promotion commences 6.00am 26 August 2024 and closes 11.59pm 11 September 2024 (the “<b>Promotional Period</b>”). Entries received after this closing time will be ineligible for entry.
            </li>
            <li>
              The prize details for the promotion include: <br>
              <b>Grand Prize Draw</b>
              <ul>
                <li>The two first valid entries drawn from the Grant Prize Draw will win the grand prize of: </li>
                <ul>
                  <li>    Return Premium Economy flights for two (2) adults (travelling together) from any Air New Zealand serviced domestic airport to Honolulu, Hawaii, flying Air New Zealand. </li>
                </ul>
              </ul>
              The two agents with the highest number of bookings logged via duosales <b>in Premium Economy or Business Premier will win the grand prize</b>. <br>
              The winners will be drawn from one prize pool on at the Aloha Down Under Events. One winner will be announced at Aloha Down Under in Auckland, Tuesday 17th September 2024, and one winner will be announced at Aloha Down Under in Wellington, Monday 16th September 2024. Attempts will be made to notify winners by phone at the contact telephone number provided on the promotion entry. If the Promoter, having used reasonable efforts, is unable to contact the winners between 16th and 17th September, then the prize is forfeited and a further draw(s) from that prize pool will be conducted. The Promoter's decision is final, and no correspondence will be entered into.
            </li>
            <li>
              The winners are responsible for paying all additional costs associated with the prize that are not specifically included (as stated in clause 7), including (where applicable) airport taxes, transport, transfers, meal costs, spending money, insurance and all other incidentals. The winners of the Grand Prize are also responsible for all necessary travel documents, including valid travel identification, visas, travel insurance and a passport validity.
            </li>
            <li>
              Travel is subject to A class availability which will be assessed for seat confirmation inside 60 days from departure date. Travel must be commenced and completed by 17th of September 2025. The winner and guest if applicable must travel together. All ticket taxes, airport and government costs are at the traveller’s expense. The prize winners must be a full-time travel agent at the time of travel. Travel is on Air New Zealand (086) operated services only. Travel during New Zealand Public or School Holidays is not permitted. Stopovers are not permitted. Once ticketed, any changes will be at the traveller’s own expense and is subject to the terms and conditions of the fare used.
            </li>
            <li>
              The prizes cannot be converted into cash and are not transferable. 
            </li>
            <li>
              The Promoter accepts no responsibility for any variation in prize value.
            </li>
            <li>
              The Promoter reserves the right to substitute the prize in whole (or any of its components), with a substitute prize of equal or greater value.
            </li>
            <li>
              All prize travel will be subject to Air New Zealand’s Conditions of Carriage, to view visit <a href="https://airnewzealand.co.nz/conditions-of-carriage" target="_blank">www.airnewzealand.co.nz/conditions-of-carriage</a>. Prize travel will not be eligible to accrue Airpoints Dollars™ or Status Points and cannot be used in conjunction with Air New Zealand’s Airpoints™ Programme or any other carriers’ frequent flyer schemes.
            </li>
            <li>
              The Promoter assumes no responsibility for any error, omission, interruption, deletion, defect, delay in operation or transmission, communications line failure, theft or destruction or unauthorised access to or alteration of entries. The Promoter assumes no responsibility for any injury or damage to participants or any other person’s computer related to or resulting from participation in or downloading any materials in this promotion. Entries into this promotion will be stored only in the Air New Zealand booking database.
            </li>
            <li>
              Neither the Promoter nor any other person or party associated with this promotion, shall be liable for any loss or damage whatsoever suffered (including but not limited to indirect or consequential loss) or personal injury suffered or sustained in connection with either participation in this promotion or with any prizes offered. 
            </li>
            <li>
              The Promoter collects entrants’ personal information in order to conduct the promotion. If the information requested is not provided, the entrant may not participate in the promotion. Acceptance of the prize is deemed consent for the Promoter to use the winner’s details and photographs for promotional and media purposes without any further reference, payment or other compensation to the entrant. Please refer to Air New Zealand’s Privacy Statement at <a href="https://www.airnewzealand.co.nz/privacy" target="_blank">https://www.airnewzealand.co.nz/privacy</a> regarding the collection, use, disclosure and storage of personal information. 
            </li>
            <li>
              The Promoter of this competition is Air New Zealand Limited, Private Bag 92007, Auckland, New Zealand. 
            </li>
            <li>
              If any queries, please contact the Promoter at <a href="mailto:duosupportnz@airnz.co.nz" target="_blank">duosupportnz@airnz.co.nz</a> 
            </li>

          </ol>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.video-title {
  font-size: 28px;
  font-family: "AirNZ Sans Semibold";
  float: left;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
@counter-style list-style-custom {
  system: extends lower-alpha;
  suffix: '). ';
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
};
</script>
