<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 10px">
          <div
            :style="{
              height: '476px',
              maxWidth: '1271px',
              backgroundImage:
                'url(' + $bucket + '/image/dashboard-pages/COP_Banner.jpg',
              backgroundSize: 'cover',
            }"
          >
            <div class="banner-title white--text">
              <span>Cabin of Possibility</span>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <div class="pa-6" style="max-width: 1138px">
          <h3>
            Experience the best sleep in the sky with our future aircraft cabin
            experience
          </h3>
          <p>
            We’re excited to unveil Air New Zealand’s Cabin of Possibility. Our
            new Dreamliners, due to arrive in 2024, will give customers more
            choice than any airline in the world, providing the best sleep in
            the sky regardless of the cabin customers choose to fly in.
          </p>
          <p>
            This includes a new Business Premier Luxe seat, designed for
            customers looking for more space and privacy, and SkynestTM*, the
            world’s first sleep pods in the sky for Economy travellers.
          </p>
          <p>
            The feedback from extensive customer research over five years has
            highlighted the importance of a good night’s sleep and the need for
            more space and comfort, so the new experience is designed around
            creating a home away from home that leaves customers refreshed and
            raring to go at their destination.
          </p>
          <p>
            Innovation has driven the new cabin experience from nose to tail.
            For Economy customers, the Skynest* concept, first announced in
            2020, will be a world-first.
          </p>
        </div>
      </v-row>

      <v-row class="px-6" style="padding-bottom:6px;">
        <h3>The Cabin of Possibility</h3>
      </v-row>

      <v-row v-for="item in contents" :key="item.title">
        <div v-if="item.image" class="pa-4 pl-0">
          <img
            style="max-height: 269px; max-width: 310px"
            :src="$bucket + '/image/dashboard-pages/' + item.image"
          />
        </div>
        <div
          v-if="item.image"
          class="d-flex flex-column justify-center px-4 pb-11"
        >
          <div class="pb-3">
            <h1>
              {{ item.title }}
              <sup v-if="item.titleSup">{{ item.titleSup }}</sup>
            </h1>
          </div>
          <div
            style="padding-top: 2px"
            class="pr-8"
            v-html="item.description"
            :style="{ maxWidth: item.image ? '828px' : '1190px' }"
          />
        </div>
        <div
          v-else
          class="d-flex flex-column justify-center px-0"
          style="padding:bottom: 21px;"
        >
          <div class="pb-3">
            <h1>
              {{ item.title }}
              <sup v-if="item.titleSup">{{ item.titleSup }}</sup>
            </h1>
          </div>
          <div
            style="padding-top: 2px"
            v-html="item.description"
            :style="{ maxWidth: item.image ? '828px' : '1190px' }"
          />
        </div>
      </v-row>

      <v-row class="mb-10">
        <p>
          <b
            >To find out more about Air New Zealand’s new Inflight Cabin
            Experience, watch the below video.</b
          >
        </p>
      </v-row>

      <v-row class="mt-0">
        <iframe
          width="1180"
          height="592"
          src="https://www.youtube.com/embed/YOle0e1nZsk"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </v-row>
    </v-container>
  </v-container>

  <cabin-of-possibility-mobile v-else></cabin-of-possibility-mobile>
</template>

<style scoped>
.banner-title {
  padding-top: 146px;
  height: 90px;
  font-size: 90px;
  padding-left: 60px;
  font-family: "AirNZ Black Italic";
  text-shadow: 0px 4px 6px #000000e3;
}

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 35px;
  font-weight: normal;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

sup {
  font-size: 50% !important;
  margin: -5px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeader from "./subHeader.vue";
import cabinOfPossibilityMobile from './mobile/cabinOfPossibilityMobile.vue';

export default {
  components: {
    subHeader,
    cabinOfPossibilityMobile,
  },
  mounted: function () {
    window.scrollTo(0, 0);
  },
  data: function () {
    return {
      contents: [
        {
          title: "Business Premier Luxe",
          titleSup: "TM",
          image: "COP_BusinessPremierLuxe.jpg",
          description:
            "<p>The best sleep in the sky. Our new offering is for customers looking for the ultimate space and privacy. A luxury experience with all the features of Business Premier, but with a fully closing door and space for two to dine.</p>",
        },
        {
          title: "Business Premier",
          titleSup: "TM",
          image: "COP_BusinessPremier.jpg",
          description:
            "<p>A comfortable and private nest for a blissful journey and tranquil sleep. And if travelling with a companion, the middle row allows customers to open their nest and share their experience.</p>",
        },
        {
          title: "Premium Economy",
          image: "COP_PremiumEconomy.jpg",
          description:
            "<p>For the treat-seekers looking for a little luxury to unwind and enjoy a taste of Aotearoa. Our new seat offers more privacy and protected space where you can recline at leisure without interrupting the person behind.</p>",
        },
        {
          title: "Economy Skynest",
          titleSup: "TM*",
          image: "COP_EconomySkynest.jpg",
          description:
            "<p>It’s time to swap the headrest for some bedrest. Say hello to the world’s first sleep pods in the sky, Skynest. Stretch out in one of six pods for part of your journey and catch some shut eye. A game changer for economy travellers.</p>",
        },
        {
          title: "Economy Skycouch",
          titleSup: "TM",
          description:
            "<p>Use the Skycouch the way you want. Sit, spread out, or lie down and snooze. Share the space or keep it all to yourself.</p>",
        },
        {
          title: "Economy Stretch",
          titleSup: "TM",
          description:
            "<p>It’s all in the name. This seat is for those who want to rest and stretch their legs further than the regular Economy seat.</p>",
        },
        {
          title: "Economy Seat",
          description:
            "<p>An enhanced economy seat designed with more storage, comfort and space and a 50% bigger screen for entertainment. Connect to Bluetooth audio and pair your device to act as a remote control or second screen.</p>",
        },
      ],
    };
  },
};
</script>