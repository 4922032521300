<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    <!-- Top Header -->
    <subHeader />

    <!-- Content Page -->
    <v-container class="ma-0" style="height: 70px; padding: 0 0 0 0;">
      <h2 class="mb-0 bdm-text-bold"><b>Meet our network</b></h2>
      <p class="duo pt-4">
        With a strong network of sales managers, retail & account managers & training managers across New Zealand, we’re here to support you.
      </p>
    </v-container>

    <!-- Tab Header BDM -->
    <subHeaderBdm v-model="selectedTab" />

    <!-- Sales Managers Sections -->
    <v-row v-if="selectedTab == 1" no-gutters class="d-flex flex-row">
      <bdmTiles v-if="!$root.isBlank(nonMapStatesData)" :statesData="nonMapStatesData" :selectedStates="salesManagerStates" :isResources="false" :isNonMapBdm="true" key="bdmTilesKey0" />
    </v-row>

    <!-- Retail & Account Managers Sections -->
    <v-row v-if="selectedTab == 0" no-gutters class="d-flex flex-row">
      <v-spacer></v-spacer>
      <!-- SVG MAP -->
      <div class="map">
        <v-row class="mt-2" align="center" justify="center">
          <radio-svg-map
            class="radio-map"
            v-model="selectedLocation"
            @click="pointLocation"
            @mouseout="unpointLocation"
            @mousemove="moveOnLocation"
            :map="NewZealand"
          />
        </v-row>
      </div>

      <v-spacer></v-spacer>
      <bdmTiles :statesData="mapStatesData" :selectedStates="selectedMapStates" :isResources="false" key="bdmTilesKey1" />
    </v-row>

    <!-- Training & Engagement Sections -->
    <v-row v-if="selectedTab == 2" no-gutters class="d-flex flex-row">
      <bdmTiles v-if="!$root.isBlank(nonMapStatesData)" :statesData="nonMapStatesData" :selectedStates="trainingEngagementStates" :isResources="false" :isNonMapBdm="true" key="bdmTilesKey2" />
    </v-row>
  </v-container>

  <bdm-profile-mobile v-else></bdm-profile-mobile>
</template>

<style scoped>
div.bdm {
  border-left: 2px solid #2d2926;
  width: 445px;
}
.radio-map {
  /* max-width: 60%; */
  max-height: 79vh;
}
.profile {
  width: 3em !important;
  height: 3em !important;
}
.v-card {
  background: hsla(0, 0%, 100%) !important;
  border-radius: 4px !important;
}
.v-system-bar {
  background: none;
}
.v-list {
  background: none;
}
.v-list-item {
  background: white;
  padding-left: 20px;
  margin-bottom: 2px;
}
.bdm-text {
  font-size: 14px;
  background-color: white;
  padding: 20px 25px;
}
.bdm-action {
  width: 100%;
  background-color: white;
  padding: 0;
  padding-bottom: 10px;
  border-color: white;
}
.bdm-profile {
  height: 78vh;
  overflow-y: scroll; /* Add the ability to scroll */
}
/* Hide scrollbar for Chrome, Safari and Opera */
.bdm-profile::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.bdm-profile {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.bdm-sm-name {
  max-height: 50px;
  border-radius: 4px;
  padding-bottom: 10px;
  padding: 5px 20px 15px 0;
}
/* Small devices (desktops, 600px > < 960px*) */
@media (max-width: 600px) {
  .bdm-sm-name {
    max-height: 70px;
    border-radius: 4px;
    padding-left: 10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px 20px 15px 20px;
  }
}
/* Medium devices (desktops, 960px > < 1264px*) */
@media (min-width: 960px) {
  div.map {
    width: 48%;
  }
}
/* Large devices (desktops, 1264px > < 1600px*) */
@media (min-width: 1264px) {
  div.map {
    width: 50%;
  }
}
/* Extra large devices (> 1600px*) */
@media (min-width: 1600px) {
  div.map {
    width: 70%;
  }
  .radio-map {
    /* max-width: 60%; */
    max-height: 79vh;
  }
  .bdm-profile {
    height: 84vh;
    overflow-y: scroll; /* Add the ability to scroll */
  }
}
/* .bdm-firstname {
	padding-bottom: 12px;
	border-bottom: 4px solid rgb(0, 0, 0);
} */
.horizontal-line {
  width: 50px;
  padding-bottom: 12px;
  border-bottom: 4px solid rgb(0, 0, 0);
}
button.get-in-touch {
  letter-spacing: 0px;
  color: white;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
  text-transform: none !important;
  min-width: 163px !important;
  max-width: 163px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 4px !important;
  margin: 10px 25px;
  /* width: 150px; */
}

button.bdm-introduction {
  letter-spacing: 0px;
  color: black;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
  text-transform: none !important;
  min-width: 190px !important;
  max-width: 190px !important;
  min-height: 50px !important;
  max-height: 50px !important;
  border-radius: 4px !important;
  margin: 10px 25px;
  /* width: 150px; */
}
/* button.get-in-touch:hover {
	font-size: 17px !important;
	background-color: #ffffff !important;
	color: #2d2926 !important;
	text-transform: none !important;
	min-width: 193px !important;
	max-width: 193px !important;
	min-height: 50px !important;
	max-height: 50px !important;
	border-radius: 4px !important;
} */
</style>

<!-- Do not set as scoped -->
<style>
.svg-map {
  width: 78%;
  height: auto;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  /* stroke: rgba(209, 207, 207, 0.5); */
}
.svg-map__location {
  fill: white;
  fill-opacity: 0.8;
  cursor: pointer;
}
.svg-map__location:focus,
.svg-map__location:hover {
  fill: #2d2926;
  outline: 0;
  fill-opacity: 1;
}
.svg-map__location[aria-checked="true"] {
  fill: #2d2926;
  fill-opacity: 1;
}
.v-btn {
  margin-right: 10px !important;
}

/* Small devices (desktops, 600px > < 960px*) */
@media (max-width: 600px) {
  .bdm-sm-name {
    max-height: 70px;
    border-radius: 4px;
    padding-left: 10px;
    padding-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px 20px 15px 20px;
  }
}

/* Medium devices (desktops, 960px > < 1264px*) */
@media (min-width: 960px) {
  div.map {
    width: 48%;
  }
}

/* Large devices (desktops, 1264px > < 1600px*) */
@media (min-width: 1264px) {
  div.map {
    width: 50%;
  }
}

/* Extra large devices (> 1600px*) */
@media (min-width: 1600px) {
  div.map {
    width: 70%;
  }
  .radio-map {
    /* max-width: 60%; */
    max-height: 79vh;
  }
  .bdm-profile {
    height: 84vh;
    overflow-y: scroll; /* Add the ability to scroll */
  }
}

.bdm-profile-text-header {
  letter-spacing: 0px;
  color: #2d2926;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 18px;
  font-family: AirNZ Sans Semibold !important;
  opacity: 1;
}
.bdm-profile-text-subtitle {
  letter-spacing: 0px;
  color: #2d2926;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 18px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
}
.bdm-dialog-name {
  letter-spacing: 0px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 28px !important;
  line-height: 27px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
}
.bdm-territory {
  letter-spacing: 0px;
  color: #ffffff;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 27px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
}
.bdm-contact {
  letter-spacing: 0px;
  color: #2d2926;
  text-align: left;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 18px !important;
  line-height: 33px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  text-decoration: underline;
  /* border-bottom: 1px solid #2D2926; */
  padding-bottom: 0px;
  white-space: pre-wrap;
}
.bdm-booking {
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  font-size: 17px !important;
  line-height: 21px;
  font-family: AirNZ Sans Regular !important;
  opacity: 1;
  white-space: pre-wrap;
}
.bdm-text-bold {
  font-family: "AirNZ Sans Semibold";
  font-size: 24px;
  line-height: 21px;
}
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
// import axios from "axios";
import subHeader from "./subHeader.vue";
import subHeaderBdm from "./subHeaderBdm.vue";
import bdmTiles from "./bdmTiles.vue";
import bdmData from './bdmData.vue';
import { RadioSvgMap } from "vue-svg-map";
// npm i @svg-maps/new-zealand, then copy from node_modules to ../../public/map/ folder
// edit .svg with inkscape then copy data on path to index.js 
// I mean copy all numbers that contains m and z, I assume you already union the path before copy, how: Go to Layer and Objects -> select path -> Path tab -> Union
import NewZealand from "../../public/map/new-zealand"; // referencing index.js not new-zealand.svg
import bdmProfileMobile from "./mobile/bdmProfileMobile.vue"

export default {
  components: {
    subHeader,
    subHeaderBdm,
    bdmTiles,
    RadioSvgMap,
    bdmProfileMobile,
  },
  data() {
    return {
      NewZealand,
      selectedTab: 0,
      selectedLocation: null,
      pointedLocation: null,
      tooltipStyle: { display: "none" },
      stateList: bdmData.data().stateList,
      mapStatesData: [],
      selectedMapStates: [],
      // retailAccountManagersStates: ["national", "northnz", "southnz"],

      nonMapStatesData: [],
      salesManagerStates: ['unknown-sales-manager'],
      trainingEngagementStates: ['unknown-training-engagement'],
    };
  },
  watch: {
    selectedTab: 'getNonMapData'
  },
  mounted: function () {
    this.getNonMapData();
    window.scrollTo(0, 0);
  },
  methods: {
    getLocationId(node) {
      return node && node.attributes.id.value;
    },
    getLocationName(node) {
      return node && node.attributes.name.value;
    },
    pointLocation(event) {
      //   this.pointedLocation = this.getLocationName(event.target);
      this.selectedMapStates = this.getLocationId(event.target).split("&");
      this.mapStatesData = this.stateList.filter(
        (item) => item.state === this.getLocationId(event.target)
      );
    },
    unpointLocation() {
      this.pointedLocation = null;
      this.tooltipStyle = { display: "none" };
    },
    moveOnLocation(event) {
      this.tooltipStyle = {
        position: "fixed",
        display: "block",
        top: `${event.clientY + 10}px`,
        left: `${event.clientX + 10}px`,
        backgroundColor: "white",
        padding: "10px",
        zIndex: 5,
      };
    },
    getNonMapData() {
      if (this.selectedTab === 1) {
        this.nonMapStatesData = this.stateList.filter(
          (item) => this.salesManagerStates.includes(item.state)
        );
      } else if (this.selectedTab === 0) {
        this.mapStatesData = this.stateList.filter(
          (item) => item.state.includes("northnz")
        );
        this.selectedMapStates = ["northnz"];
        this.selectedLocation = 'northnz';
      } else if (this.selectedTab === 2) {
        this.nonMapStatesData = this.stateList.filter(
          (item) => this.trainingEngagementStates.includes(item.state)
        );
      }
    },
    scrollToTop() {
      this.$refs.bdm.scrollTop = 0;
    },
  },
};
</script>

