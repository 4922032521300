<!-- eslint-disable no-console -->
<template>
  <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
    
    <subHeader />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1266" max-height="345" style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-tiles/Korea.Landing.jpeg'" />
        </v-col>
      </v-row>
      <v-row>
        <div style="width: 1266px !important;">
          <h2 style="padding-bottom: 1rem;">
            Air New Zealand and Korea Tourism Organization are giving you and a companion the chance to win a trip to Seoul!
          </h2>
          
          <div class="pb-8">
            <p>There are 1 of 4 prizes to be won, simply: </p>
            <ul>
              <li>Make a booking to South Korea between 6 August - 23 August 2024 for travel anytime on Air New Zealand (086) ticket stock.</li>
              <li>Enter your Air New Zealand booking references through <a :href="$window.location.origin + '/#/sales'">Duosales</a> to go in the draw to win a trip of a lifetime!</li>
              <li>Complete the Discover South Korea training module <a :href="$window.location.origin + '/#/training/module/' + this.tileDiscoverKorea.link + '/'">here</a></li>
            </ul>
          </div>
          
          <div style="padding: 2rem 0 2rem 3rem; background-color:#ffffff;">
            <v-row>
              <div>
                <p style="font-size: 24px; font-weight: bold; margin-top: 0;">Prize include: </p>
                <ul>
                  <li>Economy return flights for two from Auckland to Seoul with domestic connection options available for those travelling across New Zealand.</li>
                  <li>A 4-night Seoul City Stay for two, twin share</li>
                  <li>Airport Transfers in Seoul</li>
                  <li>A guided city tour including Gyeongbok Palace, Gwangjang Market and N Seoul Tower</li>
                </ul>
              </div>
            </v-row>
          </div>

          <div>
            <p style="font-weight: bold;">More of a learner? Don’t worry, there are bonus prizes too for watching the KTO webinar series!</p>
            <p>Simply complete the webinar module below on you’ll be in to win 1 of 3 lots of $100 prezzy cards!</p>
            <p style="font-style: italic;" ><a :href="$window.location.origin + '/#/page/KTO-TC'" style="font-size: 12px; color: black">Terms and Conditions apply</a></p>
          </div>
        </div>
      </v-row>

      <v-row class="py-1">
        <v-col cols="12" :md="8" class="px-0 pr-2">
          <v-row no-gutters
            :style="{ minHeight: '369px', backgroundColor: '#221551', color: 'white' }">
            <v-col cols="12" :md="6">
              <v-card class="pt-2"
                :color="'duoQuaternary'" tile
                min-height="376" elevation="0">
                <div class="d-flex align-center pl-6" style="width: 100%; height: 58px; font-size: 28px">
                  <div class='d-flex flex-column white--text'>                    
                    <span class="title-discover" style="font-size: 28px; line-height: 1.2; color: white !important;" v-html="this.tileDiscoverKorea.title"></span>
                    <span class="duo-underline mt-0"></span>
                  </div>
                  <v-spacer />
                </div>
                <v-card-text class="ma-1">
                  <v-row>
                    <v-col cols="12" class="pl-3">
                      <v-row align="center">
                        <v-col cols="auto" class="pl-3">
                          <v-avatar size="76"
                            :color="'white'">
                            <v-icon class="duo-icon-training"
                              :color="'duoQuaternary'"
                              size="32"></v-icon>
                          </v-avatar>
                        </v-col>
                        
                      </v-row>
                      <v-row>
                        <v-col cols="12" class="pl-4 pr-12">
                          <span
                            :class="'duo-description white--text'"
                            v-html="this.tileDiscoverKorea.content"></span>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" class="duo-description pl-3 pr-6">
                      <span
                        :style="{ color: 'white' }"></span>
                    </v-col>
                  </v-row>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn class="'duo-text-button elevation-0 '" width="193px" height="50px"
                        :style="{ fontSize: '17px', color: '#2d2926' }" :color="'white'"
                        @click="openSameTab('/#/training/module/' + tileDiscoverKorea.link + '/')" absolute bottom light>{{ this.tileDiscoverKorea.buttonLabel }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" md="6" :style="{
              minHeight: '376px',
              backgroundImage:
                'url(' + this.tileDiscoverKorea.image + ')',
              backgroundColor: '#2d2926',
              backgroundSize: 'cover',
              position: 'relative',
              backgroundPosition: '50% 50%',
            }">
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="4" class="px-0">
          <v-row no-gutters :style="{ minHeight: '369px', backgroundColor: '#221551', color: 'white' }">
            <v-col cols="12">
              <v-card :color="'duoQuaternary'" tile min-height="376" elevation="0">
                <v-col cols="12" :md="12" :style="{ minHeight: '220px', backgroundImage: 'url(' + this.tileKoreaWebinar.image + ')', backgroundColor: '#2d2926', backgroundSize: 'cover', position: 'relative' }">
                </v-col>
                <div class="d-flex align-center pl-4 pt-8" style="width: 100%; height: 58px; font-size: 28px">
                  <div :class="'d-flex flex-column white--text'">
                    <span v-html="this.tileKoreaWebinar.title"></span>
                  </div>
                  <v-spacer />
                </div>
                <v-card-text>
                  <v-row no-gutters>
                    <v-col cols="12">
                      <v-btn class="'duo-text-button elevation-0 '" width="193px" height="50px"
                        :style="{ fontSize: '17px', color: '#2d2926' }" :color="'white'"
                        @click="openSameTab('/#/training/module/'+ tileKoreaWebinar.link + '/')" absolute bottom light>{{ this.tileKoreaWebinar.buttonLabel }}</v-btn>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

    </v-container>
  </v-container>
</template>

<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-size: 17px;
  padding-bottom: 1rem;
}

.title-discover >>> p {
  margin-bottom: 0.5rem
}
</style>

<script>
import axios from "axios";
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  data() {
    return {
      tileDiscoverKorea: {},
      tileKoreaWebinar: {}
    };
  },
  mounted: function () {
    this.getTrainingModule();
    window.scrollTo(0, 0);
  },
  methods: {
    openSameTab(url) {
      // eslint-disable-next-line no-console
      console.log(url)
      window.open(url, '_self')
    },
    openNewTab(url) {
      window.open(url, '_blank')
    },
    getTrainingModule() {
      axios
        .get("/system/tile/training")
        .then((response) => {
          this.tileDiscoverKorea = response.data.filter(v => v.id == 149023)[0]
          this.tileKoreaWebinar = response.data.filter(v => v.id == 149024)[0]
          
          // eslint-disable-next-line no-console
          console.log(this.tileDiscoverKorea, this.tileDiscoverKorea.link)
          // eslint-disable-next-line no-console
          console.log(this.tileKoreaWebinar, this.tileKoreaWebinar.link)
        });
    },
  }
};
</script>