<template>
  <div style="padding-top: 12px">
    <subHeaderMobile />

    <v-container style="border: 1px solid #221651 !important;" class="mb-2">
			<div class="d-flex justify-center align-center">
        <div>
          <span style="color: #221651 !important;">For the best Training Experiences,</span> <br />
          <span style="color: #221651 !important;">please use your desktop or laptop.</span>
        </div>
			</div>
		</v-container>

    <v-container fluid style="max-width: 1296px; padding-top: 0px" class="ma-0 pa-0">
      <!-- row 2 -->
      <v-row no-gutters>
        <duo-training-tile-mobile @onClickEdit="showEditDialog(item)" :map="item.map"
                        @onClickHide="showDialogConfirmation('unpublish', item.id)"
                        v-for="item in tileList" :img="item.image" :title="item.title" :content="item.content"
                        :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :link="item.link"
                        :status="getModuleStatus(item.link)" @onClickButton="onStartTraining(item.link)" />
      </v-row>
      <!-- row 4 end -->


      <v-dialog v-model="dialogRetake" max-width="400">
        <v-card class="mx-auto" max-width="400">
          <v-toolbar flat color="#221551" dark>
            <v-toolbar-title>{{moduleStatus == 'Resume' ? 'Resume' : 'Finish'}}</v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <h2 class="text-h6 my-4">{{ moduleStatus == 'Resume' ? 'You haven\'t finished this training yet' : 'You have finished this training' }}</h2>
            <h2 class="text-h6 my-4" v-if="moduleStatus != 'Resume' && showScore">Your Score: {{ finalScore }}</h2>
          </v-card-text>

          <v-card-actions class="d-flex justify-space-between pb-6">
            <v-col cols="6" class="my-0 py-0">
              <v-btn @click="handleRetakeModule()" class="duo-primary" style="width: 100% !important;" block>Retake</v-btn>
            </v-col>
            <v-col cols="6" class="my-0 py-0">
              <v-btn @click="handleReviewModule()" class="duo-secondary" style="width: 100% !important;" block>{{moduleStatus == 'Resume' ? 'Resume' : 'Review'}}</v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
/* eslint-disable no-console */
import subHeaderMobile from "../mobile/subHeaderMobile.vue";
import duoTrainingTileMobile from "../mobile/duoTrainingTileMobile.vue";
import axios from "axios";


export default {
  components: {
    subHeaderMobile,
    duoTrainingTileMobile
  },
  mounted: function () {
    window.scrollTo(0, 0);
    this.getList();
    if (this.$store.state.principal.administrator == false) {
      this.listCompletedModule();
    }
  },
  data() {
    return {
      retakeModule: false,
      dialogRetake: false,
      moduleId: 0,
      moduleStatus: "",
      finalScore: 0,
      showScore: false,
      tileList: [],
      completedModuleList: [],
    };
  },
  methods: {
    getList() {
            axios
                .get("/system/tile/training")
                .then((response) => {
                    this.tileList = response.data.filter(function (el) {
                        return el.sequence;
                    })
                })
                .catch(() => { });
    },
    listCompletedModule() {
      axios
        .get("/system/score/completed")
        .then((response) => {
          this.completedModuleList = response.data;
        }).catch(() => { });
    },
    onStartTraining(id) {
      this.moduleId = id;
      this.checkPersonScore();
    },
    handleRetakeModule() {
      this.$store.state.retakeTrainingModule = true;
      this.$router.push(`/training/module/${this.moduleId}/`);
    },
    handleReviewModule() {
      this.$store.state.retakeTrainingModule = false;
      this.$router.push(`/training/module/${this.moduleId}/`);
    },
    checkPersonScore() {
      axios
        .get("/system/score/answer?moduleId=" + this.moduleId)
        .then((response) => {
          if (response.data != null) {
            this.finalScore = response.data.finalScore;
            this.moduleStatus = this.getModuleStatus(response.data.moduleId);
            this.dialogRetake = true;
            this.moduleId && this.getModulePageQuiz(this.moduleId);
          } else {
            this.$router.push(`/training/module/${this.moduleId}/`);
          }
        })
        .catch(() => { });
    },
    getModuleStatus(moduleId) {
      let result = this.completedModuleList.find(score => score.moduleId === moduleId);
      if (result != null) {
        return result.transitMap.scoreStatus;
      } else {
        return null;
      }
    },
    getModulePageQuiz(id) {
      if (id !== undefined) axios.get(`/system/page/module/list?moduleId=${id}`).then((response) => {
        this.showScore = response.data.some((v) => v.name.includes('choice') || v.name.includes('answer'))
      });
    },
  },
};
</script>

<style scoped>
.btn-dashboard {
  width: 192px !important;
  height: 50px !important;
  border: 1px solid #2d2926 !important;
  background-color: #2d2926 !important;
  color: white !important;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0px;
}

.btn-dashboard-inverse {
  width: 192px !important;
  height: 50px !important;
  border: 1px solid white !important;
  background-color: white !important;
  color: #2d2926 !important;
  border-radius: 4px;
  opacity: 1;
  letter-spacing: 0px;
}
</style>
