<template>
  <v-main style="margin: 10px">
    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-row no-gutters style="padding: 20px 10px 15px">
        <v-col cols="12" sm="8">
          <div class="d-flex px-2">
            <h2>Slot Report</h2>
          </div>
        </v-col>
      </v-row>

      <!-- Slot Report -->
      <v-card class="d-flex flex-column align-content-center mt-2" min-height="155">
        <v-card-text>
          <v-form ref="form2" no-gutters class="d-flex flex-wrap">
            <v-row>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateSlotStartPicker" v-model="dateSlotStartMenu" :close-on-content-click="false"
                  :return-value.sync="dateSlotStart" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateSlotStart" :v-model="dateSlotStart"
                      :value="$root.parseDate(dateSlotStart)" readonly v-on="on" v-bind="attrs"
                      :rules="[(v) => !!v || 'Enter start date']" @click:prepend="dateSlotStartMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">Start date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateSlotStart" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateSlotStartPicker.save(dateSlotStart)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateSlotStartMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3" class="date-block ma-2 px-5" style="min-width: 200px">
                <v-menu ref="dateSlotEndPicker" v-model="dateSlotEndMenu" :close-on-content-click="false"
                  :return-value.sync="dateSlotEnd" transition="scale-transition" offset-y max-width="320px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field id="dateSlotStart" :v-model="dateSlotEnd"
                      :value="$root.parseDate(dateSlotEnd)" readonly v-on="on" v-bind="attrs"
                      :rules="[(v) => !!v || 'Enter end date']" @click:prepend="dateSlotEndMenu = true">
                      <template v-slot:append>
                        <img v-on="on" v-bind="attrs" width="24" height="24" src="/icon/icon-calendar.svg" />
                      </template>
                      <template v-slot:label>
                        <span style="font-size: 17px; color: black !important">End date</span>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker v-model="dateSlotEnd" no-title scrollable style="width: 100%">
                    <v-row no-gutters justify="space-around" class="pa-4" style="min-width: 304px">
                      <v-btn width="120" class="mx-0" outlined color="primary"
                        @click="$refs.dateSlotEndPicker.save(dateSlotEnd)">Confirm</v-btn>
                      <v-btn width="120" class="mx-0" outlined @click="dateSlotEndMenu = false">Cancel</v-btn>
                    </v-row>
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="3">
                <v-autocomplete v-model="campaignNameExport" :items="campaignNameList" item-text="value" item-value="value"
                  label="Filter" :class="{ 'line-blue': campaignNameExport != null }" class="rounded-0" style="margin-top: 12px;"
                  append-icon="mdi-chevron-down" />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex flex-wrap">
          <v-btn medium class="mt-2 ml-0" color="primary" @click="downloadReport"><v-icon
              left>mdi-export</v-icon> Export Slot Report</v-btn>
        </v-card-actions>
      </v-card>
    </v-card>

    <br />

    <v-card class="d-flex flex-column align-content-center" min-height="155">
      <v-data-table :headers="headers" :items="slotList" :loading="loading" :search="search"
        :footer-props="{ itemsPerPageOptions: [50, 100, 500, -1] }">
        <template v-slot:top>
          <v-row no-gutters style="padding: 20px 10px 15px">
            <v-spacer></v-spacer>
            <v-col cols="3">
              <v-autocomplete v-model="campaignNameTable" :items="campaignNameList" @change="list" item-text="value" item-value="value"
                label="Filter" :class="{ 'line-blue': campaignNameExport != null }" class="rounded-0"
                append-icon="mdi-chevron-down" />
            </v-col>
            <v-col cols="8" sm="6" class="ml-6">
              <div class="d-flex px-2">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" hide-details
                  style="margin-right: 10px" />
              </div>
            </v-col>
          </v-row>
        </template>
        <template v-slot:item.name="{ item }">
          <div class="d-flex flex-row">
            {{ item.firstName + " " + item.lastName }}
          </div>
        </template>
        <template v-slot:item.action="{ item }">
          <div class="d-flex flex-row">
            <v-icon class="ml-2"
              @click="showDeleteDialog(item)">mdi-delete</v-icon>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Delete {{ title }}</span>
        </v-card-title>
        <v-card-text>Are you sure you want to delete '{{ entity.email }}' ({{ entity.id }})?</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="dialogDelete = false">Cancel</v-btn>
          <v-btn @click="deleteSlot" color="accent">Delete</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-main>
</template>

<style scoped>
.date-block {
  max-width: 230px !important;
}
</style>

<script>
import axios from "axios";

export default {
  data() {
    return {
      dateSlotStartMenu: false,
      dateSlotStart: null,
      dateSlotEndMenu: false,
      dateSlotEnd: null,
      loading: true,
      search: "",
      entity: { transitMap: {}, map: {} },
      slotList: [],
      campaignNameExport: "",
      campaignNameTable: "",
      campaignNameList: [],
      headers: [
        { text: "NAME", value: "name", width: "160" },
        { text: "EMAIL", value: "email", width: "160" },
        { text: "ACCOUNT NAME", value: "agencyName", width: "160" },
        { text: "PARENT ACCOUNT", value: "agencyGroupName", width: "160" },
        { text: "ACCOUNT MANAGER", value: "bdmName" },
        { text: "PERSONAL CONTACT NUMBER", value: "mobileNumber" },
        { text: "SLOT GROUP", value: "slotTime" },
        { text: "STATUS", value: "value" },
        { text: "SUBMITTED", value: "transitMap.created", width: "180" },
        { text: "", value: "action", sortable: false }
      ],
      dialogAdd: false,
      dialogDelete: false
    };
  },
  mounted() {
    this.listCampaign();
  },
  methods: {
    listCampaign() {
      axios
        .get("/system/slot/list/campaign")
        .then((response) => {
          this.campaignNameExport = response.data[0] ?? "";
          this.campaignNameTable = response.data[0] ?? "";
          this.campaignNameList = response.data;
          this.list();
        })
        .catch(() => { });
    },
    downloadReport() {
      if (this.$refs.form2.validate()) {
        axios
          .post(
            "/system/slot/report/export",
            {
              dateStart: this.dateSlotStart,
              dateEnd: this.dateSlotEnd,
              campaignName: this.campaignNameExport
            },
            {
              responseType: 'blob'  // Important for handling file downloads
            }
          )
          .then((response) => {
            const blob = new Blob([response.data], { type: response.data.type });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            const now = new Date();
            const formattedDate = now.toISOString().split('T')[0];
            let fileName = `duo-slot-report--${formattedDate}.csv`;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            alert(`There was an error downloading the file: ${error.message}`);
          });
      }
    },
    newEntity() {
      return { transitMap: {}, map: {} };
    },
    list() {
      this.loading = true;
      axios
        .post("/system/slot/list", {
          campaignName: this.campaignNameTable,
				})
        .then((response) => {
          this.loading = false;
          if (response.status === 200) {
            this.slotList = response.data;
          }
      });
    },
    showDeleteDialog(item) {
      this.entity = this.$root.clone(item);
      this.dialogDelete = true;
    },
    deleteSlot() {
      axios
        .post("/system/slot/delete", { id: this.entity.id })
        .then(() => {
          this.dialogDelete = false;
          this.entity = this.newEntity();
          this.list();
        })
        .catch(() => { });
    },
  }
};
</script>
