<template>
	<v-container fluid>
		<subHeader />

		<v-container fluid style="max-width: 1271px">
			<v-row>

				<v-row class="my-8">
          <v-col v-for="tile in tiles" :key="tile.title" cols="12" md="6">
            <v-card tile elevation="0">
              <v-img :src="$bucket + '/image/toolkit/' + tile.image" height="100%" class="d-flex flex-wrap align-end pa-2">
                <v-col cols="12" class="py-0 pl-4">
                  <v-toolbar-title class=" pt-3" style="font-size: 28px; color: white;">
                    <div class="d-flex flex-column">
                      <span><b>{{ tile.title }}</b></span>
                      <span class="duo-underline"></span>
                    </div>
                  </v-toolbar-title>
                </v-col>
                <v-col class="pt-1 pb-0 pl-4" cols="9">
                  <span class="duo-description white--text">{{ tile.description }}</span>
                </v-col>
                <v-col cols="12">
                  <v-btn @click="trackAndDownload($base + '/asset/file/toolkit' + tile.link, tile.title)" width="193px"
                    height="50px" color="white" :elevation="0" class="my-4 mx-auto">Download</v-btn>
                </v-col>
              </v-img>
            </v-card>
          </v-col>

          <v-col cols="12" md="6">
            <v-card tile elevation="0">
              <v-img :src="$bucket + '/image/dashboard-pages/Resources.Videos.Tiles.jpg'" height="100%" class="d-flex flex-wrap align-end pa-2" >
                <v-col cols="12" class="py-0 pl-4">
                  <v-toolbar-title class=" pt-3" style="font-size: 28px; color: white;">
                    <div class="d-flex flex-column">
                      <span><b>Videos</b></span>
                      <span class="duo-underline"></span>
                    </div>
                  </v-toolbar-title>
                </v-col>
                <v-col class="pt-1 pb-0 pl-4" cols="9">
                </v-col>
                <v-col cols="12">
                  <v-btn @click="visit('/resources/videos')" width="193px"
                    height="50px" color="white" :elevation="0" class="my-4 mx-auto">Find out more</v-btn>
                </v-col>
              </v-img>
            </v-card>
          </v-col>
          
        </v-row>

			</v-row>

			<br /><br />

			<v-row>
			</v-row>
		</v-container>
	</v-container>
</template>

<style scoped>
</style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "./subHeader.vue";

export default {
	components: {
		subHeader,
	},
	data() {
    return {
      tiles: [
        {
          title: "Logo",
          image: "T1.Logo.jpg",
          description: "The Air New Zealand wordmark is our core brand logo - we use it for everything.",
          link: "/Logo.zip",
        },
        {
          title: "Imagery",
          image: "T2.Imagery.jpg",
          description: "We've selected some approved images for you to use.",
          link: "/Imagery.zip",
        }
      ]
    };
  },
	mounted: function () {
	},
	methods: {
		visit(url){
			this.$router.push(url);
		},
		trackAndVisit(url, title){
			this.$root.saveActivityLog({type: 'Visit_dashboard_tile', value: title});
			this.$router.push(url);
		},
		stopVideo() {
			// youtube
			var iframes = document.querySelectorAll("iframe");
			Array.prototype.forEach.call(iframes, (iframe) => {
				iframe.contentWindow.postMessage(JSON.stringify({ event: "command", func: "stopVideo" }), "*");
			});

			// mp4
			var videos = document.querySelectorAll('iframe, video');
			Array.prototype.forEach.call(videos, (video) => {
				if (video.tagName.toLowerCase() === 'video') {
					video.pause();
					video.currentTime = 0;
				} else {
					var src = video.src;
					video.src = src;
				}
			});
		},
		trackAndDownload(url, title) {
      this.$root.saveActivityLog({
        type: "Download_toolkit",
        value: title,
      });
      window.open(url, '_blank');
    },
	},
};
</script>
