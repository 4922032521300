<template>
  <v-container fluid>
    <subHeaderMobile />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px" style="background-size: cover; background-position: center center;"
            :src="$bucket + '/image/dashboard-pages/CreditsAndRefunds3.jpeg'" />
        </v-col>
        <v-col cols="12" class="d-inline-flex py-0 px-4 mt-8 mb-4 justify-space-between align-end">
          <div>
            <h2>Air New Zealand credits issued prior to 30 Sep 2022 must be redeemed by 31 Jan 2026. <br/> So, now's the time to remind your customers of their credit information and ways to redeem it.</h2>
          </div>
        </v-col>
      </v-row>
      <v-row style="padding: 0px 20px 10px 20px">
        <v-col class="px-0" cols="12">
          <div class="d-inline-flex align-center"><v-btn class="mr-5" color="black" fab elevation="0"><v-icon class="icon-anz-calendar" color="white" size="32"></v-icon></v-btn><h2>Key dates</h2></div>
        </v-col>
        <v-col  cols="12" md="6" class="px-0">
          <h2 class="duo-purple mb-2">For credits received BEFORE 1 Oct 2022</h2>
            <ul class="mr-12 pr-6">
              <li>
                Redeem by 31 Jan 2026. Any unused credit will expire after this date
              </li>
              <li>
                Complete travel by 31 Dec 2026
              </li>
            </ul>
        </v-col>
        <v-col  cols="12" md="6" class="px-0">
          <h2 class="duo-purple mb-2">For credits received AFTER 1 Oct 2022</h2>
            <ul class="mr-12 pr-6">
              <li>
                Redeem within 12 months of original date of departure
              </li>
              <li>
                Travel within system range
              </li>
            </ul>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row style="padding: 0px 20px 10px 20px">
        <v-col class="px-0" cols="12">
          <div class="d-inline-flex align-center"><v-btn class="mr-5" color="black" fab elevation="0"><v-icon class="icon-anz-airplane" color="white" size="32"></v-icon></v-btn><h2>Ways to redeem</h2></div>
        </v-col>
        <v-col cols="12" md="4" class="px-0">
          <h2 style="min-height: 75px;" class="duo-purple mb-2">Book new flights</h2>
          <ul class="mr-12 pr-6">
            <li>
              Credits can be used to purchase any Air New Zealand fare including those operated by another airline. With the permission of the credit owner, tickets can also be purchased in any name.
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="4" class="px-0">
          <h2 style="min-height: 75px;" class="duo-purple mb-2">Add an ancillary service for an upcoming flight</h2>
          <ul class="mr-12 pr-6">
            <li>
              Customers can use their credit or any residual balances to add an additional bag, preferred seat or even a Skycouch for an upcoming flight.
            </li>
          </ul>
        </v-col>
        <v-col cols="12" md="4" class="px-0">
          <h2 style="min-height: 75px;" class="duo-purple mb-2">Upgrade an existing ticket</h2>
          <ul class="mr-12 pr-6">
            <li>
              Let your customer treat themselves with a flight upgrade on an already booked ticket.
            </li>
          </ul>
        </v-col>
      </v-row>
      <v-divider class="my-6"/>
      <v-row style="padding: 0px 20px 10px 20px">
        <v-col class="px-0" cols="12">
          <div class="d-inline-flex align-center"><v-btn class="mr-5" color="black" fab elevation="0"><v-icon color="white" size="30" x-large>mdi-currency-usd</v-icon></v-btn><h2>Refund eligibility</h2></div>
        </v-col>
        <v-col  cols="12" class="px-0">
          <h2 class="duo-purple mb-2">Your customers credit may be refundable under the following criteria provided it's not been used partially or in full: </h2>
            <ul class="mr-12 pr-6">
              <li>
                It was specified in the original fare rules
              </li>
              <li>
                Local legislation requires a refund when a flight is cancelled by the operating airline
              </li>
              <li>
                The request is considered under our Covid-19 compassionate policy
              </li>
              <li>
                Original destination is now an Air New Zealand ceased route, e.g. Norfolk Island or Buenos Aires
              </li>
            </ul>
            <br/>
            <b>Tickets already transferred to Virtual Card, can only be refunded via BSP.</b>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <p>Find out more information on Air New Zealand credits and refunds, including Virtual Card below.</p>
          <v-btn @click="visit('https://www.airnzagent.com.au/credits-and-refunds')" id="hero-banner-button" style="font-size: 16px;" class="mb-2 white--text" width="193px" height="50px" color="black" :elevation="0" absolute>Find out more</v-btn>
        </v-col>
      </v-row>
      <v-row style="padding: 80px 20px 0px 0px">
        <v-col cols="12">
          <h2>Content to share on your channels </h2>
          <p>To help you communicate with your customers, we've introduced a range of downloadable assets encouraging and inspiring them to not let that holiday or business trip getaway!
          </p><p>These can be shared across your own social and comms channels with a range of imagery and destinations to choose from.
          </p><p>Download today and start inspiring your customers to use their existing credit, and book their next Air New Zealand getaway! </p>
        </v-col>
      </v-row>
      <!-- <v-row class="mt-12 mb-6">
        <v-col cols="auto">
          <v-img max-height="320px" width="100%" :src="$bucket + '/image/dashboard-pages/CreditsAndRefunds_Tile1.jpg'" />
        </v-col>
        <v-col cols="12">
          <v-btn @click="download($base + '/asset/file/downloads/Agent Assets.zip' )" id="hero-banner-button" style="font-size: 16px;" class="white--text" width="100%" height="50px" color="black" :elevation="0" >Download agent assets</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-img max-height="320px" max-width="100%" :src="$bucket + '/image/dashboard-pages/CreditsAndRefunds_Tile2.jpg'" />
        </v-col>
        <v-col cols="12">
          <v-btn @click="download($base + '/asset/file/downloads/Corporate Assets.zip' )" id="hero-banner-button" style="font-size: 16px;" class="white--text" width="100%" height="50px" color="black" :elevation="0" >Download corporate assets</v-btn>
        </v-col>
      </v-row> -->
    </v-container>
  </v-container>
</template>

<style scoped>

h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Regular";
  font-size: 20px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
</style>

<script>
/* eslint-disable no-console */
import subHeaderMobile from "./subHeaderMobile.vue";

export default {
  components: {
    subHeaderMobile,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    visit(url){
			window.open(url, "_blank")
		},
    download(url) {
      window.open(url, '_blank');
    },
  }
};
</script>