<template>
  <div v-if="show">
    <!-- Disabled for admin preview -->
		<v-navigation-drawer v-if="$store.state.principal.administrator == false" style="box-shadow: unset; z-index: 999" :width="mini ? '43' : ''" right app v-model="drawer" :mini-variant.sync="mini" absolute temporary hide-overlay>
			<v-list>
				<v-container class="pb-4">
					<v-row class="justify-space-between" @click="drawer = !drawer">
						<v-col class="d-flex justify-start align-center pl-4">
							<img src="/icon/logo-airnz.svg" width="20"  />
						</v-col>
						<v-col class="d-flex justify-end align-center pr-4">
							<v-icon style="color: white !important" class="icon-duo-close" size="12"></v-icon>
						</v-col>
					</v-row>
				</v-container>
				
				<v-list-item @click="showProfile = false" style="color: white !important" to="/dashboard" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-duo-dashboard"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/sales" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-anz-airplane"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>sales </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/training" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="duo-icon-training"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>training </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="showProfile = false" style="color: white !important" to="/profile" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-anz-person"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title> <b>duo</b>profile </v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item @click="logout" style="color: white !important" to="#" link>
					<v-list-item-icon>
						<v-icon style="color: white !important" class="icon-duo-exit"></v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Log out</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<template v-slot:append>
				<div style="width: 200px;"></div>
			</template>
		</v-navigation-drawer>

    <!-- Disabled for admin preview -->
    <v-container fluid>
      <v-row>
        <v-col cols="12" class="d-flex">
          <div v-if="$router.currentRoute.path != '/faqs'" class="navbar d-flex flex-wrap pt-5">
            <img style="width: 216px" :src="$bucket + '/image/logo-duo-reverse.svg'" @click="$store.commit('debug')" />
          </div>
          <div v-else class="navbar d-flex flex-wrap pt-5">
            <img style="width: 216px" :src="$bucket + '/image/logo/logo-duo-reverse-white_color.svg'" @click="$store.commit('debug')" />
            <img style="width: 182px" :src="$bucket + '/image/logo/logo-airnz-white_color.svg'" />
          </div>
          <v-spacer></v-spacer>
          <img src="/icon/logo-airnz.svg" class="anz-logo pt-6" />
          <v-icon @click="drawer = !drawer" style="color: black !important" class="pt-6 pr-2" size="40">mdi-menu</v-icon>
        </v-col>
      </v-row>
      <router-view :key="$route.fullPath"></router-view>
    </v-container>
    <subHeaderMobile />
    <v-container fluid style="max-width: 1271px">
      <v-row justify="center">
        <v-col cols="12" style="padding: 0; margin-bottom: 14px">
          <v-img max-width="1271px" max-height="376px" style="background-size: cover; background-position: center center;"
            :src="entity.heroImage" />
        </v-col>
        <v-col v-if="!entity.map.hideTitle || entity.map.hideTitle == false" cols="12"
          class="d-inline-flex py-0 px-4 justify-space-between align-end">
          <h1 v-if="!entity.map.headingStyle || entity.map.headingStyle == 'h1'">{{ entity.title }}</h1>
          <h2 v-else-if="entity.map.headingStyle == 'h2'">{{ entity.title }}</h2>
          <h3 v-else>{{ entity.title }}</h3>
        </v-col>
      </v-row>
      <div class="mt-4" v-for="(item, idx) in entity.map.contentList" :key="idx">
        <v-row v-if="item.type == 'Text'" style="padding: 0px 20px 10px 20px">
          <v-col cols="12" class="pa-0">
            <div v-html="contentCss + item.content" />
          </v-col>
        </v-row>
        <v-row v-else-if="item.type == 'Video'" class="mt-0 d-flex justify-center text-center">
          <v-col cols="12">
            <iframe width="100%" height="300" :src="item.content" :title="entity.title" frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen />
          </v-col>
        </v-row>
        <v-row v-else-if="item.type == 'Images_Block'" class="pa-0 d-flex" style="padding: 0px 60px 0px 0px;">
          <v-col class="py-4" :cols="!item.content.image2 && !item.content.image3 ? 12 : !item.content.image3 ? 6 : 4">
            <v-img height="360" style="background-size: cover;" :src="item.content.image1" />
          </v-col>
          <v-col v-if="item.content.image2" class="py-4" :cols="!item.content.image3 ? 6 : 4">
            <v-img height="360" style="background-size: cover;" :src="item.content.image2" />
          </v-col>
          <v-col v-if="item.content.image3" class="py-4" cols="4">
            <v-img height="360" style="background-size: cover;" :src="item.content.image3" />
          </v-col>
        </v-row>
        <v-row v-else-if="item.type == 'CTA_Button'" style="padding: 0px 0px 10px 0px">
          <v-col
            :class="'d-flex'"
            cols="12">
            <v-btn @click="onClickCTA(item.content.link)" block height="50px" color="duoSecondary"
              :elevation="0" dark>{{ item.content.label }}</v-btn>
          </v-col>
        </v-row>

        <div v-else-if="item.type == 'Dynamic_Form' && item.content">
          <duo-dynamic-form-mobile :show="show" :is-preview="isPreview" @onFormSubmitted="onFormSubmitted" :question-list="questionMap[item.content]" :is-show-submitted="submittedMap[item.content]" />
        </div>
      </div>

    </v-container>
    <v-container style="margin-top:50px;">
      <duoFooter />
    </v-container>
  </div>
</template>
  
<style scoped>
h1 {
  font-family: "AirNZ Black Italic";
  font-size: 38px;
}

h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 25px;
}

h3 {
  font-family: "AirNZ Sans Semibold";
  font-size: 21px;
}

p,
a {
  margin-top: 21px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}
.v-navigation-drawer--mini-variant .logo-label-airnz {
	display: none;
}

.v-navigation-drawer--is-mouseover .logo-airnz {
	display: none;
}

a.v-list-item {
	padding: 0 !important;
	height: 56px;
}
.v-app-bar {
	background: rgba(255, 255, 255, 0) !important;
}
.navbar {
	display: flex;
	justify-content: space-between !important;
	align-items: center;
	padding: 0 10px;
	width: 100%;
}
.v-navigation-drawer {
	height: 100% !important;
	position: fixed;
	background: #2d2926;
}
.v-list > .v-list-item:hover,
.v-list > .v-list-item:active {
	background-color: #440099 !important;
	text-decoration: none !important;
}
.v-list-item > .v-list-item__icon {
	margin-top: 20px;
}
.v-list-item:hover > .v-list-item__icon {
	background-color: #221551 !important;
	min-width: 56px;
	height: 56px;
	padding-right: 15px !important;
	margin: 0 13px 0 auto !important;
}
.v-list-item--link:before,
.v-list-item--active {
	background-color: #440099;
}
.v-list-item--active > .v-list-item__icon {
	background-color: #221551 !important;
	min-width: 56px;
	height: 56px;
	padding-right: 15px !important;
	margin: 0 13px 0 auto !important;
}
.v-list-item__icon > i {
	margin-left: 20px;
	font-size: 17px;
}
.v-application .primary--text {
	color: white !important;
	caret-color: white;
}
a.v-list-item:hover {
	text-decoration: none !important;
	background-color: #440099 !important;
}
a:hover {
	text-decoration: none !important;
	color: #440099 !important;
	background: none;
}
i:hover {
	text-decoration: none !important;
}
.anz-logo{
	padding-right: 5px;
	width:30px;
	filter: invert(88%) sepia(21%) saturate(935%) hue-rotate(123deg) brightness(85%) contrast(97%);
}
</style>
  
<script>
/* eslint-disable no-console */
import subHeaderMobile from "./subHeaderMobile.vue";
import duoFooter from "../duoFooter.vue";
import duoDynamicFormMobile from "./duoDynamicFormMobile.vue";

export default {
  components: {
    subHeaderMobile,
    duoFooter,
    duoDynamicFormMobile,
  },
  data() {
    return {
      contentCss: '<style scoped>ul {margin-left: 48px;}</style>',
      drawer: false,
      showProfile: false,
			mini: false,
    }
  },
  props: {
    isPreview: {
      default: false,
    },
    show: {
      default: true,
    },
    entity: {
      default: {
        map: {
          contentList: []
        }
      }
    },
    questionMap: {
      default: {

      }
    },
    submittedMap: {
      default: {

      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  methods: {
    onClickCTA(link) {
      if(link.startsWith('http://') || link.startsWith('https://')) {
        window.location.href = link;
      }
    },
    onFormSubmitted() {
      this.$emit("onReset");
    }
  }
};
</script>