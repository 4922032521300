<script>

export default {
	data() {
		return {
			stateList: [
				{
					state: "unknown-sales-manager",
					name: "Sales Manager",
					bdmList: [
						{
							firstName: "CJ",
							lastName: "Lowe",
							territory:
								"Senior Sales Manager Retail/TMC",
							interests: "Travelling, tennis, food and wine",
							favouriteDestination: "New York",
							countriesVisited: 22,
							joinedNz: '2006',
							email: "courtnay-jane.mccarthy@airnz.co.nz",
							image: "CJ.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:courtnay-jane.mccarthy@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: `<strong>Top tip:</strong> <span><a style="color: black;" href="https://www.airnewzealand.co.nz/airpoints-credit-card-benefit-table">Air New Zealand credit cards</a></span> are a great way to boost Airpoints™ and Status Points accruals as well as access a reduced rate for a Koru membership.`
						},
						{
							firstName: "Duane",
							lastName: "Perrot",
							territory: "Senior Manager - Sales, South Island",
							interests: "All sport. Could easily watch two flies crawling up a wall if it was competitive.",
							favouriteDestination: "Japan wins for the variety in culture and experiences.",
							countriesVisited: 33,
							email: "Duane.Perrott@airnz.co.nz",
							image: "Duane.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:Duane.Perrott@airnz.co.nz",
							videoIntroduction: "",
						},
						{
							firstName: "Paige",
							lastName: "Myocevich",
							territory: "Sales Manager",
							brandRegion: "Flight Centre, First Travel Group, Expedia and Webjet",
							interests: "Traveling, Camping, hosting, organising and being with friends and family",
							favouriteDestination: "Vietnam",
							countriesVisited: 14,
							joinedNz: "2013",
							email: "paige.myocevich@airnz.co.nz",
							image: "Paige.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:paige.myocevich@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: `<p>Travel Tip: <br />
							If you are travelling with a little one, consider purchasing the Skycouch with use of the infant pod instead of a basinet allowing extra room for you and bub to both stretch out.<br />
							<br />
							Another Travel Tip if someone hasn’t sent one:<br />
							To maximise your earn Issue EMDs in your GDS vs online to claim commission. POS commission levels below:<br />
							5% prepaid bags, 5% Skycouch and 10% seat select. An example of your earn could be Business Premier preferred seating on the dry lease aircraft 777-3 – at $590 lead in you would earn $59.<br />
							<br />
							Another Tip <br />
							Airpoints calculator to work out APD and status point earn.</p>`
						},
						{
							firstName: "Jo",
							lastName: "Inglis",
							territory: "Sales Manager",
							brandRegion: "Helloworld, House of Travel, CTM, CTS and World Way",
							interests: "Adventure sports, riding bikes, exploring the great outdoors and getting lost in new places",
							favouriteDestination: "Italy",
							countriesVisited: 26,
							email: "jo.inglis@airnz.co.nz",
							image: "Jo.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:jo.inglis@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "Remember that if you’re an Elite member, you can request one cabin class upgrades using your Airpoints Dollars 😊"
						},
					],
				},
				{
					state: "unknown-training-engagement",
					name: "Training & Engagement",
					bdmList: [
						{
							firstName: "Charlotte",
							lastName: "Taylor",
							territory:
								"Training & Engagement Manager",
							interests: "Gaming, movies, cooking",
							favouriteDestination: "Bay of Islands",
							countriesVisited: 15,
							email: "Charlotte.Taylor@airnz.co.nz",
							phoneNumber: "027 266 0916",
							image: "Charlotte.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:Charlotte.Taylor@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "Did you know you can redeem your Airpoints for some really cool items on our Airpoints store if you’re an NZ based member? Comes in really hand if you have 30-50APD spare for gifts for people (or yourself!)"
						},
					],
				},
				{
					state: "national",
					name: "National",
					bdmList: [
						{
							firstName: "Chris",
							lastName: "McLean",
							territory: "Account Manager Groups",
							interests: 'Anything that involves "friendly" competition - Jogging, Paddleboarding, Mountain Biking, Road Riding, Multi Sport events',
							favouriteDestination: "Hawaii",
							countriesVisited: "too many to count!",
							email: "chris.mclean@airnz.co.nz",
							phoneNumber: "021 797 804",
							image: "Chris.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:chris.mclean@airnz.co.nz",
							videoIntroduction: "",
						},
						{
							firstName: "Grace",
							lastName: "Yang",
							territory: "Retail Account Manager - Asia VFR GTN, CTS, Worldway",
							interests: "Travel and movies",
							favouriteDestination: "Maui, Hawaii",
							countriesVisited: 12,
							email: "grace.yang@airnz.co.nz",
							phoneNumber: "021 178 2916",
							image: "Grace.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:grace.yang@airnz.co.nz",
							videoIntroduction: "",
						},
						{
							firstName: "Leanne",
							lastName: "Bianchi",
							territory: "TMC & Retail Account Manager",
							interests: "Spending time with friends and family, rugby, food",
							favouriteDestination: "London",
							countriesVisited: 5,
							email: "leanne.bianchi@airnz.co.nz",
							phoneNumber: "027 246 7172",
							image: "Leanne.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:leanne.bianchi@airnz.co.nz",
							videoIntroduction: "",
						},
					],
				},
				{
					state: "northnz",
					name: "North Island",
					bdmList: [
						{
							firstName: "Stacey",
							lastName: "Paranjpe",
							territory: "Retail Account Manager Wellington, Manawatu, Taranaki, Wairarapa, Whanganui",
							brandRegion: "<strong>North Island Brokers:</strong> NZTB",
							interests: "Travelling, food, wine, walks in the sun, spending time with Family and Friends.",
							favouriteDestination: "Spain",
							countriesVisited: 25,
							email: "stacey.paranjpe@airnz.co.nz",
							phoneNumber: "027 221 1377",
							image: "Stacey.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:stacey.paranjpe@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "My hidden gem is on our A320 international aircraft, seats ABC have a slightly increased seat pitch over seats DEF up to row 10. So pre-seat in A, B, or C for a bit more leg room."
						},
						{
							firstName: "Aimee",
							lastName: "Donovan",
							territory: "Retail Account Manager Auckland: Auckland City Fringe, Northshore, Northland, Napier, Gisborne",
							brandRegion: "<strong>Flight Centre:</strong> Customer Connect <br/><strong>North Island Brokers:</strong> FC Brokers, Travel Associates, The Travel Brokers​",
							interests: "Good food with family & friends, Hot Yoga, Music, the beach & travelling off the beaten track!",
							favouriteDestination: "Anywhere in Asia",
							countriesVisited: "8",
							joinedNz: "2023",
							email: "aimee.donovan@airnz.co.nz",
							phoneNumber: "027 239 5587",
							image: "Aimee2.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:aimee.donovan@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "If you have clients travelling to the USA, Europe who wants to use recognition upgrades I would recommend booking them on our dry lease aircraft which has much bigger premium cabins with 53 Business Premier Seat and 34 Premium Economy seats giving pax more of a chance to have these upgrade confirmed."
						},
						{
							firstName: "Zinia",
							lastName: "Pesuna",
							territory: "Retail Account Manager Auckland: Flight Centre, House of Travel, First Travel Group and Helloworld <br/>Auckland CBD, East, West, South and Kerikeri, Rotorua, Taupo and Whakatane",
							brandRegion: "<strong>House of Travel:</strong> Devenport <br/><strong>Helloworld:</strong> Takapuna <br/><strong>North Island Brokers:</strong> Travel Advocates",
							interests: "Art, baking, travelling",
							favouriteDestination: "Queenstown",
							countriesVisited: 19,
							email: "zinia.pesuna@airnz.co.nz",
							phoneNumber: "021 145 2463",
							image: "Zinia.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:zinia.pesuna@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: `<strong>Advance purchase (AP) fares:</strong><br />
							Advance purchase (AP) in the GDS work on IATA logic, this means the AP rule is based on the outbound travel date. Air NZ fares ex-NZ are one-way fares combined to create a round trip, the Outbound AP and the Inbound AP are considered as separate entities.<br />
							What this means is that the GDS will not always price correctly if the outbound is inside the AP but the inbound is not, whereas online is not constrained by IATA logic so quotes correctly. <br/>
							Agents can force the fare/manually build with the AP fare basis to match the fare online. Agents need make sure when issuing they still meet the TTL and the advance purchase restrictions, no authority number is required as they are complying with fare rules.<br />
							<br />
							Credits: <br/>
							<strong>All LONGHAUL</strong> (Asia, America UK/EU) fares can be held in credit as per fare rules however flights must be cancelled out prior to day of departure.`
						},
						{
							firstName: "Chris",
							lastName: "McLean",
							territory: "Account Manager Groups and RTO's",
							brandRegion: "(Part-time Tues-Thurs)",
							interests: 'Anything that involves "friendly" competition - Jogging, Paddleboarding, Mountain Biking, Road Riding, Multi Sport events',
							favouriteDestination: "Hawaii",
							countriesVisited: 25,
							email: "chris.mclean@airnz.co.nz",
							phoneNumber: "021 797 804",
							image: "Chris.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:chris.mclean@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: `Booking a Group booking early means you can access the sharpest pricing. For some routes you don’t need to pay in full until 120 days prior and you can name change up until 48 hours prior to travel.`
						},
						{
							firstName: "Grace",
							lastName: "Yang",
							territory: "Retail Account Manager - Asia",
							brandRegion: "<strong>VFR GTN, CTS, Worldway, North Island Brokers:</strong> Aspire, CTS, Ecp Travels and ITOs",
							interests: "Travel and movies",
							favouriteDestination: "Maui, Hawaii",
							countriesVisited: 16,
							email: "grace.yang@airnz.co.nz",
							phoneNumber: "021 178 2916",
							image: "Grace.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:grace.yang@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "I shall go with the webforms – we have 2 webforms set up from the agents portal which makes easier for agents to submit their name change or fares quote requests."
						},
						{
							firstName: "Leanne",
							lastName: "Bianchi",
							territory: "TMC & Retail Account Manager",
							brandRegion: "<strong>Helloworld, Flight Centre & CTM Retail:</strong> West Auckland, Napier, Hastings, Havelock North. <br /><strong>North Island Brokers:</strong> The Travel Brokers",
							interests: "Spending time with friends and family, rugby, food",
							favouriteDestination: "London",
							countriesVisited: 5,
							email: "leanne.bianchi@airnz.co.nz",
							phoneNumber: "027 246 7172",
							image: "Leanne.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:leanne.bianchi@airnz.co.nz",
							videoIntroduction: "",
						},
						{
							firstName: "Matt",
							lastName: "Jenkins",
							territory: "TMC & Retail Account Manager",
							brandRegion: "<strong>TMC North Island:</strong> HOT, FTG <br /><strong>Retail:</strong> Thames, Cambridge, Mt Maunganui, Tauranga, Whitianga, Waihi <br/><strong>North Island Brokers:</strong> Fine Travel, TMG",
							interests: "Golf",
							favouriteDestination: "London",
							countriesVisited: 56,
							email: "matt.jenkins@airnz.co.nz",
							phoneNumber: "027 201 1324",
							image: "MattJenkins.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:matt.jenkins@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "Sector surcharges on our UK/EU fares are a great way of adding in extra value to a fare for the customer, if the Air NZ booking class is high and the Other airline booking class is low and/or O class is available on the Air New Zealand service, price up a sector surcharge option for the customer."
						},
					],
				},
				{
					state: "southnz",
					name: "South Island",
					bdmList: [
						{
							firstName: "Frank",
							lastName: "Gibbons",
							territory:
								"Regional Senior Account Manager Central Otago/Southland",
							brandRegion: "<strong>South Island Broker:</strong> NZ Travel Brokers, Envoyage (TMG and Flight Centre Independents)",
							interests: "Golf, Football (the Beautiful Game) and Sausage Rolls",
							favouriteDestination: "Chicago",
							countriesVisited: 15,
							email: "frank.gibbons@airnz.co.nz",
							phoneNumber: "021 752 437",
							image: "Frank.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:frank.gibbons@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: `One ups – my quote: <br />
							<br />
							Suggest to customers to bid as much as they are comfortable in putting forward as long as a) they are happy to have not bid more if the miss out, and b) that they are happy with the amount they have paid when its successful.  And getting a higher standard of service is always worth the investment <br />
							<br/>
							<i>If a customer wishes to travel on an earlier domestic flight on an International 086 ticket -  If the same class is available in the PNR, then the change can be made and as the itinerary remains with the same O&D. As the same class is available, you can request an authority via Agency Support to waive the change fee. If the same booking class is not available, Agency Support can assist if the new domestic flights meet the O&D exception requirements. If the original class can be confirmed, then you can request an authority via Agency Support to waive the change fee.  Rule of Thumb – Longhaul Outside the PNR H and Shorthaul Outside the PNR its V</i>`
						},
						{
							firstName: "Elaine",
							lastName: "Casey",
							territory:
								"TMC & Retai Account Manager - Christchurch, Ashburton, Timaru, Nelson, Marlborough & Upper & Lower West Coast",
							brandRegion: "<strong>South Island Brokers:</strong> First Group Brokers, The Travel Brokers Travel Advocates",
							interests: "Time with family & friends, watching sport & travel",
							favouriteDestination: "Italy",
							countriesVisited: 15,
							email: "elaine.casey@airnz.co.nz",
							phoneNumber: "027 296 0908",
							image: "Elaine.jpg",
							hideButton: false,
							notes: "",
							bookings: "mailto:elaine.casey@airnz.co.nz",
							videoIntroduction: "",
							hiddenGems: "“Why not offer your clients a stopover in Bali on the way back from Europe” (Seasonal of course but what a fantastic option for a beach break on the over or on the return !)"
						},
					],
				},
			],
		};
	},
};
</script>
