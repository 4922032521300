import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

/* eslint-disable */

if (localStorage.getItem("principal") && JSON.parse(localStorage.getItem("principal")).token) {
	axios.defaults.headers.common["Authorization"] = "Bearer " + JSON.parse(localStorage.getItem("principal")).token;
}

export default new Vuex.Store({
	state: {
		principal: (localStorage.getItem("principal") && JSON.parse(localStorage.getItem("principal"))) || {},
		notification: {
			title: null,
			content: {},
			show: false,
			color: "#440099",
			buttonText: null
		},
		retakeTrainingModule: false,

		trainingModule: {
			id: null,
			details: {
				isActive: false,
				name: null,
				description: null
			},
			slides: [],
			quizes: []
		},

		choosenItem: {
			backgroundColor: null,
			bodyCopy: null,
			buttonText: null,
			previousId: null,
			heading: null,
			imageName: null,
			isDisplayAfterQuiz: false,
			layout: 0,
			type: null,

			question: null,
			imageNameQuestion: null,
			answer1: null,
			answer2: null,
			answer3: null,
			answer4: null,
			imageNameAnswer1: null,
			imageNameAnswer2: null,
			imageNameAnswer3: null,
			imageNameAnswer4: null,
			correctAnswer: 0

		},

		trainingModuleBgColor: "#000000",
	},
	mutations: {
		login(state, principal) {
			state.principal = principal;
			axios.defaults.headers.common["Authorization"] = "Bearer " + principal.token;
			localStorage.setItem("principal", JSON.stringify(principal));
		},
		logout(state) {
			state.principal = {};
			delete axios.defaults.headers.common["Authorization"];
			localStorage.removeItem("principal");
		},
		showNotification(state, { title, content, color, buttonText }) {
			state.notification.title = title;
			state.notification.content = content;
			state.notification.color = color;
			state.notification.show = true;
			state.notification.buttonText = buttonText;
		},
		hideNotification(state) {
			state.notification.show = false;
			state.notification.title = null;
			state.notification.color = "#440099";
			state.notification.content = {};
			state.notification.buttonText = null;
		},
		debug(state) {
			console.log("localStorage principal: " + localStorage.getItem("principal") + " state: " + JSON.stringify(state, null, 4));
		},

		setTrainingModule(state, trainingModule) {
			state.trainingModule = trainingModule;
		},
		resetTrainingModule(state) {
			state.trainingModule = {
				id: null,
				details: {
					isActive: false,
					name: null,
					description: null
				},
				slides: [],
				quizes: []
			};
		},
		setChoosenItem(state, item) {
			state.choosenItem = item;
		},
		resetChoosenItem(state) {
			state.choosenItem = {
				backgroundColor: null,
				bodyCopy: null,
				buttonText: null,
				previousId: null,
				heading: null,
				imageName: null,
				isDisplayAfterQuiz: false,
				layout: 0,
				type: null,
				
				question: null,
				imageNameQuestion: null,
				answer1: null,
				answer2: null,
				answer3: null,
				answer4: null,
				imageNameAnswer1: null,
				imageNameAnswer2: null,
				imageNameAnswer3: null,
				imageNameAnswer4: null,
				correctAnswer: 0
			};
		},

		setTrainingModuleBgColor(state, item) {
			state.trainingModuleBgColor = item;
		}
	},
	actions: {
		login({ commit }, principal) {
			return new Promise((resolve, reject) => {
				axios
					.post("/system/authentication/login", {
						email: principal.email,
						password: principal.password,
						rememberMe: principal.rememberMe,
					})
					.then((response) => {
						let principal = response.data;
						if (principal && principal.token) {
							commit("login", principal);
							resolve({ response: response, principal: principal });
						}
					})
					.catch((error) => reject(error));
			});
		},
		logout({ commit }) {
			return new Promise((resolve, reject) => {
				axios
					.post("/system/authentication/logout", {})
					.then((response) => {
						commit("logout");
						resolve(response);
					})
					.catch((error) => reject(error));
			});
		},
		loginAdministration({ commit }, principal) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/authentication/login", {
						email: principal.email,
						password: principal.password,
					})
					.then((response) => {
						let principal = response.data;
						if (principal && principal.token) {
							commit("login", principal);
							resolve({ response: response, principal: principal });
						}
					})
					.catch((error) => reject(error));
			});
		},
		logoutAdministration({ commit }) {
			return new Promise((resolve, reject) => {
				axios
					.post("/api/authentication/logout", {})
					.then((response) => {
						commit("logout");
						resolve(response);
					})
					.catch((error) => reject(error));
			});
		},
		debug({ commit }) {
			commit("debug");
		},
	},
	getters: {
		isAuthenticated: (state) => state.principal && state.principal.token,
	},
});
