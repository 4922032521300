<template>
  <v-container fluid>
    <subHeader />
    <v-container fluid style="max-width: 1296px">
      <div style="margin-bottom: 14px"></div>
      <v-row justify="center">
        <v-col cols="12" style="padding: 0;">
          <v-img
            max-width="1296"
            max-height="500px"
            :src="$bucket + '/image/dashboard-pages/CreativeLab.Hero.jpg'"
          />
        </v-col>
      </v-row>
      <div style="margin-bottom: 44px"></div>
      <v-row>
        <div style="width: 1296px !important; " class="px-8 pb-6">
          <p style="font-size: 23px; font-family: 'AirNZ Sans Semibold'"><b>Want the chance to see our new upcoming onboard products and have a sneak peek behind the scenes in our creative lab in Auckland? Register below if you’d like to come check out our new seats and learn about all the magic and design that goes into creating our world class products.</b></p>
          <div class="pt-1"></div>
          <p><b>Sessions will be held on Wednesday 28<sup>th</sup> August.</b></p>
          <p>The sessions will be approximately 60 – 90 minutes long, and agents will have an opportunity to test the below products and sample some of our onboard snacks, as well as plenty of time to chat to some of our Cabin Crew and Account Managers with any questions you may have.</p>
        </div>
        <div style="width: 1296px !important; ">
          <div style="padding: 1rem 2rem 1rem 2rem; background-color:#ffffff;" class="px-12">
            <p>
              Our new <b>Business Premier Luxe™, Business Premier™</b> and <b>Premium Economy</b>
            </p>
            <p>
              <b>Economy, Economy Stretch™, Economy Skycouch™</b>
            </p>
            <p>
              Our new 2025 product, <b>Economy Skynest™</b>
            </p>
          </div>
        </div>
        <div style="width: 1296px !important; " class="px-8 pt-4">
          <p><b>This is a really exciting opportunity for you to get to know the Air New Zealand products you’re selling and experience part of the onboard experience our customers get to enjoy, and yourselves, when flying with us!</b></p>
          <p>Once you have registered, we will be in touch to confirm your spot. Spaces are limited, so we ask that you only register for a spot if you are able to attend the timeslot.</p>
          <p>On street parking is available nearby The Hangar. The address will be shared when your spot is confirmed, but please note that this experience is in Auckland CBD.</p>
          <br />
          <v-btn height="45" width="190"
            style="font-size: 16px; background-color: #01b0b9 !important; color: white; text-transform: none !important;"
            @click="showSlotTimeDialog = true;">
            Register Now
          </v-btn>
        </div>
      </v-row>

      <v-dialog v-model="showSlotTimeDialog" persistent width="505px">
        <v-card class="white" height="100%" :style="{ borderRadius: '8px'}">
          <v-card-title class="pa-1 pl-6" :style="{ backgroundColor: '#00AFB9' }">
            <span class="notification-title-text-style">Choose Time Slot</span>
            <v-spacer></v-spacer>
            <v-btn icon x-large color="white" @click="showSlotTimeDialog = false"><v-icon>mdi-close</v-icon></v-btn>
          </v-card-title>
          <v-card-text class="pt-6">
            <v-row justify="center">
              <v-col class="d-flex justify-center">
                <v-btn-toggle
                  v-model="slotTime"
                  background-color="#00AFB9"
                  color="#00AFB9"
                  rounded="0"
                  tile
                  group
                  block
                >
                  <v-btn v-for="(value, index) in times" :key="index" :value="value">{{ value }}</v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-center" :style="{ paddingBottom: '20px'}">
            <v-spacer></v-spacer>
            <v-btn height="45" width="120"
              style="font-size: 16px; background-color: #01b0b9 !important; color: white; text-transform: none !important;"
              @click="showSlotTimeDialog = false; submit();">
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-container>
  </v-container>
</template>

<style scoped>
h2 {
  font-family: "AirNZ Sans Semibold";
  font-size: 28px;
}

p {
  margin-top: 18px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

li {
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
}

a,
li a {
  margin-top: 15px;
  font-family: "AirNZ Sans Regular";
  font-size: 17px;
  text-decoration: underline;
  color: #440099;
}

a:hover {
  color: #9b4ff7 !important;
}
</style>

<script>
/* eslint-disable no-console */
import axios from "axios";
import subHeader from "./subHeader.vue";

export default {
  components: {
    subHeader,
  },
  props: {
  },
  data() {
    return {
      showSlotTimeDialog: false,
      slotTime: "8am",
      times: []
    }
  },
  mounted() {
    this.getTimeSlots();
    window.scrollTo(0, 0);
  },
  methods: {
    getTimeSlots() {
      axios.
        post("/system/slot/times", {
					campaignName: "Creative Lab",
				})
        .then(response => {
          this.times = response.data;
        })
        .catch(error => {
          console.error("There was an error fetching time slots: ", error);
        });
    },
    submit() {
			axios
        .post("/system/slot/save", {
					slotTime: this.slotTime,
          campaignName: "Creative Lab",
          campaignDate: "2024-08-28"
				})
				.then((response) => {
          var status = response.data.value ?? 'submitted'
					// show notif
					this.$store.commit("showNotification", 
						{ 	
              title: "Awesome", 
							content: "Thanks you’ve now " + status + " for the " + this.slotTime + " slot in the incentive.", 
							color: "#00AFB9",
							buttonText: "OK"
						}
					);
				});
		},
  }
};
</script>

<style scoped>
.notification-title-text-style {
	letter-spacing: 0px;
	color: #FFFFFF;
	text-align: left;
	font-style: normal;
	font-variant: normal;
	font-weight: 600;
	font-size: 28px;
	line-height: 32px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
}
.notification-content-text-style {
	letter-spacing: 0px;
	color: #2D2926;
	text-align: center;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	font-size: 18px;
	line-height: 21px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
	white-space: pre-wrap;
}
.regular-text-style {
	letter-spacing: 0px;
	color: #FFFFFF;
	text-align: center;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	font-size: 17px;
	line-height: 21px;
	font-family: AirNZ Sans Regular !important;
	opacity: 1;
	white-space: pre-wrap;
}
.notification-content-error-style {
	margin: 47.29px 71.45px 13.44px 72.45px;
	text-align: center;
	/* height: 77.42px; */
}
.notification-content-awesome-style {
	margin: 37.29px 71.45px 30.44px 72.45px;
	text-align: center;
	/* height: 77.42px; */
}
.notification-content-warning-style {
	margin: 39.74px 71.45px 30.98px 72.45px;
	text-align: center;
	/* height: 77.42px; */
}
</style>