<template>
	<v-container fluid>
		<subHeader />

		<v-container fluid style="max-width: 1271px">
			<v-row class="pb-8">
				<v-col cols="12">
					<v-row>
						<h2><b>Videos</b></h2>
					</v-row>
				</v-col>
				<duo-tile @onClickEdit="showEditDialog(item)" @onClickHide="showDialogConfirmation('unpublish', item.id)"
				v-for="item in tileList" :map="item.map" :img="item.image" :img-only="item.imageOnly" :title="item.title"
				:content="item.content" :size="item.size" :key="item.id" :btn-label="item.buttonLabel" :type="item.linkType"
				:typeCreator="item.type" :link="item.link" @onClickDialog="showVideoDialog(item)" :isvideo="true"/>
			</v-row>
			<v-row class="pt-5">
			</v-row>
			<dialog-video :show="videoDialog.show" :title="videoDialog.title" :link="videoDialog.link" @onCloseDialog="closeVideoDialog"/>

		</v-container>
	</v-container>
</template>

<style scoped></style>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-empty */
/* eslint-disable no-extra-boolean-cast */
import axios from "axios";
import subHeader from "./subHeader.vue";
import duoTile from "./creator/duoTile.vue"
import dialogVideo from "./dialog/dialogVideo.vue";

export default {
	components: {
		subHeader,
		duoTile,
		dialogVideo
	},
	data() {
		return {
			tileList: [],
			videoDialog: {
				show: false,
				link: "",
				title: ""
			},
		};
	},
	mounted: function () {
		this.getList();
	},
	methods: {
		closeVideoDialog() {
			this.videoDialog.show = false;
			this.stopVideo();
			this.videoDialog.link = ""
			this.videoDialog.title = "";
		},
		showVideoDialog(item) {
			this.videoDialog.link = item.link;
			this.videoDialog.title = item.reportTitle;
			this.videoDialog.show = true;
		},
		getList() {
			axios
				.get("/system/tile/video")
				.then((response) => {
					this.tileList = response.data.filter(function (el) {
						return el.sequence;
					})
					console.log(this.tileList)
				})
				.catch(() => { });
		},




		openSeatsToSuitTiles() {
			this.$root.saveActivityLog({type: 'Visit_dashboard_tile', value: "Seats to Suit"});
			this.seatsToSuitDialog.show = true;
		},
		openAirNzSafetyTiles() {
			this.$root.saveActivityLog({type: 'Visit_dashboard_tile', value: "Air NZ Safety"});
			this.airNzSafetyDialog.show = true;
		},
		openTakeTheTour(){
			this.$root.saveActivityLog({type: 'Visit_dashboard_tile', value: "Take the tour"});
			this.takeTheTourDialog.show = true;
		},
		stopVideo() {
			// youtube
			var iframes = document.querySelectorAll("iframe");
			Array.prototype.forEach.call(iframes, (iframe) => {
				iframe.contentWindow.postMessage(JSON.stringify({ event: "command", func: "stopVideo" }), "*");
			});

			// mp4
			var videos = document.querySelectorAll('iframe, video');
			Array.prototype.forEach.call(videos, (video) => {
				if (video.tagName.toLowerCase() === 'video') {
					video.pause();
					video.currentTime = 0;
				} else {
					var src = video.src;
					video.src = src;
				}
			});
		},
	},
};
</script>
